import React from 'react'
import RegisterSection from '../../Components/Auth/RegisterSection'
import Layout from '../../Layout/Layout'

const Register = () => {
  return (
    <>
    <Layout>
       <RegisterSection />
    </Layout>
    </>
  )
}

export default Register


