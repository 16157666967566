import React from 'react'
import ForgotSection from '../../Components/Auth/ForgotSection';
import Layout from '../../Layout/Layout'

const Forgot = () => {
  return (
    <>
    <Layout>
      <ForgotSection />
    </Layout>
    </>
  )
}

export default Forgot