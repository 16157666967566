import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import PropsalsSection1 from "../../Components/Dashboard/Propsal/PropsalsSection1";

const Propsals = () => {
  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "2%" }}
        >
          <PropsalsSection1 />
        </div>
      </Layout>
    </>
  );
};

export default Propsals;
