/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
// import { ColorRing } from "react-loader-spinner";
import { GET_ORDERS } from "../../Graphql/Query/order";
import { GET_USER } from "../../Graphql/Query/AuthQuery";
import {  useQuery } from "@apollo/client";


const CardTenManageJobs = () => {

  const { data, refetch } = useQuery(GET_ORDERS);
  const { data: currentUser } = useQuery(GET_USER);

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const allJobsCombined = data ? data?.userOrders : [];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (isoTime) => {
    const date = new Date(isoTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    refetch();
  }, []);
  

 
  return (
    <>
      <table className="table-style3 table at-savesearch">
        <thead className="t-head">
          <tr>
            <th scope="col">Buyer</th>
            <th scope="col">Title</th>
            <th scope="col">Date</th>
            <th scope="col">Gig Price</th>
            <th scope="col">Platform Fee</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody className="t-body">
          {currentJobs
            ?.filter((e) => e?.sellerId === currentUser?.currentUser?.id)
            ?.map((item) => (
              <tr>
                <th scope="row">
                  <div className="freelancer-style1 p-0 mb-0 box-shadow-none">
                    <div className="d-lg-flex align-items-lg-center">
                      <div className="thumb w60 position-relative rounded-circle mb15-md">
                        <img
                          alt="rounded"
                          loading="lazy"
                          width={60}
                          height={60}
                          decoding="async"
                          data-nimg={1}
                          className="rounded-circle mx-auto"
                          style={{ color: "transparent" }}
                          src={`${"https://yassohapi.devssh.xyz"}${
                            item?.buyerId === currentUser?.currentUser?.id
                              ? item?.seller?.profilePic
                              : item?.buyer?.profilePic
                          }`}
                        />
                        {/* <span className="online-badge2" /> */}
                      </div>
                      <div className="details ml15 ml0-md mb15-md">
                        <h5 className="title mb-2">
                          {currentUser?.currentUser?.id === item?.buyerId
                            ? item?.seller?.username
                            : item?.buyer?.username}
                        </h5>
                        {/* <h6 className="mb-0 text-thm">{item?.buyer?.email}</h6> */}
                      </div>
                    </div>
                  </div>
                </th>
                <td className="vam">
                  <span>{item?.gig?.title}</span>
                </td>
                <td className="vam">{formatDate(item?.orderDate)}</td>
                <td className="vam">
                  <span>{item?.gig?.price}</span>
                </td>
                <td className="vam">
                  <span>{item?.appFee}</span>
                </td>
                <td className="vam">
                  <span
                    className={`pending-style ${
                      item?.status === "in_progress"
                        ? "style2"
                        : item?.status === "completed"
                        ? "style7"
                        : item?.status === "cancelled"
                        ? "style3"
                        : "style1"
                    }`}
                  >
                    {item?.status === "in_progress"
                      ? "InProgress"
                      : capitalizeFirstLetter(item?.status)}
                  </span>
                </td>
              
              </tr>
            ))}
        </tbody>
      </table>
      <div className="mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
     
    </>
  );
};

export default CardTenManageJobs;
