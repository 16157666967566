/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useQuery,useMutation } from "@apollo/client";
import { GET_GIG } from "../../Graphql/Query/Gig";
import {
  DELETE_ORDER,
} from "../../Graphql/Mutation/Auth/order/order";
import toast from "react-hot-toast";


const CardNineManageService = () => {
  const { data,refetch } = useQuery(GET_GIG);
  const [ deleteGig ] = useMutation(DELETE_ORDER);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const allJobsCombined = data ? data?.sellerGigs : []
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  async function deleteOrder(id) {
    deleteGig({ variables: { gigId: id } })
      .then((result) => {
        console.log(result, 'result----->>>');
        if (result && result.data) {
          toast.success("Gig Deleted SuccessFully");
          refetch()
        }
      })
      .catch((errors) => {
        console.error(errors);
      });
  }

  return (
    <>
      <table className="table-style3 table at-savesearch">
        <thead className="t-head">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Category</th>
            <th scope="col">Type/Cost</th>
            <th scope="col">Delivery Time</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody className="t-body">
          {currentJobs?.map((job) => (
            <tr>
              <th className="dashboard-img-service" scope="row">
                <div className="listing-style1 list-style d-block d-xl-flex align-items-start border-0 mb-0">
                  {/* <div className="list-thumb flex-shrink-0 bdrs4 mb10-lg">
                     <img
                      alt="thumb"
                      loading="lazy"
                      width={122}
                      height={91}
                      decoding="async"
                      data-nimg={1}
                      className="w-100"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75
                                "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75"
                    /> 
                  </div> */}
                  <div className="list-content flex-grow-1 py-0 pl15 pl0-lg">
                    <h6 className="list-title mb-0">
                      <a href="#">
                       {job?.title}
                      </a>
                    </h6>
                    {/* <ul className="list-style-type-bullet ps-3 dashboard-style mb-0">
                      <li>Delivered within a day</li>
                      <li>Delivery Time Decreased</li>
                      <li>Upload apps to Stores</li>
                    </ul> */}
                  </div>
                </div>
              </th>
              <td className="align-top">
                <span className="fz15 fw400">{job?.serviceCategory?.name}</span>
              </td>
              <td className="align-top">
                <span className="fz14 fw400">
                  ${/* */}{job?.price}{/* */}
                </span>
              </td>
              <td className="align-top">
                <span className="fz14 fw400">
                {job?.deliveryTime}
                </span>
              </td>
              <td className="align-top">
              <div className="d-flex">
                    {/* <button
                    
                      className="pending-style style7 me-2 btn btn-outline-none"
                      id="edit"
                      data-bs-toggle="modal"
                      data-bs-target="#proposalModal"
                    >
                      <span className="flaticon-pencil" />
                    </button> */}
                    <button
                      className="pending-style style3 btn btn-outline-none"
                      id="delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      onClick={()=>deleteOrder(job?.id)}
                    >
                      <span className="flaticon-delete" />
                    </button>
                  </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CardNineManageService;
