import { BrowserRouter as Router, Routes, Route,Navigate,Outlet } from "react-router-dom";
import "./App.css";
import Index from "./Components/Home/Index";
import Service from "./Pages/BrowseJobs/Service/Service";
import ServiceSingle from "./Pages/BrowseJobs/Service/ServiceSingle";
import Project from "./Pages/BrowseJobs/Project/Project";
import ProjectSingle from "./Pages/BrowseJobs/Project/ProjectSingle";
import JobView from "./Pages/BrowseJobs/JobView/JobView";
import JobViewSingle from "./Pages/BrowseJobs/JobView/JobViewSingle";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Propsals from "./Pages/Dashboard/Propsals";
import Save from "./Pages/Dashboard/Save";
import Message from "./Pages/Dashboard/Message";
import Reviews from "./Pages/Dashboard/Reviews";
import Inovice from "./Pages/Dashboard/Inovice";
import Payouts from "./Pages/Dashboard/Payouts";
import Statement from "./Pages/Dashboard/Statement";
import ManageService from "./Pages/Dashboard/ManageService";
import ManageJobs from "./Pages/Dashboard/ManageJobs";
import ManageProject from "./Pages/Dashboard/ManageProject";
import AddServices from "./Pages/Dashboard/AddServices";
import CreateProject from "./Pages/Dashboard/CreateProject";
import MyProfile from "./Pages/Dashboard/MyProfile";
import Employee from "./Pages/Dashboard/Employee/Employee";
import EmployeeSingle from "./Pages/Dashboard/Employee/EmployeeSingle";
import Freelancer from "./Pages/Dashboard/Freelancer/Freelancer";
import FreelancerSingle from "./Pages/Dashboard/Freelancer/FreelancerSingle";
import BecomeSeller from "./Pages/Dashboard/BecomeSeller/BecomeSeller";
import About from "./Pages/Pages/About/About";
import Contact from "./Pages/Pages/Contact/Contact";
import Faq from "./Pages/Pages/Faq/Faq";
import Help from "./Pages/Pages/Help/Help";
import Invoices from "./Pages/Pages/Invoices/Invoices";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Pricing from "./Pages/Pages/Pricing/Pricing";
import Terms from "./Pages/Pages/Terms/Terms";
import NotFound from "./Pages/Pages/404/NotFound";
import ProLoginPage from "./Pages/ProAuth/ProLoginPage";
import ProSignupPage from "./Pages/ProAuth/ProSignupPage";
import FreelancerRegister from "./Pages/Auth/FreelancerRegister";
import WhoweAre from "./Pages/WhoweAre";
import MyOrder from "./Pages/Dashboard/MyOrder";
import Forgot from "./Pages/Auth/Forgot";
import Reset from "./Pages/Auth/Reset";

function App() {

  const token = localStorage.getItem('authToken')

  const PrivateRoutes = () => {
  return (
    token ? <Outlet/> : <Navigate to='/login'/>
    )
  }


  return (
    <Router>
      <Routes>
        <Route path="/" index element={<Index />} />{" "}
        <Route path="/service" index element={<Service />} />{" "}
        <Route path="/servicesingle" index element={<ServiceSingle />} />{" "}
        <Route path="/project" index element={<Project />} />{" "}
        <Route path="/projectsingle" index element={<ProjectSingle />} />{" "}
        <Route path="/jobview" index element={<JobView />} />{" "}
        <Route path="/jobviewsingle" index element={<JobViewSingle />} />{" "}
        <Route path="/whoweare" index element={<WhoweAre />} />{" "}


        {/* Dashboard Routing Start */}
        <Route element={<PrivateRoutes/>}>
        <Route path="/dashboard" index element={<Dashboard/>}/> {" "}
        <Route path="/dashboard/propsals" index element={<Propsals />} />{" "}
        <Route path="/dashboard/save" index element={<Save />} />{" "}
        <Route path="/dashboard/message/:id" index element={<Message />} />{" "}
        <Route path="/dashboard/message" index element={<Message />} />{" "}
        <Route path="/dashboard/reviews" index element={<Reviews />} />{" "}
        <Route path="/dashboard/invoice" index element={<Inovice />} />{" "}
        <Route path="/dashboard/payouts" index element={<Payouts />} />{" "}
        <Route path="/dashboard/statement" index element={<Statement />} />{" "}
        <Route path="/dashboard/manageservice" index element={<ManageService />} />{" "}
        <Route path="/dashboard/managejobs" index element={<ManageJobs />} />{" "}
        <Route path="/dashboard/manageorder" index element={<MyOrder />} />{" "}
        <Route path="/dashboard/manageproject" index element={<ManageProject />} />{" "}
        <Route path="/dashboard/addservices" index element={<AddServices />} />{" "}
        <Route path="/dashboard/createproject" index element={<CreateProject />} />{" "}
        <Route path="/dashboard/myprofile" index element={<MyProfile />} />{" "}
        </Route>
        {/* Dashboard Routing End */}

        {/* Dashboard Child Routing Start */}
        <Route path="/employee" index element={<Employee />} />{" "}
        <Route path="/employeesingle" index element={<EmployeeSingle />} />{" "}
        <Route path="/freelancer" index element={<Freelancer />} />{" "}
        <Route path="/freelancersingle" index element={<FreelancerSingle />} />{" "}
        <Route path="/becomeseller" index element={<BecomeSeller />} />{" "}
        {/* Dashboard Child Routing End */}

        {/* Pages Routes Start */}
        <Route path="/about" index element={<About />} />{" "}
        <Route path="/contact" index element={<Contact />} />{" "}
        <Route path="/faq" index element={<Faq />} />{" "}
        <Route path="/help" index element={<Help />} />{" "}
        <Route path="/invoices" index element={<Invoices />} />{" "}
        <Route path="/pricing" index element={<Pricing />} />{" "}
        <Route path="/terms" index element={<Terms />} />{" "}
        <Route path="/login" index element={<Login />} />{" "}
        <Route path="/forgot" index element={<Forgot />} />{" "}
        <Route path="/reset" index element={<Reset />} />{" "}
        <Route path="/register" index element={<Register />} />{" "}
        <Route path="/becomesellersignup" index element={<FreelancerRegister />} />{" "}
        <Route path="/*" index element={<NotFound />} />{" "}
        {/* Pages Routes End */}

        <Route path="/prologin" index element={<ProLoginPage />} />{" "}
        <Route path="/prosignup" index element={<ProSignupPage />} />{" "}
        <Route path="*" index element={<NotFound />} />{" "}

      </Routes>{" "}
    </Router>
  );
}

export default App;
