import React from "react";
import ServiceSection1 from "../../../Components/BrowserJobs/Service/ServiceSection1";
import Layout from "../../../Layout/Layout";

const Service = () => {
  return <>
  <Layout>
      <ServiceSection1 />
  </Layout>
     </>
};

export default Service;
