import { gql } from "@apollo/client";

export const GET_USER = gql`
query currentUser {
    currentUser {
      id
      role
      id
      role
      email
      username
      firstName
      lastName
      profilePic
      parish
      services {
        id
        name
      }
      service_areas {
        id
        area_name
      }
      profilePic
    }
  }
`;
