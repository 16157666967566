import React from 'react'
import LoginSection from '../../Components/Auth/LoginSection';
import Layout from '../../Layout/Layout'

const Login = () => {
  return (
    <>
    <Layout>
      <LoginSection />
    </Layout>
    </>
  )
}

export default Login