import React, { useState } from "react";

const Services = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handlerMouseOver1 = () => {
    setShow1(true);
  };
  const handlerMouseOut1 = () => {
    setShow1(false);
  };
  const handlerMouseOver2 = () => {
    setShow2(true);
  };
  const handlerMouseOut2 = () => {
    setShow2(false);
  };
  const handlerMouseOver3 = () => {
    setShow3(true);
  };
  const handlerMouseOut3 = () => {
    setShow3(false);
  };
  const handlerMouseOver4 = () => {
    setShow4(true);
  };
  const handlerMouseOut4 = () => {
    setShow4(false);
  };
  return (
    <>
      <div className="row advertisers-service-sec">
        <div className="col-lg-6 my-2">
          <div
            className="service-card flex-column cursor-pointer rounded border d-flex"
            onMouseOut={handlerMouseOut1}
            onMouseOver={handlerMouseOver1}
          >
            <h3 className="text-center">Desing Service</h3>
            {show1 ? (
              <ul
                style={{
                  maxHeight: show1 ? "200px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease",
                }}
              >
                <li>Graphic Design</li>
                <li>Whiteboard Animation</li>
                <li>Speed Drawing</li>
                <li>Business Card Designing</li>
                <li>Ebook Cover Design</li>
                <li>Flyers</li>
                <li>Infographics</li>
                <li>Create Vector graphics</li>
              </ul>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 my-2">
          <div
            className="service-card cursor-pointer flex-column rounded border d-flex"
            onMouseOut={handlerMouseOut2}
            onMouseOver={handlerMouseOver2}
          >
            <h3 className="text-center">Web Services</h3>
            {show2 ? (
              <ul
                style={{
                  maxHeight: show2 ? "200px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease",
                }}
              >
                <li>SEO, SMM and SEM</li>
                <li>Keyword research</li>
                <li>SEM (Search Engine Marketing)g</li>
                <li>Fixing or Customization WordPress Blogs</li>
                <li>Website/App testing</li>
                <li>Apps and Bots</li>
                <li>Designing Chatbots </li>
                <li>Mobile Apps (Gaming)</li>
                <li>Website Design</li>
              </ul>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 my-2">
          <div
            className="service-card cursor-pointer flex-column rounded border d-flex"
            onMouseOut={handlerMouseOut3}
            onMouseOver={handlerMouseOver3}
          >
            <h3 className="text-center">Social Media </h3>
            {show3 ? (
              <ul
                style={{
                  maxHeight: show3 ? "200px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease",
                }}
              >
                <li> YouTube Channel Creation</li>
                <li>CMS (Content Management System)</li>
                <li>Content Creation</li>
                <li>Video Marketing</li>
                <li>Social Media Manager</li>
                <li>Marketing and Advertising</li>
                <li>Social Media Promotion </li>
                <li>Marketing i.e. Cold Calling</li>
                <li>+ OTHERS</li>
              </ul>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 my-2">
          <div
            className="service-card cursor-pointer flex-column rounded border d-flex"
            onMouseOut={handlerMouseOut4}
            onMouseOver={handlerMouseOver4}
          >
            <h3 className="text-center">Writing Recording Services</h3>
            {show4 ? (
              <ul
                style={{
                  maxHeight: show4 ? "200px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease",
                }}
              >
                <li>Book writing and Editing</li>
                <li>Resume Writing</li>
                <li>Video Testimonials </li>
                <li>Voiceover Artist</li>
                <li>Voiceover Artist</li>
                <li>Proofreading</li>
                <li>Transcription</li>
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
