import React from 'react'
import FreelancerSectionSingle1 from '../../../Components/Dashboard/Freelancer/FreelancerSectionSingle1'
import Layout from '../../../Layout/Layout'

const FreelancerSingle = () => {
  return (
    <>
    <Layout>
      <FreelancerSectionSingle1 />
    </Layout>
    </>
  )
}

export default FreelancerSingle


