import React from 'react'
import Layout from '../../../Layout/Layout'
import ProjectSection2 from '../../../Components/BrowserJobs/Projects/ProjectSection2'

const ProjectSingle = () => {
  return (
    <>
      <Layout>
        <ProjectSection2 />
      </Layout>
    </>
  )
}

export default ProjectSingle


