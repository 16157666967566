import React from 'react'
import NotFound404 from '../../../Components/Pages/404/NotFound404'

const NotFound = () => {
  return (
    <>
     <NotFound404 />
    </>
  )
}

export default NotFound


