import React from 'react'
import Layout from '../../../Layout/Layout'
import FaqSection1 from '../../../Components/Pages/Faq/FaqSection1'

const Faq = () => {
  return (
    <>
      <Layout>
        <FaqSection1 />
      </Layout>
    </>
  )
}

export default Faq