import React from 'react'
import Layout from '../../../Layout/Layout'
import ContactSection1 from '../../../Components/Pages/Contact/ContactSection1'

const Contact = () => {
  return (
    <>
      <Layout>
        <ContactSection1 />
      </Layout>
    </>
  )
}

export default Contact