import React from 'react'

const ReviewsSection1 = () => {
  return (
    <>
<>
  <div className="col-lg-12">
    <div className="dashboard_title_area">
      <h2>Reviews</h2>
      <p className="text">Lorem ipsum dolor sit amet, consectetur.</p>
    </div>
  </div>
  <div className="row">
    <div className="col-xl-12">
      <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
        <div className="packages_table table-responsive">
          <div className="navtab-style1">
            <nav>
              <div className="nav nav-tabs mb30">
                <button className="nav-link fw500 ps-0 active">Services</button>
              </div>
            </nav>
            <div className="col-md-12">
              <div className="pb20 bdrb1">
                <div className="mbp_first position-relative d-sm-flex align-items-center justify-content-start mb30-sm mt30">
                  <img
                    alt="comments-2.png"
                    loading="lazy"
                    width={60}
                    height={60}
                    decoding="async"
                    data-nimg={1}
                    className="mr-3"
                    style={{ color: "transparent" }}
                    srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                          "
                    src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                  />
                  <div className="ml20 ml0-xs mt20-xs">
                    <div className="del-edit">
                      <span className="flaticon-flag" />
                    </div>
                    <h6 className="mt-0 mb-1">Ali Tufan</h6>
                    <div className="d-flex align-items-center">
                      <div>
                        <i className="fas fa-star vam fz10 review-color me-2" />
                        <span className="fz15 fw500">4.98</span>
                      </div>
                      <div className="ms-3">
                        <span className="fz14 text">
                          Published 2 months ago
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text mt20 mb20">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text.
                </p>
                <a
                  className="ud-btn bgc-thm4 text-thm"
                  href="/"
                >
                  Respond
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <div className="pb20 bdrb1">
                <div className="mbp_first position-relative d-sm-flex align-items-center justify-content-start mb30-sm mt30">
                  <img
                    alt="comments-2.png"
                    loading="lazy"
                    width={60}
                    height={60}
                    decoding="async"
                    data-nimg={1}
                    className="mr-3"
                    style={{ color: "transparent" }}
                    srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                          "
                    src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                  />
                  <div className="ml20 ml0-xs mt20-xs">
                    <div className="del-edit">
                      <span className="flaticon-flag" />
                    </div>
                    <h6 className="mt-0 mb-1">Ali Tufan</h6>
                    <div className="d-flex align-items-center">
                      <div>
                        <i className="fas fa-star vam fz10 review-color me-2" />
                        <span className="fz15 fw500">4.98</span>
                      </div>
                      <div className="ms-3">
                        <span className="fz14 text">
                          Published 2 months ago
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text mt20 mb20">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text.
                </p>
                <a
                  className="ud-btn bgc-thm4 text-thm"
                  href="/"
                >
                  Respond
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <div className="pb20">
                <div className="mbp_first position-relative d-sm-flex align-items-center justify-content-start mb30-sm mt30">
                  <img
                    alt="comments-2.png"
                    loading="lazy"
                    width={60}
                    height={60}
                    decoding="async"
                    data-nimg={1}
                    className="mr-3"
                    style={{ color: "transparent" }}
                    srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                          "
                    src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                  />
                  <div className="ml20 ml0-xs mt20-xs">
                    <div className="del-edit">
                      <span className="flaticon-flag" />
                    </div>
                    <h6 className="mt-0 mb-1">Ali Tufan</h6>
                    <div className="d-flex align-items-center">
                      <div>
                        <i className="fas fa-star vam fz10 review-color me-2" />
                        <span className="fz15 fw500">4.98</span>
                      </div>
                      <div className="ms-3">
                        <span className="fz14 text">
                          Published 2 months ago
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text mt20 mb20">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text.
                </p>
                <a
                  className="ud-btn bgc-thm4 text-thm"
                  href="/"
                >
                  Respond
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </>
  )
}

export default ReviewsSection1

