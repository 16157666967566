import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import ManageJobsSection1 from "../../Components/Dashboard/ManageJobs/ManageJobsSection1";

const ManageJobs = () => {

  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <ManageJobsSection1 />
        </div>
      </Layout>
    </>
  );
};

export default ManageJobs;
