import React from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'

const Layout = ({ children }) => {
  return (
    <>
      <div className="wrapper ovh mm-page mm-slideout">
        <Header />
        {children}
        <Footer />
      </div>
    </>
  )
}

export default Layout