import { gql } from "@apollo/client";

export const CREATE_CHAT_MUTATION = gql`
mutation CreateChat($receiverId: Int!, $text: String!) {
  createChat(receiverId: $receiverId, text: $text) {
    senderId
    receiverId
    messages {
      text
      senderId
      sender {
        username
        profilePic
      }
    }
    receiver {
      username
      profilePic
    }
    sender {
      username
      profilePic
    }
    }
}`
export const SEND_MESSAGE = gql`
mutation SendMessage($chatId: Int!, $text: String!) {
  sendMessage(chatId: $chatId, text: $text) {
    id
  }
}`

export const MESSAGE_SUBSCRIPTION = gql`
subscription MessageCreated($chatId: Int!) {
  messageCreated(chatId: $chatId) {
    text
    sender {
      profilePic
      username
    }
  }
}
`

