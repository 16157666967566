/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import * as Yup from 'yup';
import { useEffect, useState } from "react";
import "./profile.css";
import { Field, Form, Formik } from "formik";

function ProSignup() {
    const [current, setCurrent] = useState(1);
    const [selectedButton, setSelectedButton] = useState(1);

    const handleClick = (btnNumber) => {
        setSelectedButton(btnNumber);
    };

    const steps = 4;
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        setPercent((100 / steps) * current);
    }, [current, steps]);

    const handleNext = () => {
        setCurrent(current + 1);
    };

    const handlePrevious = () => {
        setCurrent(current - 1);
    };

    return (
        <>
            <section className="section section-theme-1 section-downloads mt-60  pt-md-100">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                                <Formik
                                    initialValues={{
                                        full_name: "",
                                        number: "",
                                        industry: "",

                                        company_name: "",
                                        including: "",
                                        revenue: "",

                                        job: "",
                                    }}
                                    onSubmit={(values, actions) => {
                                        console.log(values);
                                    }}
                                >
                                    {({ values }) => (
                                        <Form id="msform">
                                            <div className="progress">
                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar"
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: `${percent}%` }}
                                                />
                                            </div>{" "}
                                            <br />
                                            {current === 1 && (
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h2 className="fs-title">Your free trial is now active</h2>
                                                            </div>
                                                        </div>{" "}
                                                        <label className="fieldlabels">First Name :</label>{" "}
                                                        <Field type="text" name="full_name"
                                                            placeholder="Full Name" />{" "}
                                                        <label className="fieldlabels">Phone Number : </label>{" "}
                                                        <Field type="text" name="number" placeholder="Phone Number" />
                                                        <label className="fieldlabels">Choose Your Industry:</label>{" "}

                                                        <Field as="select" className="w-100"
                                                            style={{ padding: '14px', borderRadius: '10px' }}
                                                            name="industry">
                                                            <option value="painting">Painting</option>
                                                            <option value="Junk Removal">Junk Removal</option>
                                                        </Field>
                                                    </div>{" "}
                                                    <button
                                                        type="button"
                                                        className="next action-button"
                                                        onClick={handleNext}
                                                        disabled={values.full_name && values.number && values.industry ? false : true}
                                                    >
                                                        Next
                                                    </button>
                                                </fieldset>
                                            )}
                                            {current === 2 && (
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h2>Set up your HVAC business</h2>
                                                                <h4 className="fs-title fs-5">This will help us customize your experience at Jobber.</h4>
                                                            </div>
                                                        </div>{" "}

                                                        <label className="fieldlabels">Company Name :</label>{" "}
                                                        <Field type="text" name="company_name"
                                                            placeholder="Company Name" />{" "}

                                                        <label className="fieldlabels">What's your team size (including yourself)?</label>{" "}
                                                        <Field as="select" className="w-100"
                                                            style={{ padding: '14px', borderRadius: '10px' }}
                                                            name="including">
                                                            <option value="" disabled>Please Select</option>
                                                            <option value="Just me">Just me</option>
                                                            <option value="2-3 People">2-3 People</option>
                                                            <option value="4-10 People">4-10 People</option>
                                                            <option value="10 + People">10 + People</option>
                                                        </Field>

                                                        <label className="fieldlabels mt-4">What's your estimated annual revenue?</label>{" "}
                                                        <Field as="select" className="w-100"
                                                            style={{ padding: '14px', borderRadius: '10px' }}
                                                            name="revenue">
                                                            <option value="" disabled>Please Select</option>
                                                            <option value="$0 - $50,000">$0 - $50,000</option>
                                                            <option value="$50,000 - $150,000">$50,000 - $150,000</option>
                                                            <option value="$150,000 - $500,000">$150,000 - $500,000</option>
                                                            <option value="$500,000+">$500,000+</option>
                                                            <option value="I'd Prefer not to say">I'd Prefer not to say</option>
                                                        </Field>

                                                    </div>{" "}
                                                    <button
                                                        type="button"
                                                        className="next action-button"
                                                        onClick={handleNext}
                                                        disabled={values.company_name && values.including && values.revenue ? false : true}
                                                    >
                                                        Next
                                                    </button>{" "}
                                                    <button
                                                        type="button"
                                                        className="previous action-button-previous"
                                                        onClick={handlePrevious}
                                                    >
                                                        Previous
                                                    </button>
                                                </fieldset>
                                            )}
                                            {current === 3 && (
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h2>What is your top priority?</h2>
                                                                <h4 className="fs-title fs-5">While Jobber is committed to helping you succeed in all aspects, which is your immediate focus for this year?</h4>
                                                            </div>
                                                        </div>{" "}

                                                        <div className="container">
                                                            <div className="btn-wrapper">
                                                                <div className={`btn duolingo-btn ${selectedButton === 1 ? 'active' : ''}`} id="btn-1" onClick={() => handleClick(1)}>
                                                                    <p className="btn-text mb-0" id="bt-1">Increase efficiency</p>
                                                                </div>
                                                            </div>
                                                            <div className="btn-wrapper">
                                                                <div className={`btn duolingo-btn ${selectedButton === 2 ? 'active' : ''}`} id="btn-2" onClick={() => handleClick(2)}>
                                                                    <p className="btn-text mb-0" id="bt-2">Get Paid faster</p>
                                                                </div>
                                                            </div>
                                                            <div className="btn-wrapper">
                                                                <div className={`btn duolingo-btn ${selectedButton === 3 ? 'active' : ''}`} id="btn-3" onClick={() => handleClick(3)}>
                                                                    <p className="btn-text mb-0" id="bt-3">
                                                                        Win more work</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>{" "}
                                                    <button
                                                        type="button"
                                                        className="next action-button"
                                                        onClick={handleNext}
                                                    >
                                                        Next
                                                    </button>{" "}
                                                    <button
                                                        type="button"
                                                        className="previous action-button-previous"
                                                        onClick={handlePrevious}
                                                    >
                                                        Previous
                                                    </button>
                                                </fieldset>
                                            )}
                                            {current === 4 && (
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h2>One last thing...</h2>
                                                                <h4 className="fs-title fs-5">How did you find out about Jobber? We'd love to know!</h4>
                                                            </div>
                                                        </div>{" "}
                                                        <label className="fieldlabels mt-4">How did you find out about Jobber?</label>{" "}
                                                        <Field type="text" name="job" placeholder="How did you find out about Jobber?" />
                                                    </div>{" "}
                                                    <button
                                                        type="submit"
                                                        className="next action-button"
                                                    >
                                                        Submit
                                                    </button>{" "}
                                                    <button
                                                        type="button"
                                                        className="previous action-button-previous"
                                                        onClick={handlePrevious}
                                                    >
                                                        Previous
                                                    </button>
                                                </fieldset>
                                            )}

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}


export default ProSignup



