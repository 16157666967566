/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const FreelancerSectionSingle1 = () => {
  return (
    <>
<div className="body_content">
  <section className="categories_list_section overflow-hidden" style={{marginTop:'8%'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="listings_category_nav_list_menu">
            <ul className="mb0 d-flex ps-0">
              <li>
                <a className="active">All Categories</a>
              </li>
              <li>
                <a className="">Graphics Design</a>
              </li>
              <li>
                <a className="">Digital Marketing</a>
              </li>
              <li>
                <a className="">Writing Translation</a>
              </li>
              <li>
                <a className="">Video Animation</a>
              </li>
              <li>
                <a className="">Music Audio</a>
              </li>
              <li>
                <a className="">Programming Tech</a>
              </li>
              <li>
                <a className="">Business</a>
              </li>
              <li>
                <a className="">Lifestyle</a>
              </li>
              <li>
                <a className="">Trending</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8 col-lg-10">
          <div className="breadcumb-style1 mb10-xs">
            <div className="breadcumb-list">
              <a>Home</a>
              <a>Services</a>
              <a>Design &amp; Creative</a>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-2">
          <div className="d-flex align-items-center justify-content-sm-end">
            <a className="position-relative ui-share-toggle">
              <div className="share-save-widget d-flex align-items-center">
                <span className="icon flaticon-share dark-color fz12 mr10" />
                <div className="h6 mb-0">Share</div>
              </div>
            </a>
            <a>
              <div className="share-save-widget d-flex align-items-center ml15">
                <span className="icon flaticon-like dark-color fz12 mr10" />
                <div className="h6 mb-0">Save</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section pt-0">
    <div className="cta-service-v1 freelancer-single-style mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
      <img
        alt="vector-img"
        loading="lazy"
        width={198}
        height={226}
        decoding="async"
        data-nimg={1}
        className="left-top-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75"
      />
      <img
        alt="vector-img"
        loading="lazy"
        width={255}
        height={181}
        decoding="async"
        data-nimg={1}
        className="right-bottom-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75"
      />
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-xl-7">
            <div className="position-relative">
              <h2>I will design website UI UX in adobe xd or figma</h2>
              <div className="list-meta d-sm-flex align-items-center mt30">
                <a className="position-relative freelancer-single-style">
                  <span className="online" />
                  <img
                    alt="Freelancer Photo"
                    loading="lazy"
                    width={90}
                    height={90}
                    decoding="async"
                    data-nimg={1}
                    className="rounded-circle w-100 wa-sm mb15-sm"
                    style={{ color: "transparent" }}
                    srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75
                    "
                    src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75"
                  />
                </a>
                <div className="ml20 ml0-xs">
                  <h5 className="title mb-1">Leslie Alexander</h5>
                  <p className="mb-0">UI/UX Designer</p>
                  <p className="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                    <i className="fas fa-star vam fz10 review-color me-2" />
                    {/* */}4.82 94 reviews
                  </p>
                  <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                    <i className="flaticon-place vam fz20 me-2" /> London, UK
                  </p>
                  <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                    <i className="flaticon-30-days vam fz20 me-2" /> Member
                    since April 1, 2022
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="pt10 pb90 pb30-md">
    <div className="container">
      <div className="row wow fadeInUp">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-target" />
                </div>
                <div className="details">
                  <h5 className="title">Job Success</h5>
                  <p className="mb-0 text">98%</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-goal" />
                </div>
                <div className="details">
                  <h5 className="title">Total Jobs</h5>
                  <p className="mb-0 text">921</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-fifteen" />
                </div>
                <div className="details">
                  <h5 className="title">Total Hours</h5>
                  <p className="mb-0 text">1,499</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-file-1" />
                </div>
                <div className="details">
                  <h5 className="title">In Queue Service</h5>
                  <p className="mb-0 text">20</p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-about">
            <h4>Description</h4>
            <p className="text mb30">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <p className="text mb30">
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy. Various
              versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <hr className="opacity-100 mb60 mt60" />
            <h4 className="mb30">Education</h4>
            <div className="educational-quality">
              <div className="m-circle text-thm">M</div>
              <div className="wrapper mb40">
                <span className="tag">2012 - 2014</span>
                <h5 className="mt15">Bachlors in Fine Arts</h5>
                <h6 className="text-thm">Modern College</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a ipsum tellus. Interdum et malesuada fames ac ante ipsum
                  primis in faucibus.
                </p>
              </div>
              <div className="m-circle before-none text-thm">M</div>
              <div className="wrapper mb60">
                <span className="tag">2008 - 2012</span>
                <h5 className="mt15">Computer Science</h5>
                <h6 className="text-thm">Harvartd University</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a ipsum tellus. Interdum et malesuada fames ac ante ipsum
                  primis in faucibus.
                </p>
              </div>
            </div>
            <hr className="opacity-100 mb60" />
            <h4 className="mb30">Work &amp; Experience</h4>
            <div className="educational-quality">
              <div className="m-circle text-thm">M</div>
              <div className="wrapper mb40">
                <span className="tag">2012 - 2014</span>
                <h5 className="mt15">UX Designer</h5>
                <h6 className="text-thm">Dropbox</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a ipsum tellus. Interdum et malesuada fames ac ante ipsum
                  primis in faucibus.
                </p>
              </div>
              <div className="m-circle before-none text-thm">M</div>
              <div className="wrapper mb60">
                <span className="tag">2008 - 2012</span>
                <h5 className="mt15">Art Director</h5>
                <h6 className="text-thm">amazon</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a ipsum tellus. Interdum et malesuada fames ac ante ipsum
                  primis in faucibus.
                </p>
              </div>
            </div>
            <hr className="opacity-100 mb60" />
            <h4 className="mb30">Awards adn Certificates</h4>
            <div className="educational-quality ps-0">
              <div className="wrapper mb40">
                <span className="tag">2012 - 2014</span>
                <h5 className="mt15">UI UX Design</h5>
                <h6 className="text-thm">Udemy</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a ipsum tellus. Interdum et malesuada fames ac ante ipsum
                  <br className="d-none d-lg-block" />
                  primis in faucibus.
                </p>
              </div>
              <div className="wrapper mb60">
                <span className="tag">2008 - 2012</span>
                <h5 className="mt15">App Design</h5>
                <h6 className="text-thm">Google</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a ipsum tellus. Interdum et malesuada fames ac ante ipsum
                  <br className="d-none d-lg-block" />
                  primis in faucibus.
                </p>
              </div>
            </div>
            <hr className="opacity-100 mb60" />
            <h4 className="mb30">Featured Services</h4>
            <div className="row mb35">
              <div className="col-sm-6 col-xl-4">
                <div className="listing-style1">
                  <div className="list-thumb">
                    <img
                      alt="thumbnail"
                      loading="lazy"
                      width={255}
                      height={190}
                      decoding="async"
                      data-nimg={1}
                      className="w-100 h-100 object-fit-cover"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75"
                    />
                    <a className="listing-fav fz12">
                      <span className="far fa-heart" />
                    </a>
                  </div>
                  <div className="list-content">
                    <p className="list-text body-color fz14 mb-1">
                      Web &amp; App Design
                    </p>
                    <h6 className="list-title">
                      <a href="/">
                        I will design modern websites in figma o...
                      </a>
                    </h6>
                    <div className="review-meta d-flex align-items-center">
                      <i className="fas fa-star fz10 review-color me-2" />
                      <p className="mb-0 body-color fz14">
                        <span className="dark-color me-2">4.82</span>94{/* */}
                        reviews
                      </p>
                    </div>
                    <hr className="my-2" />
                    <div className="list-meta mt15">
                      <div className="budget">
                        <p className="mb-0 body-color">
                          Starting at
                          <span className="fz17 fw500 dark-color ms-1">
                            ${/* */}983
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4">
                <div className="listing-style1">
                  <div className="list-thumb">
                    <img
                      alt="thumbnail"
                      loading="lazy"
                      width={255}
                      height={190}
                      decoding="async"
                      data-nimg={1}
                      className="w-100 h-100 object-fit-cover"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-2.jpg&w=256&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-2.jpg&w=256&q=75"
                    />
                    <a className="listing-fav fz12">
                      <span className="far fa-heart" />
                    </a>
                  </div>
                  <div className="list-content">
                    <p className="list-text body-color fz14 mb-1">
                      Art &amp; Illustration
                    </p>
                    <h6 className="list-title">
                      <a href="/">
                        I will create modern flat design illustr...
                      </a>
                    </h6>
                    <div className="review-meta d-flex align-items-center">
                      <i className="fas fa-star fz10 review-color me-2" />
                      <p className="mb-0 body-color fz14">
                        <span className="dark-color me-2">4.82</span>94{/* */}
                        reviews
                      </p>
                    </div>
                    <hr className="my-2" />
                    <div className="list-meta mt15">
                      <div className="budget">
                        <p className="mb-0 body-color">
                          Starting at
                          <span className="fz17 fw500 dark-color ms-1">
                            ${/* */}983
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4">
                <div className="listing-style1">
                  <div className="list-thumb">
                    <img
                      alt="thumbnail"
                      loading="lazy"
                      width={255}
                      height={190}
                      decoding="async"
                      data-nimg={1}
                      className="w-100 h-100 object-fit-cover"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-3.jpg&w=256&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-3.jpg&w=256&q=75"
                    />
                    <a className="listing-fav fz12">
                      <span className="far fa-heart" />
                    </a>
                  </div>
                  <div className="list-content">
                    <p className="list-text body-color fz14 mb-1">
                      Design &amp; Creative
                    </p>
                    <h6 className="list-title">
                      <a href="/">
                        I will build a fully responsive design i...
                      </a>
                    </h6>
                    <div className="review-meta d-flex align-items-center">
                      <i className="fas fa-star fz10 review-color me-2" />
                      <p className="mb-0 body-color fz14">
                        <span className="dark-color me-2">4.82</span>94{/* */}
                        reviews
                      </p>
                    </div>
                    <hr className="my-2" />
                    <div className="list-meta mt15">
                      <div className="budget">
                        <p className="mb-0 body-color">
                          Starting at
                          <span className="fz17 fw500 dark-color ms-1">
                            ${/* */}983
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="opacity-100" />
            <div className="product_single_content mb50">
              <div className="mbp_pagination_comments">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="total_review mb30 mt45">
                      <h4>80 Reviews</h4>
                    </div>
                    <div className="d-md-flex align-items-center mb30">
                      <div className="total-review-box d-flex align-items-center text-center mb30-sm">
                        <div className="wrapper mx-auto">
                          <div className="t-review mb15">4.96</div>
                          <h5>Exceptional</h5>
                          <p className="text mb-0">3,014 reviews</p>
                        </div>
                      </div>
                      <div className="wrapper ml60 ml0-sm">
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">5 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "90%" }}
                              aria-valuenow={90}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">58</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">4 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar w-75"
                              aria-valuenow={75}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">20</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">3 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar w-50"
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">15</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">2 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "30%" }}
                              aria-valuenow={30}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">2</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">1 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "20%" }}
                              aria-valuenow={10}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mbp_first position-relative d-flex align-items-center justify-content-start mb30-sm">
                      <img
                        alt="comments-2.png"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="mr-3"
                        style={{ color: "transparent" }}
                        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                        "
                        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                      />
                      <div className="ml20">
                        <h6 className="mt-0 mb-0">Bessie Cooper</h6>
                        <div>
                          <span className="fz14">12 March 2022</span>
                        </div>
                      </div>
                    </div>
                    <p className="text mt20 mb20">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                    </p>
                    <div className="review_cansel_btns d-flex">
                      <a>
                        <i className="fas fa-thumbs-up" />
                        Helpful
                      </a>
                      <a>
                        <i className="fas fa-thumbs-down" />
                        Not helpful
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mbp_first position-relative d-flex align-items-center justify-content-start mt30 mb30-sm">
                      <img
                        alt="comments-2.png"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="mr-3"
                        style={{ color: "transparent" }}
                        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                        "
                        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                      />
                      <div className="ml20">
                        <h6 className="mt-0 mb-0">Darrell Steward</h6>
                        <div>
                          <span className="fz14">12 March 2022</span>
                        </div>
                      </div>
                    </div>
                    <p className="text mt20 mb20">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                    </p>
                    <div className="review_cansel_btns d-flex pb30">
                      <a>
                        <i className="fas fa-thumbs-up" />
                        Helpful
                      </a>
                      <a>
                        <i className="fas fa-thumbs-down" />
                        Not helpful
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="position-relative bdrb1 pb50">
                      <a
                        className="ud-btn btn-light-thm"
                        href="/"
                      >
                        See More
                        <i className="fal fa-arrow-right-long" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bsp_reveiw_wrt mb20">
              <h6 className="fz17">Add a Review</h6>
              <p className="text">
                Your email address will not be published. Required fields are
                marked *
              </p>
              <h6>Your rating of this product</h6>
              <div className="d-flex">
                <i className="fas fa-star review-color" />
                <i className="far fa-star review-color ms-2" />
                <i className="far fa-star review-color ms-2" />
                <i className="far fa-star review-color ms-2" />
                <i className="far fa-star review-color ms-2" />
              </div>
              <form className="comments_form mt30 mb30-md">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-4">
                      <label className="fw500 fz16 ff-heading dark-color mb-2">
                        Comment
                      </label>
                      <textarea
                        className="pt15"
                        rows={6}
                        placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb20">
                      <label className="fw500 ff-heading dark-color mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ali Tufan"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb20">
                      <label className="fw500 ff-heading dark-color mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="creativelayers088"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz15 ff-heading">
                        Save my name, email, and website in this browser for the
                        next time I comment.
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <a className="ud-btn btn-thm">
                      Send
                      <i className="fal fa-arrow-right-long" />
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="blog-sidebar ms-lg-auto">
            <div className="price-widget pt25 bdrs8">
              <h3 className="widget-title">
                $29<small className="fz15 fw500">/per hour</small>
              </h3>
              <div className="category-list mt20">
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-place text-thm2 pe-2 vam" />
                    Location
                  </span>
                  <span>London, UK</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-30-days text-thm2 pe-2 vam" />
                    Member since
                  </span>
                  <span>April 2022</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-calendar text-thm2 pe-2 vam" />
                    Last Delivery
                  </span>
                  <span>5 days</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-mars text-thm2 pe-2 vam" />
                    Gender
                  </span>
                  <span>Male</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-translator text-thm2 pe-2 vam" />
                    Languages
                  </span>
                  <span>English</span>
                </a>
                <a className="d-flex align-items-center justify-content-between mb-3">
                  <span className="text">
                    <i className="flaticon-sliders text-thm2 pe-2 vam" />
                    English Level
                  </span>
                  <span>Fluent</span>
                </a>
              </div>
              <div className="d-grid">
                <a className="ud-btn btn-thm" href="/">
                  Contact Me
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
            <div className="sidebar-widget mb30 pb20 bdrs8">
              <h4 className="widget-title">My Skills</h4>
              <div className="tag-list mt30">
                <a>Figma</a>
                <a>Sketch</a>
                <a>HTML5</a>
                <a>Software Design</a>
                <a>Prototyping</a>
                <a>SaaS</a>
                <a>Design Writing</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default FreelancerSectionSingle1

