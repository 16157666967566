/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Footer = () => {
  return (
    <div className='bg-primary'>
      <>
  <section
    className="footer-style1 pt25 pb-0 
          
           "
  >
    <div className="container">
      <div className="row bb-white-light pb10 mb60">
        {/* <div className="col-md-5">
          <div className="d-block text-center text-md-start justify-content-center justify-content-md-start d-md-flex align-items-center mb-3 mb-md-0">
            <a className="fz17 fw500 mr15-md mr30 text-white" href="#">
              Terms of Service
            </a>
            <a className="fz17 fw500 mr15-md mr30 text-white" href="#">
              Privacy Policy
            </a>
            <a className="fz17 fw500 mr15-md mr30 text-white" href="#">
              Site Map
            </a>
          </div>
        </div> */}
        <div className="col-md-7 mx-auto">
          <div className="social-widget text-center">
            <div className="social-style1">
              <a className="text-white me-2 fw500 fz17" href="#">
              LET’S GET SOCIAL.  FOLLOW US
              </a>
              <a>
                <i className="fab fa-facebook-f list-inline-item" />
              </a>
              <a>
                <i className="fab fa-twitter list-inline-item" />
              </a>
              <a>
                <i className="fab fa-instagram list-inline-item" />
              </a>
              <a>
                <i className="fab fa-youtube list-inline-item" />
              </a>
              <a>
                <i className="fab fa-linkedin-in list-inline-item" />
              </a>
              <a>
                <i className="fab fa-tiktok list-inline-item" />
              </a>
              <a>
                <i className="fab fa-pinterest list-inline-item" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-auto justify-content-center">
        <div className="col-sm-6 col-lg-3">
          <div className="link-style1 mb-4 mb-sm-5 ">
            <h5 className="mb15 text-white">Customers</h5>
            <div className="link-list">
              <a href="#">Sign up</a>
              <a href="#">Dashboard</a>
              <li>
                <a href="#">FAQ</a>
              </li>
              <a href="#">Terms & Conditions</a>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="link-style1 mb-4 mb-sm-5 ">
            <h5 className="mb15 text-white">Freelancers</h5>
            <ul className="ps-0">
              <li>
                <a href="#">Sign up as a Freelancer</a>
              </li>
              <li>
                <a href="#">Dashboard</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="link-style1 mb-4 mb-sm-5 ">
            <h5 className="mb15 text-white">About Us</h5>
            <ul className="ps-0">
              <li>
                <a href="#">Our Approach</a>
              </li>
              <li>
                <a href="#">Resource Center</a>
              </li>
              <li>
                
                <a href="/whoweare">Who We Are</a>
              </li>
              
              <li>
                <a href="#">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="col-sm-6 col-lg-3">
          <div className="footer-widget">
            <div className="footer-widget mb-4 mb-sm-5">
              <div className="mailchimp-widget">
                <h5 className="title text-white mb20">Subscribe</h5>
                <div className="mailchimp-style1 ">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your email address"
                  />
                  <button type="submit">Send</button>
                </div>
              </div>
            </div>
            <div className="app-widget mb-4 mb-sm-5">
              <h5 className="title text-white mb20">Get the app.</h5>
              <div className="row mb-4 mb-lg-5">
                <div className="col-lg-12">
                  <a className="app-list d-flex align-items-center mb10">
                    <i className="fab fa-apple fz17 mr15" />
                    <h6 className="app-title fz15 fw400 mb-0 ">iOS App</h6>
                  </a>
                  <a className="app-list d-flex align-items-center">
                    <i className="fab fa-google-play fz15 mr15" />
                    <h6 className="app-title fz15 fw400 mb-0 ">Android App</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  <a className="scrollToHome ">
    <i className="fas fa-angle-up" />
  </a>
</>

    </div>
  )
}

export default Footer