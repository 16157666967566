import React from 'react'
import { useParams } from "react-router-dom";
import Layout from '../../Components/Dashboard/Layout/Layout'
import MessageSection1 from '../../Components/Dashboard/Message/MessageSection1'

const Message = () => {
  const { id } =useParams()
  return (
    <>
       <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <MessageSection1 chatUserId={id} />
        </div>
      </Layout>
    </>
  )
}

export default Message


