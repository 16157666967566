/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { SiCoinmarketcap } from "react-icons/si";
import { SiFreelancer } from "react-icons/si";
import { TbBusinessplan } from "react-icons/tb";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./slider.css";

// import required modules
// import { Navigation } from "swiper/modules";

const Banner = () => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  return (
    <>
      <section
        style={{
          backgroundImage:
            'url("https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fhome%2Fhome-1.jpg&w=3840&q=75")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        {/* <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-banner-wrapper home1_style">
                <div className="ui-hero-slide">
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    style={{ height: "100vh" }}
                  >
                    <SwiperSlide>
                      <img src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fhome%2Fhome-2.jpg&w=3840&q=75" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fhome%2Fhome-1.jpg&w=3840&q=75" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fhome%2Fhome-2.jpg&w=3840&q=75" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fhome%2Fhome-1.jpg&w=3840&q=75" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="position-relative-mt-20">
                  <h3 className="banner-title animate-up-2">
                    Welcome To yaSSoh!{/* */}
                  </h3>
                  <h4 className="banner-text text-white ff-heading  animate-up-3">
                    Free Service Marketplace
                  </h4>
                  <h4 className="banner-text text-white ff-heading  animate-up-3">
                    Connects businesses with freelancers
                  </h4>
                  <h4 className="banner-text text-white ff-heading  animate-up-3">
                    Always Open for business
                  </h4>
                  {/* <div className="advance-search-tab bgc-white bgct-sm p10 p0-md bdrs4 banner-btn position-relative zi9 animate-up-4">
                    <div className="row">
                      <div className="col-md-5 col-lg-6 col-xl-6">
                        <div className="advance-search-field mb10-sm bdrr1 bdrn-sm">
                          <form className="form-search position-relative">
                            <div className="box-search">
                              <span className="icon far fa-magnifying-glass" />
                              <input
                                className="form-control"
                                type="text"
                                placeholder="What are you looking for?"
                                name="search"
                                defaultValue=""
                              />

                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-4 d-none d-md-block">
                      <div className="advance-search-field mb10-sm bdrr1 bdrn-sm">
                          <form className="form-search position-relative">
                            <div className="box-search">
                              <span className="icon far fa-location" />
                              <input
                                className="form-control"
                                type="text"
                                placeholder="ZipCode"
                                name="zip-code"
                                defaultValue=""
                              />

                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-3 col-lg-2 col-xl-2 ps-md-0">
                        <div className="text-center text-xl-end">
                          <button
                            className="ud-btn btn-thm w-100 px-4"
                            type="button"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {!token &&
                  <div className="d-flex justify-content-start mt25">
                    <div className="text-center text-xl-end">
                      <button
                      onClick={()=> navigate("/register")}
                        className="ud-btn btn-thm w-100 px-4"
                        type="button"
                      >
                       Sign Up
                      </button>
                    </div>
                    {/* <div className="text-center text-xl-end ms-5">
                        <button
                          className="ud-btn btn-thm w-100 px-4"
                          type="button"
                        >
                          Join As A Pro 
                        </button>
                      </div> */}
                  </div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex flex-column justify-content-center align-items-center position-relative-mt-20">
                  <div>
                  <SiCoinmarketcap size={120} color="#5bbb7b" />
                  <SiFreelancer size={120} color="#5bbb7b" />
                  </div>
                  <div>
                  <TbBusinessplan size={120} color="#5bbb7b" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
