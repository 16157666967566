import * as yup from "yup";

export const initialValuesSignup = {
  username: "",
  firstName: "",
  lastName: "",
  parish: "",
  role: "",
  services: [],
  service_areas: [],
  email: "",
  profilePic: "",
  password: "",
  checkbox1: false,
  checkbox2: false,
};

export const signUpSchema = yup.object().shape({
  username: yup.string().required("user name is required"),
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  parish: yup.string().required("Parish is required"),
  role: yup.string().required("Role is required"),
  services: yup.array().when("role", {
    is: (val) => val === "SELLER",
    then: (schema) =>
      yup.array().required("Select any other services you do is required"),
    otherwise: (schema) => yup.array(),
  }),
  service_areas: yup.array().when("role", {
    is: (val) => val === "SELLER",
    then: (schema) =>
      yup.array().required("Select Service Areas from parishes is required"),
    otherwise: (schema) => yup.array(),
  }),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(72, "Password must be at most 72 characters")
    .matches(
      /^(?:(?!yassoh).)*$/,
      'Password must not contain any variation of "yassoh"'
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .test(
      "not-common-words",
      "Password must not be commonly used or easily guessed",
      (value) =>
        ![
          "password",
          // "12345678",
          "abcdefg",
          // Add more common words to this list as needed
        ].includes(value.toLowerCase())
    )
    .test(
      "not-contains-name-or-email",
      "Password must not contain your name or email",
      (value) =>
        !["yassoh"].some((substring) => value.toLowerCase().includes(substring))
    ),
  checkbox1: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
  checkbox2: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
  profilePic: yup.mixed().required("Image is required"),
});

export const initialValuesLogin = {
  email: "",
  password: "",
  checkbox: false,
};

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  checkbox: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(72, "Password must be at most 72 characters")
});

export const becomeSellerSchema = yup.object().shape({
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  parish: yup.string().required("Parish is required"),
  role: yup.string().required("Role is required"),
  services: yup.array().when("role", {
    is: (val) => val === "SELLER",
    then: (schema) =>
      yup.array().required("Select any other services you do is required"),
    otherwise: (schema) => yup.array(),
  }),
  service_areas: yup.array().when("role", {
    is: (val) => val === "SELLER",
    then: (schema) =>
      yup.array().required("Select Service Areas from parishes is required"),
    otherwise: (schema) => yup.array(),
  }),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
 
  checkbox1: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
  checkbox2: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
});

export const initialValuesBecomeSeller = (value)=> {
  return {
  firstName: value?.firstName || "",
  lastName:value?.lastName || "",
  parish: value?.parish || "",
  role: value?.role || "",
  services: [],
  service_areas: [],
  email:value?.email || "",
  checkbox1: false,
  checkbox2: false,
  }
};

export const initialValueProfile  = (value)=> {
  return {
  username: value?.username || "",
  firstName: value?.firstName || "",
  role: value?.role || "",
  lastName: value?.lastName || "",
  parish:value?.parish ||  "",
  services: value?.services?.map((option) => ({
    value: option?.id,
    label: option?.name,
  })) || [],
  service_areas: value?.service_areas?.map(e=>e?.area_name) ||  [],
  email:value?.email ||  "",
  // profilePic: "",
};
}

export const ProfileSchema = yup.object().shape({
  username: yup.string().required("user name is required"),
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  parish: yup.string().required("Parish is required"),
  services: yup.array().when("role", {
    is: (val) => val === "SELLER",
    then: (schema) =>
      yup.array().required("Select any other services you do is required"),
    otherwise: (schema) => yup.array(),
  }),
  service_areas: yup.array().when("role", {
    is: (val) => val === "SELLER",
    then: (schema) =>
      yup.array().required("Select Service Areas from parishes is required"),
    otherwise: (schema) => yup.array(),
  }),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),

  // profilePic: yup.mixed().required("Image is required"),
});

export const ForgotSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const initialValuesForgot = {
  email: "",
};

export const initialValuesReset = {
  email: "",
  password: "",
  token: "",
};

export const resetSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  token: yup.string().required("Reset Toekn is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(72, "Password must be at most 72 characters")
});