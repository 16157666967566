/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { useMutation } from "@apollo/client";
import { USRR_FORGOT } from "../../Graphql/Mutation/Auth/AuthMutation";
import { initialValuesForgot, ForgotSchema } from "./schema";
import { useFormik } from "formik";
import toast from "react-hot-toast";

const ForgotSection = () => {

  const navigate = useNavigate();
  const [UserForgot, { loading: loginLoading }] =
    useMutation(USRR_FORGOT);


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValuesForgot,
      validationSchema: ForgotSchema,
      onSubmit: async(values) => {
        console.log(values);
        const {
          email,
        } = values;
  
        try {
          const loginResponse = await UserForgot({
            variables: {
              email,
            },
          });
  
          console.log(loginResponse, "loginResponse");
          if (loginResponse && loginResponse.data) {
            if(loginResponse.data){
              toast.success('Token send successfully please Check your email')
            navigate("/reset");
          }
        }
    } catch (error) {
          console.log(error, "error");
        }
      },
    });

  return (
    <>
      <div className="body_content mt40" style={{ background: "#FFEDE8" }}>
        <section className="our-login">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="main-title text-center">
                  <h2 className="title">Forgot Password</h2>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div className="col-xl-6 mx-auto">
                  <div className="log-reg-form search-modal form-style1 bgc-white p40 p30-sm default-box-shadow1 bdrs12">
                    <div className="mb30">
                      <p className="text">
                      Already have an account? {/* */}
                        <Link className="text-thm" to={"/login"}>
                          Log In.
                        </Link>
                      </p>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.email && errors.email}
                      </span>
                    </div>
                 
                    <div className="d-grid mb10">
                      <button className="ud-btn btn-thm" type="submit">
                      {loginLoading ? (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
};

export default ForgotSection;
