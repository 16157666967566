import React from 'react'
import Layout from '../../../Layout/Layout'
import ServiceSection2 from '../../../Components/BrowserJobs/Service/ServiceSection2'

const ServiceSingle = () => {
  return (
    <>
   <Layout>
    <ServiceSection2 />
   </Layout>
    </>
  )
}

export default ServiceSingle

