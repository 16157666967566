/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const JobViewSection2 = () => {
  return (
    <>
<div className="body_content">
  <section className="categories_list_section overflow-hidden" style={{marginTop:'8%'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="listings_category_nav_list_menu">
            <ul className="mb0 d-flex ps-0">
              <li>
                <a className="active">All Categories</a>
              </li>
              <li>
                <a className="">Graphics Design</a>
              </li>
              <li>
                <a className="">Digital Marketing</a>
              </li>
              <li>
                <a className="">Writing Translation</a>
              </li>
              <li>
                <a className="">Video Animation</a>
              </li>
              <li>
                <a className="">Music Audio</a>
              </li>
              <li>
                <a className="">Programming Tech</a>
              </li>
              <li>
                <a className="">Business</a>
              </li>
              <li>
                <a className="">Lifestyle</a>
              </li>
              <li>
                <a className="">Trending</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8 col-lg-10">
          <div className="breadcumb-style1 mb10-xs">
            <div className="breadcumb-list">
              <a>Home</a>
              <a>Services</a>
              <a>Design &amp; Creative</a>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-2">
          <div className="d-flex align-items-center justify-content-sm-end">
            <a className="position-relative ui-share-toggle">
              <div className="share-save-widget d-flex align-items-center">
                <span className="icon flaticon-share dark-color fz12 mr10" />
                <div className="h6 mb-0">Share</div>
              </div>
            </a>
            <a>
              <div className="share-save-widget d-flex align-items-center ml15">
                <span className="icon flaticon-like dark-color fz12 mr10" />
                <div className="h6 mb-0">Save</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section pt-0">
    <div className="cta-job-v1 freelancer-single-style mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
      <img
        alt="left-top"
        loading="lazy"
        width={198}
        height={226}
        decoding="async"
        data-nimg={1}
        className="left-top-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75"
      />
      <img
        alt="right-bottom"
        loading="lazy"
        width={255}
        height={181}
        decoding="async"
        data-nimg={1}
        className="right-bottom-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75"
      />
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-xl-8 mx-auto">
            <div className="position-relative">
              <div className="list-meta d-lg-flex align-items-end justify-content-between">
                <div className="wrapper d-sm-flex align-items-center mb20-md">
                  <a className="position-relative freelancer-single-style">
                    <img
                      alt="job-single"
                      loading="lazy"
                      width={100}
                      height={100}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fjob-single.png&w=128&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fjob-single.png&w=128&q=75"
                    />
                  </a>
                  <div className="ml20 ml0-xs mt15-sm">
                    <h4 className="title">UX/UI Designer</h4>
                    <h6 className="mb-3 text-thm">Medium</h6>
                    <h6 className="list-inline-item mb-0">
                      $125k-$135k Hourly
                    </h6>
                    <h6 className="list-inline-item mb-0 bdrl-eunry pl15">
                      1-5 Days
                    </h6>
                    <h6 className="list-inline-item mb-0 bdrl-eunry pl15">
                      Expensive
                    </h6>
                    <h6 className="list-inline-item mb-0 bdrl-eunry pl15">
                      Remote
                    </h6>
                  </div>
                </div>
                <a className="ud-btn btn-thm2" href="/">
                  Apply For Job
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="pt10 pb90 pb30-md">
    <div className="container">
      <div className="row wow fadeInUp">
        <div className="col-lg-8 mx-auto">
          <div className="row">
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-calendar" />
                </div>
                <div className="details">
                  <h5 className="title">Date Posted</h5>
                  <p className="mb-0 text">Posted 1 days ago</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-place" />
                </div>
                <div className="details">
                  <h5 className="title">Location</h5>
                  <p className="mb-0 text">London</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-fifteen" />
                </div>
                <div className="details">
                  <h5 className="title">Hours</h5>
                  <p className="mb-0 text">50h / week</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                <div className="icon flex-shrink-0">
                  <span className="flaticon-pay-day" />
                </div>
                <div className="details">
                  <h5 className="title">Salary</h5>
                  <p className="mb-0 text">$35 - $45K</p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-about">
            <h4 className="mb-4">Description</h4>
            <p className="text mb30">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.{/* */}
            </p>
            <p className="text mb60">
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy. Various
              versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <h4 className="mb30">Key Responsibilities</h4>
            <div className="list-style1 mb60 pr50 pr0-lg">
              <ul>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Be involved in every step of the product design cycle from
                  discovery to developer handoff and user acceptance testing.
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Work with BAs, product managers and tech teams to lead the
                  Product Design
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Maintain quality of the design process and ensure that when
                  designs are translated into code they accurately reflect the
                  design specifications.
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Accurately estimate design tickets during planning sessions.
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Contribute to sketching sessions involving
                  non-designersCreate, iterate and maintain UI deliverables
                  including sketch files, style guides, high fidelity
                  prototypes, micro interaction specifications and pattern
                  libraries.
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Ensure design choices are data led by identifying assumptions
                  to test each sprint, and work with the analysts in your team
                  to plan moderated usability test sessions.
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Design pixel perfect responsive UI’s and understand that
                  adopting common interface patterns is better for UX than
                  reinventing the wheel
                </li>
                <li>
                  <i className="far fa-check text-thm3 bgc-thm3-light" />
                  Present your work to the wider business at Show &amp; Tell
                  sessions.
                </li>
              </ul>
            </div>
            <h4 className="mb30">Work &amp; Experience</h4>
            <ul className="list-style-type-bullet ps-3 mb60">
              <li>
                You have at least 3 years’ experience working as a Product
                Designer.
              </li>
              <li>You have experience using Sketch and InVision or Framer X</li>
              <li>
                You have some previous experience working in an agile
                environment – Think two-week sprints.
              </li>
              <li>
                You are familiar using Jira and Confluence in your workflow
              </li>
            </ul>
            <div className="d-grid mb60">
              <a className="ud-btn btn-thm2" href="/">
                Apply For Job
                <i className="fal fa-arrow-right-long" />
              </a>
            </div>
            <div className="main-title mb30">
              <h2>Related Jobs</h2>
              <p className="text">2022 jobs live - 293 added today</p>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xl-12">
                <div className="job-list-style1 bdr1 d-xl-flex align-items-start">
                  <div className="icon d-flex align-items-center mb20">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75"
                    />
                    <span className="fav-icon flaticon-star" />
                  </div>
                  <div className="details ml20 ml0-xl">
                    <h5>Website Designer Required For Directory Theme</h5>
                    <h6 className="mb-3 text-thm">Mailchimp</h6>
                    <p className="list-inline-item mb-0">$125k-$135k Hourly</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">1-5 Days</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">
                      Expensive
                    </p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">Remote</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-12">
                <div className="job-list-style1 bdr1 d-xl-flex align-items-start">
                  <div className="icon d-flex align-items-center mb20">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
                    />
                    <span className="fav-icon flaticon-star" />
                  </div>
                  <div className="details ml20 ml0-xl">
                    <h5>Website Designer Required For Directory Theme</h5>
                    <h6 className="mb-3 text-thm">Mailchimp</h6>
                    <p className="list-inline-item mb-0">$125k-$135k Hourly</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">1-5 Days</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">
                      Expensive
                    </p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">Remote</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-12">
                <div className="job-list-style1 bdr1 d-xl-flex align-items-start">
                  <div className="icon d-flex align-items-center mb20">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75"
                    />
                    <span className="fav-icon flaticon-star" />
                  </div>
                  <div className="details ml20 ml0-xl">
                    <h5>Website Designer Required For Directory Theme</h5>
                    <h6 className="mb-3 text-thm">Mailchimp</h6>
                    <p className="list-inline-item mb-0">$125k-$135k Hourly</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">1-5 Days</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">
                      Expensive
                    </p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">Remote</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default JobViewSection2