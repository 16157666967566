import React from 'react'
import JobViewSection2 from '../../../Components/BrowserJobs/JobView/JobViewSection2'
import Layout from '../../../Layout/Layout'

const JobViewSingle = () => {
  return (
    <>
    <Layout>
     <JobViewSection2 />
    </Layout>
    </>
  )
}

export default JobViewSingle

