/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";

const CardFourFreelancer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const [card, setCard] = useState([]);
  const allJobsCombined = [...card];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const AllCardData = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/products");
      setCard(response?.data?.products);
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllCardData();
  }, []);
  return (
    <>
      <div className="row">
        {currentJobs?.map((job) => (
          <div className="col-md-6 col-lg-4 col-xl-3">
            <div className="freelancer-style1 text-center bdr1 hover-box-shadow" style={{height:'auto'}}>
              <div className="thumb w90 mb25 mx-auto position-relative rounded-circle">
                <img
                  alt="thumb"
                  loading="lazy"
                  width={90}
                  height={90}
                  decoding="async"
                  data-nimg={1}
                  className="rounded-circle mx-auto"
                  style={{ color: "transparent" }}
                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75
                "
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75"
                />
                <span className="online" />
              </div>
              <div className="details">
                <h5 className="title mb-1">{job?.title}</h5>
                <p className="mb-0">Nursing Assistant</p>
                <div className="review">
                  <p>
                    <i className="fas fa-star fz10 review-color pr10" />
                    <span className="dark-color fw500">4.9</span>({/* */}595
                    {/* */}
                    reviews)
                  </p>
                </div>
                <div className="skill-tags d-flex align-items-center justify-content-center mb5">
                  <span className="tag">Figma</span>
                  <span className="tag mx10">Sketch</span>
                  <span className="tag">HTML5</span>
                </div>
                <hr className="opacity-100 mt20 mb15" />
                <div className="fl-meta d-flex align-items-center justify-content-between">
                  <a className="meta fw500 text-start">
                    Location
                    <br />
                    <span className="fz14 fw400">London</span>
                  </a>
                  <a className="meta fw500 text-start">
                    Rate
                    <br />
                    <span className="fz14 fw400">$90 / hr</span>
                  </a>
                  <a className="meta fw500 text-start">
                    Job Success
                    <br />
                    <span className="fz14 fw400">%98</span>
                  </a>
                </div>
                <div className="d-grid mt15">
                  <a
                    className="ud-btn btn-light-thm"
                    href="/"
                  >
                    View Profile
                    <i className="fal fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CardFourFreelancer;
