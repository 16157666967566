/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@apollo/client";
import React from "react";
import { Link,useNavigate } from "react-router-dom";
import { GET_USER } from "../../../Graphql/Query/AuthQuery";

const Sidebar = () => {
  const navigate = useNavigate();
  // const token = localStorage.getItem("authToken");
  const { data } = useQuery(GET_USER);
  // dashboard dashboard_wrapper pr30 pr0-xl dsh_board_sidebar_hidden
  const handlerToogle = () => {
    document
      .getElementById("sidebar")
      .classList.toggle("dsh_board_sidebar_hidden");
  };
  const handlerClick = () => {
    document.getElementById("mobile").classList.toggle("show");
  };
  const handlerSidebar = () => {
    document.getElementById("offcanvasExample").classList.toggle("show");
  };

  return (
    <>
      <div className="wrapper mm-page mm-slideout">
        <div className="mobilie_header_nav stylehome1">
          <div className="mobile-menu">
            <div className="header bdrb1">
              <div className="menu_and_widgets">
                <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                  <Link className="mobile_logo" to="/">
                    <img
                      alt="Header Logo"
                      loading="lazy"
                      width={133}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      style={{ color: "transparent" }}
                      src="/images/header.png"
                    />
                  </Link>
                  <div className="right-side text-end">
                    <Link to={"/register"}>join</Link>
                    <a
                      className="menubar ml30"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                      onClick={handlerSidebar}
                    >
                      <img
                        alt="icon"
                        loading="lazy"
                        width={20}
                        height={20}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent" }}
                        src="/images/mobile-dark-nav-icon.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="posr">
                <div className="mobile_menu_close_btn">
                  <span className="far fa-times" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="header-nav nav-innerpage-style menu-home4 dashboard_header main-menu">
          <nav className="posr">
            <div className="container-fluid pr30 pr15-xs pl30 posr menu_bdrt1">
              <div className="row align-items-center justify-content-between">
                <div className="col-6 col-lg-auto">
                  <div className="text-center text-lg-start d-flex align-items-center">
                    <div className="dashboard_header_logo position-relative me-2 me-xl-5 mt-0">
                      <Link className="logo" to="/">
                        <img
                          alt="logo"
                          loading="lazy"
                          width={133}
                          height={60}
                          decoding="async"
                          data-nimg={1}
                          style={{ color: "transparent" }}
                          src="/images/header.png"
                        />
                      </Link>
                    </div>
                    <div className="fz20 ml90">
                      <a className="dashboard_sidebar_toggle_icon vam">
                        <img
                          alt="navicon"
                          loading="lazy"
                          width={20}
                          height={18}
                          decoding="async"
                          data-nimg={1}
                          style={{ color: "transparent" }}
                          onClick={handlerToogle}
                          src="/images/dashboard-navicon.svg"
                        />
                      </a>
                    </div>
                    <a
                      className="login-info d-block d-xl-none ml40 vam"
                      data-bs-toggle="modal"
                      href="#exampleModalToggle"
                    >
                      <span className="flaticon-loupe" />
                    </a>
                    <div className="ml40 d-none d-xl-block">
                      <div className="search_area dashboard-style">
                        <input
                          type="text"
                          className="form-control border-0"
                          placeholder="What service are you looking for today?"
                        />
                        <label>
                          <span className="flaticon-loupe" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-auto">
                  <div className="text-center text-lg-end header_right_widgets">
                    <ul className="dashboard_dd_menu_list d-flex align-items-center justify-content-center justify-content-sm-end mb-0 p-0">
                      <li className="d-none d-sm-block">
                        <a
                          className="text-center mr5 text-thm2 dropdown-toggle fz20"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          <span className="flaticon-notification" />
                        </a>
                        <div className="dropdown-menu">
                          <div className="dboard_notific_dd px30 pt10 pb15">
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-1.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-1.png&w=96&q=75 2x
                        "
                                src="_next/notif-12a24.png?url=%2Fimages%2Fresource%2Fnotif-1.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">Your resume</p>
                                <p className="text mb-0">updated!</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-2.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-2.png&w=96&q=75 2x
                        "
                                src="_next/notif-28373.png?url=%2Fimages%2Fresource%2Fnotif-2.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">You changed</p>
                                <p className="text mb-0">password</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-3.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-3.png&w=96&q=75 2x
                        "
                                src="_next/notif-3df8c.png?url=%2Fimages%2Fresource%2Fnotif-3.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">
                                  Your account has been
                                </p>
                                <p className="text mb-0">
                                  created successfully
                                </p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-4.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-4.png&w=96&q=75 2x
                        "
                                src="_next/notif-41938.png?url=%2Fimages%2Fresource%2Fnotif-4.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">
                                  You applied for a job{/* */}
                                </p>
                                <p className="text mb-0">Front-end Developer</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-5.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-5.png&w=96&q=75 2x
                        "
                                src="_next/notif-5bb24.png?url=%2Fimages%2Fresource%2Fnotif-5.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">
                                  Your course uploaded
                                </p>
                                <p className="text mb-0">successfully</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="d-none d-sm-block">
                        <a
                          className="text-center mr5 text-thm2 dropdown-toggle fz20"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          <span className="flaticon-mail" />
                        </a>
                        <div className="dropdown-menu">
                          <div className="dboard_notific_dd px30 pt20 pb15">
                            <div className="notif_list d-flex align-items-start bdrb1 pb25 mb10">
                              <img
                                alt="testimonials"
                                loading="lazy"
                                width={50}
                                height={50}
                                decoding="async"
                                data-nimg={1}
                                className="img-2"
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=64&q=75  1x,
                          /_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=128&q=75 2x
                        "
                                src="_next/testi-1a01f.png?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=128&q=75"
                              />
                              <div className="details ml15">
                                <p className="dark-color fw500 mb-2">
                                  Ali Tufan
                                </p>
                                <p className="text mb-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing.
                                </p>
                                <p className="mb-0 text-thm">4 hours ago</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-start mb25">
                              <img
                                alt="testimonials"
                                loading="lazy"
                                width={50}
                                height={50}
                                decoding="async"
                                data-nimg={1}
                                className="img-2"
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-2.png&w=64&q=75  1x,
                          /_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-2.png&w=128&q=75 2x
                        "
                                src="_next/testi-21607.png?url=%2Fimages%2Ftestimonials%2Ftesti-2.png&w=128&q=75"
                              />
                              <div className="details ml15">
                                <p className="dark-color fw500 mb-2">
                                  Ali Tufan
                                </p>
                                <p className="text mb-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing.
                                </p>
                                <p className="mb-0 text-thm">4 hours ago</p>
                              </div>
                            </div>
                            <div className="d-grid">
                              <a className="ud-btn btn-thm w-100" href="#">
                                View All Messages
                                <i className="fal fa-arrow-right-long" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="d-none d-sm-block">
                        <a
                          className="text-center mr5 text-thm2 dropdown-toggle fz20"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          <span className="flaticon-like" />
                        </a>
                        <div className="dropdown-menu">
                          <div className="dboard_notific_dd px30 pt10 pb15">
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-1.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-1.png&w=96&q=75 2x
                        "
                                src="_next/notif-12a24.png?url=%2Fimages%2Fresource%2Fnotif-1.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">Your resume</p>
                                <p className="text mb-0">updated!</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-2.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-2.png&w=96&q=75 2x
                        "
                                src="_next/notif-28373.png?url=%2Fimages%2Fresource%2Fnotif-2.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">You changed</p>
                                <p className="text mb-0">password</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-3.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-3.png&w=96&q=75 2x
                        "
                                src="_next/notif-3df8c.png?url=%2Fimages%2Fresource%2Fnotif-3.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">
                                  Your account has been
                                </p>
                                <p className="text mb-0">
                                  created successfully
                                </p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-4.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-4.png&w=96&q=75 2x
                        "
                                src="_next/notif-41938.png?url=%2Fimages%2Fresource%2Fnotif-4.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">
                                  You applied for a job{/* */}
                                </p>
                                <p className="text mb-0">Front-end Developer</p>
                              </div>
                            </div>
                            <div className="notif_list d-flex align-items-center">
                              <img
                                alt="notif"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                srcSet="
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-5.png&w=48&q=75 1x,
                          /_next/image?url=%2Fimages%2Fresource%2Fnotif-5.png&w=96&q=75 2x
                        "
                                src="_next/notif-5bb24.png?url=%2Fimages%2Fresource%2Fnotif-5.png&w=96&q=75"
                              />
                              <div className="details ml10">
                                <p className="text mb-0">
                                  Your course uploaded
                                </p>
                                <p className="text mb-0">successfully</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="dashboard_content_wrapper">
          <div className="dashboard dashboard_wrapper pr30 pr0-xl" id="sidebar">
            <div className="dashboard__sidebar d-none d-lg-block">
              <div className="dashboard_sidebar_list">
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center -is-active" to={"/dashboard"}>
                    <i className="flaticon-home mr15" />
                    Dashboard
                  </Link>
                </div>
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/message"}>
                    <i className="flaticon-chat mr15" />
                    Message
                  </Link>
                </div>
               {/* <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/propsals"}>
                    <i className="flaticon-document mr15" />
                    My Proposals
                  </Link>
                </div>
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/save"}>
                    <i className="flaticon-like mr15" />
                    Saved
                  </Link>
                </div>
                 <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/reviews"}>
                    <i className="flaticon-review-1 mr15" />
                    Reviews
                  </Link>
                </div>
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/invoice"}>
                    <i className="flaticon-receipt mr15" />
                    Invoice
                  </Link>
                </div>*/}
                 {data?.currentUser?.role === 'SELLER' &&
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/payouts"}>
                    <i className="flaticon-dollar mr15" />
                    Payouts
                  </Link>
                </div>}
                {/*<div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/statement"}>
                    <i className="flaticon-web mr15" />
                    Statements
                  </Link>
                </div> 
             <div className="sidebar_list_item mb-1">
                  <Link
                    className="items-center"
                    to={"/dashboard/manageproject"}
                  >
                    <i className="flaticon-content mr15" />
                    Manage Project
                  </Link>
                </div> */}
              
                {/* <div className="sidebar_list_item mb-1">
                  <Link
                    className="items-center"
                    to={"/dashboard/createproject"}
                  >
                    <i className="flaticon-content mr15" />
                    Create Project
                  </Link>
                </div> */}
                
                {data?.currentUser?.role === 'SELLER' &&
                <> 
                   <p className="fz15 fw400 ff-heading pl30 mt30">
                  Organize and Manage
                </p>
                 <div className="sidebar_list_item mb-1">
                <Link
                  className="items-center"
                  to={"/dashboard/manageservice"}
                >
                  <i className="flaticon-presentation mr15" />
                  Manage Services
                </Link>
              </div>
              <div className="sidebar_list_item mb-1">
                <Link className="items-center" to={"/dashboard/addservices"}>
                  <i className="flaticon-document mr15" />
                  Add Services
                </Link>
              </div>
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/managejobs"}>
                    <i className="flaticon-briefcase mr15" />
                    Buyer Order
                  </Link>
                </div> 
                </>}

                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/manageorder"}>
                    <i className="flaticon-briefcase mr15" />
                    My Order
                  </Link>
                </div>
                
                <p className="fz15 fw400 ff-heading pl30 mt30">Account</p>
                <div className="sidebar_list_item mb-1">
                  <Link className="items-center" to={"/dashboard/myprofile"}>
                    <i className="flaticon-photo mr15" />
                    My Profile
                  </Link>
                </div>
                <div className="sidebar_list_item mb-1"   onClick={() => {
                          localStorage.clear();
                          navigate("/login");
                        }}>
                  <Link className="items-center" to=''>
                    <i className="flaticon-logout mr15" />
                    Logout
                  </Link>
                </div>
              </div>
            </div>
            <div className="dashboard__main pl0-md">
              <div className="dashboard__content">
                <div className="row pb40">
                  <div className="col-lg-12">
                    <div className="dashboard_navigationbar d-block d-lg-none">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <Link to={"/dashboard"}>
                            <img
                              alt="icon"
                              loading="lazy"
                              width={20}
                              height={20}
                              decoding="async"
                              data-nimg={1}
                              style={{ color: "transparent" }}
                              src="/images/mobile-dark-nav-icon.svg"
                              onClick={handlerClick}
                              className="mx-2"
                            />
                          </Link>
                          Dashboard Navigation
                        </button>
                        <ul className="dropdown-content" id="mobile">
                          <li className="mobile-dasboard-menu-active">
                            <Link to={"/dashboard"}>
                              <i className="flaticon-home mr10" />
                              Dashboard
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/propsals"}>
                              <i className="flaticon-document mr10" />
                              My Proposals
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/save"}>
                              <i className="flaticon-like mr10" />
                              Saved
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/message"}>
                              <i className="flaticon-chat mr10" />
                              Message
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/reviews"}>
                              <i className="flaticon-review-1 mr10" />
                              Reviews
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/invoice"}>
                              <i className="flaticon-receipt mr10" />
                              Invoice
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/payouts"}>
                              <i className="flaticon-dollar mr10" />
                              Payouts
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/statement"}>
                              <i className="flaticon-web mr10" />
                              Statements
                            </Link>
                          </li>
                          <li>
                            <p className="fz15 fw400 ff-heading mt30 pl30">
                              Organize and Manage
                            </p>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/manageservice"}>
                              <i className="flaticon-presentation mr10" />
                              Manage Services
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/managejobs"}>
                              <i className="flaticon-briefcase mr10" />
                              Manage Jobs
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/manageproject"}>
                              <i className="flaticon-content mr10" />
                              Manage Project
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/addservices"}>
                              <i className="flaticon-document mr10" />
                              Add Services
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/createproject"}>
                              <i className="flaticon-content mr10" />
                              Create Project
                            </Link>
                          </li>
                          <li>
                            <p className="fz15 fw400 ff-heading mt30 pl30">
                              Account
                            </p>
                          </li>
                          <li className="">
                            <Link to={"/dashboard/myprofile"}>
                              <i className="flaticon-photo mr10" />
                              My Profile
                            </Link>
                          </li>
                          <li className="">
                            <Link to={"/"}>
                              <i className="flaticon-logout mr10" />
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-start"
          tabIndex={-1}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header border-bottom">
            <a href="/">
              <img
                alt="Header Logo"
                loading="lazy"
                decoding="async"
                data-nimg={1}
                style={{ color: "transparent", height: "88px", width: "150px" }}
                src="/images/header.png"
              />
            </a>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handlerSidebar}
            />
          </div>
          <div className="offcanvas-body">
            <div className="ui-navigation-sidebar">
              <aside
                data-testid="ps-sidebar-root-test-id"
                width="250px"
                className="ps-sidebar-root css-1wvake5"
              >
                <div
                  data-testid="ps-sidebar-container-test-id"
                  className="ps-sidebar-container css-dip3t8"
                >
                  <nav className="ps-menu-root css-vj11vy">
                    <ul className="css-ewdv3l">
                      <style
                        data-emotion="css 16jesut"
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n                    .css-16jesut {\n                      position: relative;\n                      width: 100%;\n                    }\n                    .css-16jesut > .ps-menu-button {\n                      display: -webkit-box;\n                      display: -webkit-flex;\n                      display: -ms-flexbox;\n                      display: flex;\n                      -webkit-align-items: center;\n                      -webkit-box-align: center;\n                      -ms-flex-align: center;\n                      align-items: center;\n                      height: 50px;\n                      -webkit-text-decoration: none;\n                      text-decoration: none;\n                      color: inherit;\n                      box-sizing: border-box;\n                      cursor: pointer;\n                      padding-right: 20px;\n                      padding-left: 20px;\n                    }\n                    .css-16jesut > .ps-menu-button:hover {\n                      background-color: #f3f3f3;\n                    }\n                  ",
                        }}
                      />
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <Link
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                          to={"/"}
                        >
                          <span className="ps-menu-label css-12w9als">
                            Home
                          </span>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </Link>
                      </li>
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <a
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                        >
                          <Link to={"/jobview"}>
                            <span className="ps-menu-label css-12w9als">
                              Browse Jobs
                            </span>
                          </Link>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </a>
                      </li>
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <Link
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                          to={"/service"}
                        >
                          <span className="ps-menu-label css-12w9als">
                            Servives
                          </span>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </Link>
                        <div
                          data-testid="ps-submenu-content-test-id"
                          className="ps-submenu-content css-z5rm24"
                        ></div>
                      </li>
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <Link
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                          to={"/about"}
                        >
                          <span className="ps-menu-label css-12w9als">
                            About
                          </span>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </Link>
                      </li>
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <Link
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                          to={"/contact"}
                        >
                          <span className="ps-menu-label css-12w9als">
                            Contact
                          </span>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </Link>
                      </li>
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <Link
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                          to={"/login"}
                        >
                          <span className="ps-menu-label css-12w9als">
                            Sign in
                          </span>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </Link>
                      </li>
                      <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                        <Link
                          className="ps-menu-button"
                          data-testid="ps-menu-button-test-id"
                          tabIndex={0}
                          to={"/becomeseller"}
                        >
                          <span className="ps-menu-label css-12w9als">
                            Become a Seller
                          </span>
                          <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
