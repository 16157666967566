/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@apollo/client";
import { GET_Wallet } from "../../Graphql/Query/Wallet";

const CardSevenPayouts = () => {

  const { data } = useQuery(GET_Wallet);

  // const [currentPage, setCurrentPage] = useState(1);
  // const jobsPerPage = 8;
  // const allJobsCombined  = data ? data?.sellerWallet || {};
  // const indexOfLastJob = currentPage * jobsPerPage;
  // const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  // const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const AllCardData = async () => {
  //   try {
  //     const response = await axios.get("https://dummyjson.com/products");
  //     setCard(response?.data?.products);
  //     console.log(response?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   AllCardData();
  // }, []);
  return (
    <>
      <table className="table-style3 table at-savesearch">
        <thead className="t-head">
          <tr>
            <th scope="col">Availble Amount</th>
            <th scope="col">Pending Amount</th>
            <th scope="col">Total Credit</th>
            <th scope="col">Total Debit</th>
          </tr>
        </thead>
        <tbody className="t-body">
       
            <tr>
              <th scope="row">${/* */} {`${data?.sellerWallet?.availble_amount}`}</th>
              <td className="vam">${/* */} {`${data?.sellerWallet?.availble_amount}`}</td>
              <td className="vam">${/* */} {`${data?.sellerWallet?.total_credit}`}</td>
              <td className="vam">${/* */} {`${data?.sellerWallet?.total_credit}`}</td>
            </tr>
        </tbody>
      </table>
      {/* <div className="mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default CardSevenPayouts;
