import React from 'react'
import Layout from '../../../Layout/Layout'
import TermsSection1 from '../../../Components/Pages/Terms/TermsSection1'

const Terms = () => {
  return (
    <>
      <Layout>
        <TermsSection1 />
      </Layout>
    </> 
  )
}

export default Terms



