import React from 'react'
import ResetSection from '../../Components/Auth/ResetSection';
import Layout from '../../Layout/Layout'

const Reset = () => {
  return (
    <>
    <Layout>
      <ResetSection />
    </Layout>
    </>
  )
}

export default Reset