import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import MyProfileSection1 from "../../Components/Dashboard/MyProfile/MyProfileSection1";

const MyProfile = () => {
  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <MyProfileSection1 />
        </div>
      </Layout>
    </>
  );
};

export default MyProfile;
