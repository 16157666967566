import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
mutation CreateOrder($sellerId: Int!, $gigId: Int!, $orderDate: String!, $total: Float!) {
  createOrder(sellerId: $sellerId, gigId: $gigId, orderDate: $orderDate, total: $total) {
    url
    id
    cancel_url
    success_url
    }
  }`
export const ORDER_STATUS = gql`
mutation Mutation($orderId: Int!, $status: String!) {
  orderStatusChange(orderId: $orderId, status: $status) {
    id
  }
}`
export const DELETE_ORDER = gql`
mutation DeleteGig($gigId: Int!) {
  deleteGig(gigID: $gigId)
}`

export const PAYMENT_SUCCESS = gql`
mutation PaymentSuccess($checkoutId: String!, $orderId: Int!) {
  paymentSuccess(checkoutId: $checkoutId, orderId: $orderId) {
    id
  }
}`

export const PAYMENT_CANCEL = gql`
mutation PaymentCancel($orderId: Int!) {
  paymentCancel(orderId: $orderId)
}`

export const USER_ACCOUNT = gql`
mutation UserConnectedAccount {
  userConnectedAccount {
    url
  }
}`

export const STRIPE_ACCOUNT_STATUS = gql`
mutation StripeAccountStatusCheck {
  stripeAccountStatusCheck
}`

