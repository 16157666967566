import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      token
      user {
        id
        role
        email
        username
        firstName
        lastName
        parish
        services {
id,name
        }
        service_areas {
id,area_name
        }
        profilePic
      }
    
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation registerUser(
    $username: String!
    $firstName: String!
    $lastName: String!
    $role: String!
    $profilePic: String!
    $email: String!
    $password: String!
    $parish: String!
    $services: [Int!]
    $service_areas: [String!]
  ) {
    registerUser(
      username: $username
      firstName: $firstName
      lastName: $lastName
      role: $role
      profilePic: $profilePic
      email: $email
      parish: $parish
      password: $password
      services: $services
      service_areas: $service_areas
    ) {
      id
    }
  }
`;

export const BECOME_SELLER_MUTATION = gql`
  mutation becomeSeller(
    $userId: Int!
    $firstName: String!
    $lastName: String!
    $role: String!
    $parish: String!
    $services: [Int!]
    $service_areas: [String!]
  ) {
    becomeSeller(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      role: $role
      parish: $parish
      services: $services
      service_areas: $service_areas
    ) {
      id
    }
  }
`;
export const UPDATE_PROFILE_MUTATION = gql`
mutation UserProfileUpdate($userId: Int!, $username: String!, $email: String!, $parish: String!, $firstName: String, $lastName: String, $profilePic: String, $services: [Int!], $serviceAreas: [String!], $role: String!) {
  userProfileUpdate(userId: $userId, username: $username,  email: $email, parish: $parish, firstName: $firstName, lastName: $lastName, profilePic: $profilePic, services: $services, service_areas: $serviceAreas, role: $role) {
    id
  }
}
`;

export const USRR_FORGOT = gql`
mutation UserForgot($email: String!) {
  userForgot(email: $email) {
    id
  }
}
`;
export const RESET_PASSWORD = gql`
mutation UserReset($email: String!, $resetToken: String!, $newPassword: String!) {
  userReset(email: $email, resetToken: $resetToken, newPassword: $newPassword) {
    id
  }
}
`;