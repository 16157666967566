import React from 'react'
import Layout from '../../../Layout/Layout'
import HelpSection1 from '../../../Components/Pages/Help/HelpSection1'

const Help = () => {
  return (
    <>
      <Layout>
        <HelpSection1 />
      </Layout>
    </> 
  )
}

export default Help