/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React  from "react";
import CardTwoProject from "../../AllCards/CardTwoProject";

const ProjectSection1 = () => {

  return (
    <>
      <div className="body_content">
        <section
          className="categories_list_section overflow-hidden"
          style={{ marginTop: "8%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="listings_category_nav_list_menu">
                  <ul className="mb0 d-flex ps-0">
                    <li>
                      <a className="active">All Categories</a>
                    </li>
                    <li>
                      <a className="">Graphics Design</a>
                    </li>
                    <li>
                      <a className="">Digital Marketing</a>
                    </li>
                    <li>
                      <a className="">Writing Translation</a>
                    </li>
                    <li>
                      <a className="">Video Animation</a>
                    </li>
                    <li>
                      <a className="">Music Audio</a>
                    </li>
                    <li>
                      <a className="">Programming Tech</a>
                    </li>
                    <li>
                      <a className="">Business</a>
                    </li>
                    <li>
                      <a className="">Lifestyle</a>
                    </li>
                    <li>
                      <a className="">Trending</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="breadcumb-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <div className="breadcumb-list">
                    <a>Home</a>
                    <a>Services</a>
                    <a>Design &amp; Creative</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="breadcumb-section pt-0">
          <div className="cta-service-v1 cta-banner mx-auto maxw1700 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
            <img
              alt="left-top"
              loading="lazy"
              width={198}
              height={226}
              decoding="async"
              data-nimg={1}
              className="left-top-img wow zoomIn"
              style={{ color: "transparent" }}
              srcSet="
          /_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75 1x,
          /_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=640&q=75 2x
        "
              src="_next/left-top9e21.png?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=640&q=75"
            />
            <img
              alt="right-bottom"
              loading="lazy"
              width={255}
              height={181}
              decoding="async"
              data-nimg={1}
              className="right-bottom-img wow zoomIn"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75
        "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75"
            />
            <img
              alt="vector-service"
              loading="lazy"
              width={532}
              height={300}
              decoding="async"
              data-nimg={1}
              className="service-v1-vector bounce-y d-none d-xl-block"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75
        "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75"
            />
            <div className="container">
              <div className="row wow fadeInUp">
                <div className="col-xl-7">
                  <div className="position-relative">
                    <h2>Projects List</h2>
                    <p className="text mb30">
                      All the Lorem Ipsum generators on the Internet tend to
                      repeat.
                    </p>
                  </div>
                  <div className="advance-search-tab bgc-white p10 bdrs4 zi1 position-relative">
                    <div className="row">
                      <div className="col-md-8 col-xl-9">
                        <div className="advance-search-field">
                          <form className="form-search position-relative">
                            <div className="box-search bb1-sm">
                              <span className="icon far fa-magnifying-glass" />
                              <input
                                className="form-control"
                                type="text"
                                placeholder="What are you looking for?"
                                name="search"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-4 col-xl-3">
                        <div className="text-center text-xl-start">
                          <button
                            className="ud-btn btn-thm w-100"
                            type="button"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt30 pb90">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-sidebar-style1 d-none d-lg-block">
                  <div className="accordion" id="accordionExample">
                    <div className="card mb20 pb10 mt-0">
                      <div className="card-header" id="heading0">
                        <h4>
                          <button
                            className="btn btn-link ps-0 pt-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse0"
                            aria-expanded="false"
                            aria-controls="collapse0"
                          >
                            Category
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse0"
                        className="collapse show"
                        aria-labelledby="heading0"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-0">
                          <div className="checkbox-style1 mb15">
                            <label className="custom_checkbox">
                              UX Designer
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}1,945{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Web Developers
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}8,136{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Illustrators
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}917{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Node.js
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}240{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Project Managers
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}2,460{/* */})
                              </span>
                            </label>
                          </div>
                          <a className="text-thm">+20 more</a>
                        </div>
                      </div>
                    </div>
                    <div className="card mb20 pb10">
                      <div className="card-header" id="heading01">
                        <h4>
                          <button
                            className="btn btn-link ps-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse01"
                            aria-expanded="false"
                            aria-controls="collapse01"
                          >
                            Project type
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse01"
                        className="collapse show"
                        aria-labelledby="heading01"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-0">
                          <div className="switch-style1">
                            <div className="form-check form-switch mb20">
                              <input
                                className="form-check-input mt-0"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                              />
                              <label
                                className="form-check-label mt-0"
                                htmlFor="flexSwitchCheckDefault"
                              >
                                Fixed
                              </label>
                              <span className="right-tags">
                                ({/* */}1,945{/* */})
                              </span>
                            </div>
                          </div>
                          <div className="switch-style1">
                            <div className="form-check form-switch mb20">
                              <input
                                className="form-check-input mt-0"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                              />
                              <label
                                className="form-check-label mt-0"
                                htmlFor="flexSwitchCheckDefault"
                              >
                                Hourly
                              </label>
                              <span className="right-tags">
                                ({/* */}8,136{/* */})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb20 pb0">
                      <div className="card-header" id="heading1">
                        <h4>
                          <button
                            className="btn btn-link ps-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse1"
                            aria-expanded="false"
                            aria-controls="collapse1"
                          >
                            Price
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse1"
                        className="collapse show"
                        aria-labelledby="heading1"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-4">
                          <div className="card-body card-body px-0 pt-0">
                            <div className="range-slider-style2">
                              <div className="range-wrapper">
                                <div className="price__range__box">
                                  <div
                                    style={{ position: "relative" }}
                                    className="horizontal-slider"
                                  >
                                    <div
                                      className="example-track example-track-0"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-track example-track-1"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-track example-track-2"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-thumb example-thumb-0"
                                      style={{
                                        position: "absolute",
                                        touchAction: "none",
                                        zIndex: 1,
                                        left: 0,
                                      }}
                                      tabIndex={0}
                                      role="slider"
                                      aria-orientation="horizontal"
                                      aria-valuenow={0}
                                      aria-valuemin={0}
                                      aria-valuemax={100000}
                                      aria-disabled="false"
                                    />
                                    <div
                                      className="example-thumb example-thumb-1"
                                      style={{
                                        position: "absolute",
                                        touchAction: "none",
                                        zIndex: 2,
                                        left: 0,
                                      }}
                                      tabIndex={0}
                                      role="slider"
                                      aria-orientation="horizontal"
                                      aria-valuenow={100000}
                                      aria-valuemin={0}
                                      aria-valuemax={100000}
                                      aria-disabled="false"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex gap-1 align-items-center pt-4">
                                  <input
                                    type="number"
                                    className="amount w-100"
                                    placeholder="$20"
                                    min={0}
                                    defaultValue={0}
                                  />
                                  <span className="fa-sharp fa-solid fa-minus mx-1 dark-color" />
                                  <input
                                    type="number"
                                    className="amount2 w-100"
                                    placeholder="$100000"
                                    min={0}
                                    max={100000}
                                    defaultValue={100000}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb20 pb10">
                      <div className="card-header" id="heading0">
                        <h4>
                          <button
                            className="btn btn-link ps-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse02"
                            aria-expanded="false"
                            aria-controls="collapse02"
                          >
                            Skills
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse02"
                        className="collapse show"
                        aria-labelledby="heading0"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-0">
                          <div className="card-body card-body px-0 pt-0">
                            <div className="checkbox-style1 mb15">
                              <label className="custom_checkbox">
                                Adobe Photoshop
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}1,945{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Figma
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}8,15{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Sketch
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}654{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Adobe XD
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}323{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Balsamiq
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}2,455{/* */})
                                </span>
                              </label>
                            </div>
                            <a className="text-thm" href="#">
                              +20 more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb20 pb5">
                      <div className="card-header" id="heading2">
                        <h4>
                          <button
                            className="btn btn-link ps-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse2"
                            aria-expanded="false"
                            aria-controls="collapse2"
                          >
                            Location
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse2"
                        className="collapse show"
                        aria-labelledby="heading2"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-0">
                          <div className="card-body card-body px-0 pt-0">
                            <div className="default-box-shadow1 mb30">
                              <div className="search_area">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  defaultValue=""
                                />
                                <label>
                                  <span className="flaticon-loupe" />
                                </label>
                              </div>
                            </div>
                            <div className="checkbox-style1 mb15">
                              <label className="custom_checkbox">
                                United States
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}1,945{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                United Kingdom
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}8,136{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Canada
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}917{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Germany
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}240{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Turkey
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}2,460{/* */})
                                </span>
                              </label>
                            </div>
                            <a className="text-thm">+20 more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb20 pb5">
                      <div className="card-header" id="heading3">
                        <h4>
                          <button
                            className="btn btn-link ps-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse3"
                            aria-expanded="false"
                            aria-controls="collapse3"
                          >
                            Languange
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse3"
                        className="collapse show"
                        aria-labelledby="heading3"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-0">
                          <div className="card-body card-body px-0 pt-0">
                            <div className="checkbox-style1 mb15">
                              <label className="custom_checkbox">
                                Turkish
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}1,945{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                English
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}8,15{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Italian
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}654{/* */})
                                </span>
                              </label>
                              <label className="custom_checkbox">
                                Spanish
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="right-tags">
                                  ({/* */}323{/* */})
                                </span>
                              </label>
                            </div>
                            <a className="text-thm">+20 more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb20 pb5">
                      <div className="card-header" id="heading4">
                        <h4>
                          <button
                            className="btn btn-link ps-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            English Level
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse4"
                        className="collapse show"
                        aria-labelledby="heading4"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body card-body px-0 pt-0">
                          <div className="checkbox-style1">
                            <label className="custom_checkbox">
                              Basic level
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}1,945{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Bilingual
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}8,136{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Fluent
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}917{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Native
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}240{/* */})
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row align-items-center mb20">
                  <div className="col-md-6">
                    <div className="text-center text-md-start">
                      <p className="text mb-0 mb10-sm">
                        <span className="fw500">8</span> services available
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page_control_shorting d-md-flex align-items-center justify-content-center justify-content-md-end">
                      <div className="dropdown-lists d-block d-lg-none me-2 mb10-sm">
                        <ul className="p-0 mb-0 text-center text-md-start">
                          <li>
                            <button
                              type="button"
                              className="open-btn filter-btn-left"
                            >
                              <img
                                alt="icon"
                                loading="lazy"
                                width={18}
                                height={18}
                                decoding="async"
                                data-nimg={1}
                                className="me-2"
                                style={{ color: "transparent" }}
                                src="images/icon/all-filter-icon.svg"
                              />
                              All Filter
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="pcs_dropdown dark-color pr10 pr0-xs text-center">
                        <div className="dropdown bootstrap-select show-tick">
                          <div className="dropdown-menu">
                            <div className="inner show">
                              <ul className="dropdown-menu inner show">
                                <li>
                                  <a className="dropdown-item active selected">
                                    <span className="bs-ok-default check-mark" />
                                    <span className="text">Best Seller</span>
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item">
                                    <span className="bs-ok-default check-mark" />
                                    <span className="text">Recommended</span>
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item">
                                    <span className="bs-ok-default check-mark" />
                                    <span className="text">New Arrivals</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CardTwoProject />
              </div>
            </div>
          </div>
        </section>
        <div className="lefttside-hidden-bar">
          <div className="hsidebar-header bdrb1">
            <h4 className="list-title">All filters</h4>
            <div className="sidebar-close-icon">
              <span className="far fa-times" />
            </div>
          </div>
          <div className="hsidebar-content">
            <div className="widget-wrapper">
              <div className="sidebar-accordion">
                <div className="accordion" id="accordionExample2">
                  <div className="card mb20 pb10">
                    <div className="card-header" id="headingZero">
                      <h4>
                        <button
                          className="btn btn-link ps-0 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseZero"
                          aria-expanded="false"
                          aria-controls="collapseZero"
                        >
                          Category
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseZero"
                      className="collapse show"
                      aria-labelledby="headingZero"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="checkbox-style1 mb15">
                          <label className="custom_checkbox">
                            UX Designer
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Web Developers
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}8,136{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Illustrators
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}917{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Node.js
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}240{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Project Managers
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}2,460{/* */})
                            </span>
                          </label>
                        </div>
                        <a className="text-thm">+20 more</a>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb10">
                    <div className="card-header" id="heading011">
                      <h4>
                        <button
                          className="btn btn-link ps-0 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse011"
                          aria-expanded="false"
                          aria-controls="collapse011"
                        >
                          Project type
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapse011"
                      className="collapse show"
                      aria-labelledby="heading011"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="switch-style1">
                          <div className="form-check form-switch mb20">
                            <input
                              className="form-check-input mt-0"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                            <label
                              className="form-check-label mt-0"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              Fixed
                            </label>
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </div>
                        </div>
                        <div className="switch-style1">
                          <div className="form-check form-switch mb20">
                            <input
                              className="form-check-input mt-0"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                            <label
                              className="form-check-label mt-0"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              Hourly
                            </label>
                            <span className="right-tags">
                              ({/* */}8,136{/* */})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb0">
                    <div className="card-header" id="headingOnes">
                      <h4>
                        <button
                          className="btn btn-link ps-0 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnes"
                          aria-expanded="false"
                          aria-controls="collapseOnes"
                        >
                          Price
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseOnes"
                      className="collapse show"
                      aria-labelledby="headingOnes"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="range-slider-style2">
                          <div className="card-body card-body px-0 pt-0">
                            <div className="range-slider-style2">
                              <div className="range-wrapper">
                                <div className="price__range__box">
                                  <div
                                    style={{ position: "relative" }}
                                    className="horizontal-slider"
                                  >
                                    <div
                                      className="example-track example-track-0"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-track example-track-1"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-track example-track-2"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-thumb example-thumb-0"
                                      style={{
                                        position: "absolute",
                                        touchAction: "none",
                                        zIndex: 1,
                                        left: 0,
                                      }}
                                      tabIndex={0}
                                      role="slider"
                                      aria-orientation="horizontal"
                                      aria-valuenow={0}
                                      aria-valuemin={0}
                                      aria-valuemax={100000}
                                      aria-disabled="false"
                                    />
                                    <div
                                      className="example-thumb example-thumb-1"
                                      style={{
                                        position: "absolute",
                                        touchAction: "none",
                                        zIndex: 2,
                                        left: 0,
                                      }}
                                      tabIndex={0}
                                      role="slider"
                                      aria-orientation="horizontal"
                                      aria-valuenow={100000}
                                      aria-valuemin={0}
                                      aria-valuemax={100000}
                                      aria-disabled="false"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center pt-3">
                                  <span id="slider-range-value1">
                                    ${/* */}0
                                  </span>
                                  <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon" />
                                  <span id="slider-range-value2">
                                    ${/* */}100000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb10">
                    <div className="card-header" id="headingZero2">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseZero2"
                          aria-expanded="true"
                          aria-controls="collapseZero2"
                        >
                          Skills
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseZero2"
                      className="collapse show"
                      aria-labelledby="headingZero2"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="card-body card-body px-0 pt-0">
                          <div className="checkbox-style1 mb15">
                            <label className="custom_checkbox">
                              Adobe Photoshop
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}1,945{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Figma
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}8,15{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Sketch
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}654{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Adobe XD
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}323{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Balsamiq
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}2,455{/* */})
                              </span>
                            </label>
                          </div>
                          <a className="text-thm" href="#">
                            +20 more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb5">
                    <div className="card-header" id="headingTwos">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwos"
                          aria-expanded="true"
                          aria-controls="collapseTwos"
                        >
                          Location
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseTwos"
                      className="collapse show"
                      aria-labelledby="headingTwos"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="card-body card-body px-0 pt-0">
                          <div className="default-box-shadow1 mb30">
                            <div className="search_area">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                defaultValue=""
                              />
                              <label>
                                <span className="flaticon-loupe" />
                              </label>
                            </div>
                          </div>
                          <div className="checkbox-style1 mb15">
                            <label className="custom_checkbox">
                              United States
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}1,945{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              United Kingdom
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}8,136{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Canada
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}917{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Germany
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}240{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Turkey
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}2,460{/* */})
                              </span>
                            </label>
                          </div>
                          <a className="text-thm">+20 more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb5">
                    <div className="card-header" id="headingThrees">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThrees"
                          aria-expanded="true"
                          aria-controls="collapseThrees"
                        >
                          Languange
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseThrees"
                      className="collapse show"
                      aria-labelledby="headingThrees"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="card-body card-body px-0 pt-0">
                          <div className="checkbox-style1 mb15">
                            <label className="custom_checkbox">
                              Turkish
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}1,945{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              English
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}8,15{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Italian
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}654{/* */})
                              </span>
                            </label>
                            <label className="custom_checkbox">
                              Spanish
                              <input type="checkbox" />
                              <span className="checkmark" />
                              <span className="right-tags">
                                ({/* */}323{/* */})
                              </span>
                            </label>
                          </div>
                          <a className="text-thm">+20 more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb5">
                    <div className="card-header" id="headingFours">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFours"
                          aria-expanded="true"
                          aria-controls="collapseFours"
                        >
                          English Level
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseFours"
                      className="collapse show"
                      aria-labelledby="headingFours"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="checkbox-style1">
                          <label className="custom_checkbox">
                            Basic level
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Bilingual
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}8,136{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Fluent
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}917{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Native
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}240{/* */})
                            </span>
                          </label>
                        </div>
                        <a className="text-thm">+20 more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenbar-body-ovelay" />
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
};

export default ProjectSection1;
