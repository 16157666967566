/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const CreateProjectSection1 = () => {
  return (
    <>
      <>
        <div className="row">
          <div className="col-lg-9">
            <div className="dashboard_title_area">
              <h2>Creat Project</h2>
              <p className="text">Lorem ipsum dolor sit amet, consectetur.</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="text-lg-end">
              <a className="ud-btn btn-dark">
                Save &amp; Publish
                <i className="fal fa-arrow-right-long" />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb25">
                <h5 className="list-title">Basic Information</h5>
              </div>
              <div className="col-xl-8">
                <form className="form-style1">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          Project Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="i will"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Category
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">Select</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Graphics &amp; Design
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Digital Marketing
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Writing &amp; Translation
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Video &amp; Animation
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Music &amp; Audio
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Programming &amp; Tech
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Business</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Lifestyle</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Trending</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Freelancer Type
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">Select</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Full Time</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Part Time</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Project Based
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Other</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Price type
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">Select</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Low($)</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Mid($$)</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">High($$$)</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          Cost
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="$"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Project Duration
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">Select</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">1 Day</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">2 Day</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">3 Day</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">1 Week</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Level
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">Select</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Entry Level
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Standard Level
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Expert Level
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Country
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      United States
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">
                                          United States
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Canada</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          United Kingdom
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Australia</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Germany</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Japan</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            City
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      New York
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item active selected">
                                        <span className="text">New York</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Toronto</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">London</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Sydney</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Berlin</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Tokyo</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Language
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">English</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">French</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">German</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Japanese</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Languages Level
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Select
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Beginner</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">
                                          Intermediate
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Advanced</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Fluent</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10">
                            Skills
                          </label>
                          <div className="bootselect-multiselect">
                            <div className="dropdown bootstrap-select">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-light"
                                data-bs-toggle="dropdown"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Nothing selected
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  style={{ maxHeight: 300, overflowX: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Select</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Figma</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Adobe XD</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">CSS</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">HTML</span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a className="dropdown-item">
                                        <span className="text">Bootstrap</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb10">
                        <label className="heading-color ff-heading fw500 mb10">
                          Project Detail
                        </label>
                        <textarea
                          cols={30}
                          rows={6}
                          placeholder="Description"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="text-start">
                        <a className="ud-btn btn-thm" href="/">
                          Save
                          <i className="fal fa-arrow-right-long" />
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs12 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb25">
                <h5 className="list-title">Upload Attachments</h5>
              </div>
              <div className="row">
                <div className="col-6 col-xl-3">
                  <label>
                    <a className="upload-img">
                      Upload Files
                      <input
                        type="file"
                        accept="application/pdf"
                        className="d-none"
                        multiple=""
                      />
                    </a>
                  </label>
                </div>
              </div>
              <p className="text">Maximum file size: 10 MB</p>
              <div className="text-start">
                <a className="ud-btn btn-thm" href="/">
                  Save &amp; Publish
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default CreateProjectSection1;
