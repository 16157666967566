/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import toast from "react-hot-toast";
import { ProfileSchema,initialValueProfile } from "../../Auth/schema";
import { UPDATE_PROFILE_MUTATION } from "../../../Graphql/Mutation/Auth/AuthMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER } from "../../../Graphql/Query/AuthQuery";
import { GET_SERVICES } from "../../../Graphql/Query/Service";
import { ColorRing } from "react-loader-spinner";


const MyProfileSection1 = () => {

  const [userProfileUpdate, { loading: profileUploadLoading }] =
  useMutation(UPDATE_PROFILE_MUTATION);
  const { data:userData,refetch } = useQuery(GET_USER);
  const { data } = useQuery(GET_SERVICES);
  const defaultValues = useMemo(() => initialValueProfile(userData?.currentUser),[]);
  const services = data?.services || [];

  const countries =[
    "Kingston",
    "Montego Bay",
    "Spanish Town",
    "Portmore",
    "May Pen",
    "Mandeville",
    "Old Harbour",
    "Linstead",
    "Half Way Tree",
    "Savanna-la-Mar",
    "Port Antonio",
    "Ocho Rios",
    "Morant Bay",
    "St. Ann's Bay",
    "Hayes"
  ];
    
    const {
      values,
      setFieldValue,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
    } = useFormik({
      enableReinitialize: true,
      initialValues: defaultValues,
      validationSchema: ProfileSchema,
      onSubmit: async (values) => {
        const {
          username,
          firstName,
          lastName,
          email,
          // profilePic,
          service_areas,
          services,
          parish,
          role,
        } = values;
        try {
          // const formData = new FormData();
          // formData.append("media", profilePic);
          // const mediaResponse = await axios.post(
          //   `http://139.99.237.187:4444/upload-media`,
          //   formData
          // );
          // let profilePicUrl = "";
          // console.log(mediaResponse, "mediaResponse");
          // if (mediaResponse?.data?.status === true) {
          //   profilePicUrl = mediaResponse.data?.data[0].name;
          // }
  
          const profileResponse = await userProfileUpdate({
            variables: {
              userId:userData?.currentUser?.id,
              username,
              firstName,
              lastName,
              email,
              // profilePic: profilePicUrl,
              serviceAreas:service_areas,
              services: services.map((e) => e?.value),
              parish,
              role
            },
          });  
          console.log(profileResponse, "profileResponse");
          if (profileResponse && profileResponse.data) {
            toast.success("User Profile Updated SuccessFully");
            refetch()
          } else {
            console.error("Profile Updated failed");
          }
        } catch (error) {
          console.log(error,"---------error");
        }
      },
    }
  );
  return (
    <>
      <div className="__className_88fdc4">
      
        <div className="col-lg-9">
          <div className="dashboard_title_area">
            <h2>My Profile</h2>
            {/* <p className="text">Lorem ipsum dolor sit amet, consectetur.</p> */}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xl-12">
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb25">
                <h5 className="list-title">Profile Details</h5>
              </div>
              <div className="col-xl-12">
                <div className="profile-box d-sm-flex align-items-center mb30">
                  <div className="profile-img mb20-sm">
                    <img
                      alt="profile"
                      loading="lazy"
                      width={71}
                      height={71}
                      decoding="async"
                      data-nimg={1}
                      className="rounded-circle wa-xs"
                      style={{
                        color: "transparent",
                        height: 71,
                        width: 71,
                        objectFit: "cover",
                      }}
                      src={`${"https://yassohapi.devssh.xyz"}${
                        userData?.currentUser?.profilePic
                      }`}
                      />
                  </div>
                  <div className="profile-content ml20 ml0-xs">
                    <div className="d-flex align-items-center my-3">
                      <a className="tag-delt text-thm2">
                        <span className="flaticon-delete text-thm2" />
                      </a>
                      <label>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          className="d-none"
                        />
                        <a className="upload-btn ml10">Upload Images</a>
                      </label>
                    </div>
                    <p className="text mb-0">
                      Max file size is 1MB, Minimum dimension: 330x300 And
                      Suitable files are .jpg &amp; .png
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your user name" 
                          onChange={handleChange}
                          name="username"
                          value={values.username}
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.username && errors.username}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          placeholder="Enter your first name" 
                          onChange={handleChange}
                          value={values.firstName}
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.firstName && errors.firstName}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          placeholder="Enter your last name" 
                          onChange={handleChange}
                          value={values.lastName}
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.lastName && errors.lastName}
                        </span>
                      </div>
                    </div>
                    {userData?.currentUser?.role === 'SELLER' && <> <div className="col-sm-6">
                    <div className="mb20">
                      <label className="form-label fw500 dark-color">
                        Select any other services you do.
                      </label>

                      <Select
                        isMulti
                        name="services"
                        options={services?.map((e) => ({
                          value: e?.id,
                          label: e?.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "services",
                            selectedOptions?.map((option) => ({
                              value: option?.value,
                              label: option?.label,
                            }))
                          )
                        }
                        onBlur={handleBlur("services")}
                        value={values?.services?.map((service) => ({
                          value: service?.value,
                          label: service?.label,
                        }))}
                      />
                      <span className="error">
                        {touched.services && errors.services}
                      </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="mb20">
                      <label className="form-label fw500 dark-color">
                        Select Service Areas from parishes
                      </label>

                      <Select
                        isMulti
                        name="service_areas"
                        options={countries?.map((e) => ({
                          value: e,
                          label: e,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "service_areas",
                            selectedOptions?.map((option) => option.value)
                          )
                        }
                        onBlur={handleBlur("service_areas")}
                        value={values?.service_areas?.map((service) => ({
                          value: service,
                          label: service,
                        }))}
                      />
                      <span className="error">
                        {touched.service_areas && errors.service_areas}
                      </span>
                      </div>
                    </div></>}
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10">
                          Parish
                        </label>
                        <input
                          type="parish"
                          className="form-control"
                          placeholder="Enter your parish"
                          name="parish"
                          value={values.parish}
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.parish && errors.parish}
                        </span>
                      </div>
                    </div>      
                    <div className="col-md-12">
                      <div className="text-start">
                      <button
                        className="ud-btn btn-thm default-box-shadow2"
                        type="submit"
                      >
                         {profileUploadLoading ? (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        ) : (
                          "Update Profile"
                        )}
                          <i className="fal fa-arrow-right-long" />
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            {/* <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb25">
                <h5 className="list-title">Skills</h5>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <form className="form-style1">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb20">
                          <div className="form-style1">
                            <label className="heading-color ff-heading fw500 mb10">
                              Skills 1
                            </label>
                            <div className="bootselect-multiselect">
                              <div className="dropdown bootstrap-select">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle btn-light"
                                  data-bs-toggle="dropdown"
                                  onClick={handlerMale9}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        Designer
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="dropdown-menu male9">
                                  <div
                                    className="inner show"
                                    style={{
                                      maxHeight: 300,
                                      overflowX: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">Designer</span>
                                        </a>
                                      </li>
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">UI/UX</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb20">
                          <div className="form-style1">
                            <label className="heading-color ff-heading fw500 mb10">
                              Point
                            </label>
                            <div className="bootselect-multiselect">
                              <div className="dropdown bootstrap-select">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle btn-light"
                                  data-bs-toggle="dropdown"
                                  onClick={handlerMale10}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        80
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="dropdown-menu male10">
                                  <div
                                    className="inner show"
                                    style={{
                                      maxHeight: 300,
                                      overflowX: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">80</span>
                                        </a>
                                      </li>
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">90</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb20">
                          <div className="form-style1">
                            <label className="heading-color ff-heading fw500 mb10">
                              Skills 2
                            </label>
                            <div className="bootselect-multiselect">
                              <div className="dropdown bootstrap-select">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle btn-light"
                                  data-bs-toggle="dropdown"
                                  onClick={handlerMale11}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        Developer
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="dropdown-menu male11">
                                  <div
                                    className="inner show"
                                    style={{
                                      maxHeight: 300,
                                      overflowX: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">
                                            Developer
                                          </span>
                                        </a>
                                      </li>
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">
                                            Programmer
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb20">
                          <div className="form-style1">
                            <label className="heading-color ff-heading fw500 mb10">
                              Point
                            </label>
                            <div className="bootselect-multiselect">
                              <div className="dropdown bootstrap-select">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle btn-light"
                                  data-bs-toggle="dropdown"
                                  onClick={handlerMale12}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        70
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="dropdown-menu male12">
                                  <div
                                    className="inner show"
                                    style={{
                                      maxHeight: 300,
                                      overflowX: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">70</span>
                                        </a>
                                      </li>
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">80</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb20">
                          <div className="form-style1">
                            <label className="heading-color ff-heading fw500 mb10">
                              Skills 3
                            </label>
                            <div className="bootselect-multiselect">
                              <div className="dropdown bootstrap-select">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle btn-light"
                                  data-bs-toggle="dropdown"
                                  onClick={handlerMale13}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        Video Editor
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="dropdown-menu male13">
                                  <div
                                    className="inner show"
                                    style={{
                                      maxHeight: 300,
                                      overflowX: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">
                                            Video Editor
                                          </span>
                                        </a>
                                      </li>
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">
                                            Programmer
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb20">
                          <div className="form-style1">
                            <label className="heading-color ff-heading fw500 mb10">
                              Point
                            </label>
                            <div className="bootselect-multiselect">
                              <div className="dropdown bootstrap-select">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle btn-light"
                                  data-bs-toggle="dropdown"
                                  onClick={handlerMale14}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        75
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="dropdown-menu male14">
                                  <div
                                    className="inner show"
                                    style={{
                                      maxHeight: 300,
                                      overflowX: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">75</span>
                                        </a>
                                      </li>
                                      <li className="selected active">
                                        <a className="dropdown-item">
                                          <span className="text">80</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="text-start">
                          <a className="ud-btn btn-thm" href="#">
                            Save
                            <i className="fal fa-arrow-right-long" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb30 d-sm-flex justify-content-between">
                <h5 className="list-title">Education</h5>
                <a className="add-more-btn text-thm">
                  <i className="icon far fa-plus mr10" />
                  Add Aducation
                </a>
              </div>
              <div className="position-relative">
                <div className="educational-quality">
                  <div className="m-circle text-thm">M</div>
                  <div className="wrapper mb40 position-relative">
                    <div className="del-edit">
                      <div className="d-flex">
                        <a className="icon me-2" id="edit">
                          <span className="flaticon-pencil" />
                        </a>
                        <a className="icon" id="delete">
                          <span className="flaticon-delete" />
                        </a>
                      </div>
                    </div>
                    <span className="tag">2012 - 2014</span>
                    <h5 className="mt15">Bachlors in Fine Arts</h5>
                    <h6 className="text-thm">Modern College</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin a ipsum tellus. Interdum et malesuada fames ac ante
                      ipsum
                      <br className="d-none d-lg-block" />
                      primis in faucibus.
                    </p>
                  </div>
                  <div className="m-circle before-none text-thm">M</div>
                  <div className="wrapper mb30 position-relative">
                    <div className="del-edit">
                      <div className="d-flex">
                        <a className="icon me-2" id="edit">
                          <span className="flaticon-pencil" />
                        </a>
                        <a className="icon" id="delete">
                          <span className="flaticon-delete" />
                        </a>
                      </div>
                    </div>
                    <span className="tag">2008 - 2012</span>
                    <h5 className="mt15">Computer Science</h5>
                    <h6 className="text-thm">Harvartd University</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin a ipsum tellus. Interdum et malesuada fames ac ante
                      ipsum
                      <br className="d-none d-lg-block" />
                      primis in faucibus.
                    </p>
                  </div>
                </div>
                <div className="text-start">
                  <a className="ud-btn btn-thm">
                    Save
                    <i className="fal fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb30 d-sm-flex justify-content-between">
                <h5 className="list-title">Work &amp; Experience</h5>
                <a className="add-more-btn text-thm">
                  <i className="icon far fa-plus mr10" />
                  Add Experience
                </a>
              </div>
              <div className="position-relative">
                <div className="educational-quality">
                  <div className="m-circle text-thm">M</div>
                  <div className="wrapper mb40 position-relative">
                    <div className="del-edit">
                      <div className="d-flex">
                        <a className="icon me-2" id="edit">
                          <span className="flaticon-pencil" />
                        </a>
                        <a className="icon" id="delete">
                          <span className="flaticon-delete" />
                        </a>
                      </div>
                    </div>
                    <span className="tag">2012 - 2014</span>
                    <h5 className="mt15">UX Designer</h5>
                    <h6 className="text-thm">Dropbox</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin a ipsum tellus. Interdum et malesuada fames ac ante
                      ipsum
                      <br className="d-none d-lg-block" />
                      primis in faucibus.
                    </p>
                  </div>
                  <div className="m-circle before-none text-thm">M</div>
                  <div className="wrapper mb30 position-relative">
                    <div className="del-edit">
                      <div className="d-flex">
                        <a className="icon me-2" id="edit">
                          <span className="flaticon-pencil" />
                        </a>
                        <a className="icon" id="delete">
                          <span className="flaticon-delete" />
                        </a>
                      </div>
                    </div>
                    <span className="tag">2008 - 2012</span>
                    <h5 className="mt15">Art Director</h5>
                    <h6 className="text-thm">amazon</h6>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin a ipsum tellus. Interdum et malesuada fames ac ante
                      ipsum
                      <br className="d-none d-lg-block" />
                      primis in faucibus.
                    </p>
                  </div>
                </div>
                <div className="text-start">
                  <a className="ud-btn btn-thm">
                    Save
                    <i className="fal fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb30 d-sm-flex justify-content-between">
                <h5 className="list-title">Awards</h5>
                <a className="add-more-btn text-thm">
                  <i className="icon far fa-plus mr10" />
                  Add Awards
                </a>
              </div>
              <div className="position-relative">
                <div className="educational-quality ps-0">
                  <div className="wrapper mb40 position-relative">
                    <div className="del-edit">
                      <div className="d-flex">
                        <a className="icon me-2" id="edit">
                          <span className="flaticon-pencil" />
                        </a>
                        <a className="icon" id="delete">
                          <span className="flaticon-delete" />
                        </a>
                      </div>
                    </div>
                    <span className="tag">2012 - 2014</span>
                    <h5 className="mt15">UI UX Design</h5>
                    <h6 className="text-thm">Udemy</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin a ipsum tellus. Interdum et malesuada fames ac ante
                      ipsum
                      <br className="d-none d-lg-block" />
                      primis in faucibus.
                    </p>
                  </div>
                  <div className="wrapper mb40 position-relative">
                    <div className="del-edit">
                      <div className="d-flex">
                        <a className="icon me-2" id="edit">
                          <span className="flaticon-pencil" />
                        </a>
                        <a className="icon" id="delete">
                          <span className="flaticon-delete" />
                        </a>
                      </div>
                    </div>
                    <span className="tag">2008 - 2012</span>
                    <h5 className="mt15">App Design</h5>
                    <h6 className="text-thm">Google</h6>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin a ipsum tellus. Interdum et malesuada fames ac ante
                      ipsum
                      <br className="d-none d-lg-block" />
                      primis in faucibus.
                    </p>
                  </div>
                </div>
                <div className="text-start">
                  <a className="ud-btn btn-thm" href="#">
                    Save
                    <i className="fal fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="bdrb1 pb15 mb25">
                <h5 className="list-title">Change password</h5>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <form className="form-style1">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb20">
                          <label className="heading-color ff-heading fw500 mb10">
                            Old Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="********"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb20">
                          <label className="heading-color ff-heading fw500 mb10">
                            New Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="********"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb20">
                          <label className="heading-color ff-heading fw500 mb10">
                            Confirm New Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="********"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="text-start">
                          <a className="ud-btn btn-thm" href="#">
                            Change Password
                            <i className="fal fa-arrow-right-long" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="col-lg-7">
                <div className="row">
                  <div className="bdrb1 pb15 mb25">
                    <h5 className="list-title">Change password</h5>
                  </div>
                  <form className="form-style1">
                    <div className="row">
                      <div className="col-sm-12">
                        <h6>Close account</h6>
                        <p className="text">
                          Warning: If you close your account, you will be
                          unsubscribed from all your 5 courses, and will lose
                          access forever.
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb20">
                          <label className="heading-color ff-heading fw500 mb10">
                            Enter Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="********"
                          />
                        </div>
                        <div className="text-start">
                          <a className="ud-btn btn-thm" href="#">
                            Change Password
                            <i className="fal fa-arrow-right-long" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        </form>
      </div>
    </>
  );
};

export default MyProfileSection1;



