import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
query UserOrders {
  userOrders {
    appFee
    id
    buyerId
    sellerId
    buyer {
      username
      profilePic
    }
    seller {
      username
      profilePic
    }
    gig {
      title
      price
    }
    orderDate
    status
  }
}
`
