/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { SEND_MESSAGE } from "../../../Graphql/Mutation/Auth/Chat/Chat";
import { GET_ALL_CHAT, GET_CHAT_BY_USER_ID } from "../../../Graphql/Query/Chat";
import { GIG_BY_SELLER_ID } from "../../../Graphql/Query/Gig";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER } from "../../../Graphql/Query/AuthQuery";
import { MESSAGE_SUBSCRIPTION } from "../../../Graphql/Mutation/Auth/Chat/Chat";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CREATE_ORDER,
  PAYMENT_SUCCESS,
  PAYMENT_CANCEL,
} from "../../../Graphql/Mutation/Auth/order/order";
import 'react-loading-skeleton/dist/skeleton.css'
import ChatUserCard from "../../Skeleton/ChatUserCard";

const MessageSection1 = ({ chatUserId }) => {
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  // const {id} = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const orderId = searchParams.get("orderId");
  console.log(sessionId, "===========", orderId);

  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setloading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [text, setText] = useState("");
  const [chatId, setChatId] = useState(null);
  const [sellerID, setSellerID] = useState(null);

  // const { data: gigData } = useQuery(GET_ONE_GIG);
  const { data: gigData } = useQuery(GIG_BY_SELLER_ID, {
    variables: { sellerId: +sellerID },
  });
  const { data: currentUser } = useQuery(GET_USER);
  const { data: chatsData, refetch,loading:chatLoading } = useQuery(GET_ALL_CHAT);
  const [createOrder, { loading: orderLoading }] = useMutation(CREATE_ORDER);
  const [PaymentSuccess] = useMutation(PAYMENT_SUCCESS);
  const [PaymentCancel] = useMutation(PAYMENT_CANCEL);

  const {
    data: getMessagesByChatID,
    refetch: refetchMessageById,
    subscribeToMore,
  } = useQuery(GET_CHAT_BY_USER_ID, {
    variables: { chatId: +chatId },
  });

  const [sendMessage, { loading: sendLoading }] = useMutation(SEND_MESSAGE);
  const [messages, setMessages] = useState([]);

  const GetMessages = (id) => {
    setChatId(id);
    refetchMessageById(true);
  };

  const OnSubmit = async () => {
    try {
      const send = await sendMessage({
        variables: {
          chatId: +chatId,
          text: text,
        },
      });
      if (send?.data?.sendMessage?.id) {
        setText("");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    refetch();
    if (sessionId && orderId) {
      handleSucsess();
    } else if (orderId) {
      handleCancel();
    }
  }, []);

  useEffect(() => {
    if (getMessagesByChatID) {
      setMessages(getMessagesByChatID?.getMessagesByChatID || []);
    }
  }, [getMessagesByChatID]);

  useEffect(() => {
    if (messagesEndRef.current) {
      const chatContainer = messagesEndRef.current;
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  useEffect(() => {
    const abc = subscribeToMore({
      document: MESSAGE_SUBSCRIPTION,
      variables: {
        chatId: chatId ? +chatId : 0,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const newFeedItem = subscriptionData.data.messageCreated;
        if (!newFeedItem) {
          return prev;
        }
        return Object.assign({}, prev, {
          getMessagesByChatID: [newFeedItem, ...prev?.getMessagesByChatID],
        });
      },
      onError: (error) => console.log("error", error),
    });

    return abc;
  }, [subscribeToMore, chatId]);

  const formatTime = (isoTime) => {
    const date = new Date(isoTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const calculateFeePrice = (gigPrice) => {
    return gigPrice * 0.1;
  };

  const handleGigChange = (e) => {
    const selectedGig = gigData?.gigsBySellerId?.find(
      (gig) => gig.id == e.target.value
    );
    if (selectedGig) {
      formik.setFieldValue("gig", e.target.value || "");
      formik.setFieldValue("gigPrice", selectedGig.price || "");

      const feePrice = calculateFeePrice(selectedGig.price).toFixed(2);
      formik.setFieldValue("feesPrice", feePrice);

      const totalPrice = (selectedGig.price + parseFloat(feePrice)).toFixed(2);
      formik.setFieldValue("totalPrice", totalPrice);
      console.log(formik.values, "------------------>>>");
    }
  };


  const handleSucsess = async () => {
    try {
      const orderResponse = await PaymentSuccess({
        variables: {
          checkoutId: sessionId,
          orderId: +orderId,
        },
      });
      console.log(orderResponse, "orderResponse==============>>>");
      if (orderResponse && orderResponse.data) {
        toast.success("Order Created SuccessFully");
        navigate("/dashboard/message");
      } else {
        console.error("Order failed");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  async function handleCancel(id) {
    PaymentCancel({ variables: { orderId: +orderId } })
      .then((result) => {
        console.log(result, "result----->>>");
        if (result && result.data) {
          toast.error("Order Cancel SuccessFully");
        }
      })
      .catch((errors) => {
        console.error(errors);
      });
  }

  const formik = useFormik({
    initialValues: {
      gig: "",
      orderDate: "",
      gigPrice: "",
      feesPrice: "",
      totalPrice: "",
    },
    validationSchema: Yup.object({
      gig: Yup.string().required("Gig is required"),
      orderDate: Yup.date().required("Order date is required"),
      gigPrice: Yup.number().required("Gig Price is required"),
      // feesPrice: Yup.date().required(),
      // totalPrice: Yup.date().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { gig, orderDate, totalPrice } = values;
      try {
        const orderResponse = await createOrder({
          variables: {
            orderDate,
            gigId: +gig,
            sellerId: +sellerID,
            total: +totalPrice,
          },
        });
        console.log("2nd");

        console.log(orderResponse, "orderResponse");
        if (orderResponse && orderResponse.data) {
          // toast.success("Order Created SuccessFully");
          window.location.href = orderResponse.data?.createOrder?.url;
          resetForm();
          handleClose();
          // navigate("/dashboard/managejobs");
        } else {
          console.error("Order failed");
        }
      } catch (error) {
        console.log(error, "error");
      }
    },
  });
  
  console.log('selected------>>',selectedUser)

  return (
    <>
      <>
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Message</h2>
          </div>
        </div>
        <div className="row mb40">
          <div className="col-lg-4 col-xl-4 col-xxl-4">
            <div className="message_container">
              <div className="inbox_user_list">
                <div className="iu_heading pr35">
                  <div className="chat_user_search">
                    <form className="d-flex align-items-center">
                      <button className="btn" type="button">
                        <span className="far fa-magnifying-glass" />
                      </button>
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Serach"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
                <div className="chat-member-list pr20">
                  {chatLoading &&  
                  [1,2,3,4,5,6,7,8].map(e=><ChatUserCard/>) }
                  { chatsData?.userChats?.length > 0 ? (
                    chatsData?.userChats?.map((e) => (
                      <div className="list-item pt5">
                        <a>
                          <div
                            className="d-flex align-items-center position-relative"
                            onClick={() => {
                              setSellerID(
                                e?.sender?.id === currentUser?.currentUser?.id
                                  ? e?.receiver?.id
                                  : e?.sender?.id
                              );
                              setSelectedUser({
                                id: e?.id,
                                receiver: {
                                  username:
                                    e?.sender?.id ===
                                    currentUser?.currentUser?.id
                                      ? e?.receiver?.username
                                      : e?.sender?.username,
                                  profilePic:
                                    e?.sender?.id ===
                                    currentUser?.currentUser?.id
                                      ? e?.receiver?.profilePic
                                      : e?.sender?.profilePic,
                                  role:
                                    e?.sender?.id ===
                                    currentUser?.currentUser?.id
                                      ? e?.receiver?.role
                                      : e?.sender?.role,
                                },
                              });
                              GetMessages(e?.id);
                            }}
                          >
                            <img
                              alt="ms1.png"
                              loading="lazy"
                              width={40}
                              height={40}
                              decoding="async"
                              data-nimg={1}
                              className="img-fluid float-start rounded-circle mr10"
                              style={{ color: "transparent" }}
                              src={`${"https://yassohapi.devssh.xyz"}${
                                e?.sender?.id === currentUser?.currentUser?.id
                                  ? e?.receiver?.profilePic
                                  : e?.sender?.profilePic
                              }`}
                            />
                            <div className="d-sm-flex">
                              <div className="d-inline-block">
                                <div className="fz15 fw500 dark-color ff-heading mb-0">
                                  {e?.sender?.id ===
                                  currentUser?.currentUser?.id
                                    ? e?.receiver?.username
                                    : e?.sender?.username}
                                </div>
                                {e?.messages?.length && (
                                  <p className="preview">
                                    {e?.messages[0]?.text.length > 25 ? `${e?.messages[0]?.text.slice(0,25)}...` : e?.messages[0]?.text}
                                    
                                  </p>
                                )}
                              </div>
                              <div className="iul_notific">
                                <small>
                                  {formatTime(e?.messages[0]?.createdAt)}
                                </small>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))
                  ) : (
                    <div className="d-sm-flex">
                      <div className="d-inline-block">
                        <div className="fz15 fw500 dark-color ff-heading mb-0">
                          No chat found!
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-8 col-xxl-8">
            <div className="message_container mt30-md">
              <div className="user_heading px-0 mx30">
                <div className="wrap">
                  {selectedUser?.receiver && (
                    <img
                      alt="ms3.png"
                      loading="lazy"
                      width={50}
                      height={50}
                      decoding="async"
                      data-nimg={1}
                      className="img-fluid mr10"
                      style={{ color: "transparent",width:50,height:50 }}
                      src={`${"https://yassohapi.devssh.xyz"}${
                        selectedUser?.receiver?.profilePic
                      }`}
                    />
                  )}
                  <div className="meta d-sm-flex justify-content-sm-between align-items-center">
                    <div className="authors">
                      <h6 className="name mb-0">
                        {selectedUser?.receiver?.username
                          ? selectedUser?.receiver?.username
                          : "Please select user for chatting"}
                      </h6>
                      {/* <p className="preview">Active</p>  */}
                    </div>
                    {selectedUser?.receiver?.role === "SELLER" &&
                      selectedUser && (
                        <div>
                          <button
                            type="submit"
                            className="ud-btn btn-order"
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            Create Order
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="inbox_chatting_box" ref={messagesEndRef}>
                <ul className="chatting_content">
                  {messages?.length > 0
                    ? messages
                        .slice()
                        .reverse()
                        .map((e) => (
                          <li
                            className={`${
                              currentUser?.currentUser?.id === e?.sender?.id
                                ? "float-end"
                                : "send float-start"
                            }`}
                          >
                            <div
                              className={`${
                                currentUser?.currentUser?.id === e?.sender?.id
                                  ? "message_div1"
                                  : "message_div"
                              }`}
                            >
                              <p className="message_text">{e?.text}</p>
                              <p className="float-end time">
                                {formatTime(e?.createdAt)}
                              </p>
                            </div>
                          </li>
                        ))
                    : ""}
                </ul>
              </div>

              <div className="mi_text">
                <div className="message_input">
                  <form className="d-flex align-items-center">
                    <input
                      className="form-control"
                      type="search"
                      value={text}
                      placeholder="Type a Message"
                      onChange={(e) => setText(e.target.value)}
                    />
                    <button
                      type="button"
                      className={`btn ud-btn btn-send ${!selectedUser && 'disabled'} `}
                      onClick={() => OnSubmit()}
                    >
                      {sendLoading ? (
                        <ColorRing
                          visible={true}
                          height="25"
                          width="25"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      ) : (
                        <>
                          {" "}
                          Send
                          <i class="fa-regular fa-paper-plane-top"></i>
                        </>
                      )}
                      {/* <i className="fal fa-arrow-right-long" /> */}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="modal-content position-relative">
              <button
                type="button"
                onClick={() => handleClose()}
                className="btn-close position-absolute"
                style={{ top: 10, right: 10, zIndex: 9 }}
              />
              <div className="modal-body p-4">
                <form>
                  <div className="col-sm-12">
                    <div className="mb5">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw400">
                          Select Any One Gig
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          name="gig"
                          onChange={handleGigChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.gig}
                        >
                          <option selected disabled value="">
                            Select Any One Gig
                          </option>
                          {gigData?.gigsBySellerId?.map((e) => (
                            <option value={e?.id}>{e?.title}</option>
                          ))}
                        </select>
                      </div>
                      {formik.touched.gig && formik.errors.gig && (
                        <span className="text-danger error">
                          {formik.errors.gig}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="mb5">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw400">
                          Order Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="orderDate"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.orderDate}
                        />
                      </div>
                      {formik.touched.orderDate && formik.errors.orderDate && (
                        <span className="text-danger error">
                          {formik.errors.orderDate}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="mb5">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw400">
                          Gig Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="gigPrice"
                          // onChange={handlePrice}
                          // onBlur={formik.handleBlur}
                          placeholder="Gig Price"
                          value={formik.values.gigPrice}
                        />
                      </div>
                      {formik.touched.gigPrice && formik.errors.gigPrice && (
                        <span className="text-danger error">
                          {formik.errors.gigPrice}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="mb5">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw400">
                          Yassoh Fees 10%
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="feesPrice"
                          placeholder="Fee Price"
                          value={formik.values.feesPrice}
                        />
                      </div>
                      {formik.touched.feesPrice && formik.errors.feesPrice && (
                        <span className="text-danger error">
                          {formik.errors.feesPrice}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="mb10">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw400">
                          Total Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="totalPrice"
                          placeholder="Total Price"
                          value={formik.values.totalPrice}
                        />
                      </div>
                      {formik.touched.totalPrice &&
                        formik.errors.totalPrice && (
                          <span className="text-danger error">
                            {formik.errors.totalPrice}
                          </span>
                        )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="ud-btn btn-thm mt-2"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      formik.handleSubmit(); // Manually call Formik's handleSubmit
                    }}
                  >
                    {orderLoading ? (
                      <ColorRing
                        visible={true}
                        height="25"
                        width="25"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />
                    ) : (
                      <>
                        {" "}
                        Order Place
                        <i className="fal fa-arrow-right-long" />
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </>
    </>
  );
};

export default MessageSection1;
