/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React  from "react";
import CardOneServiceAndSave from "../../AllCards/CardOneServiceAndSave";

const ServiceSection1 = () => {
  return (
    <>
      <div className="body_content">
        <section
          className="categories_list_section overflow-hidden"
          style={{ marginTop: "8%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="listings_category_nav_list_menu">
                  <ul className="mb0 d-flex ps-0">
                    <li>
                      <a className="active">All Categories</a>
                    </li>
                    <li>
                      <a className="">Graphics Design</a>
                    </li>
                    <li>
                      <a className="">Digital Marketing</a>
                    </li>
                    <li>
                      <a className="">Writing Translation</a>
                    </li>
                    <li>
                      <a className="">Video Animation</a>
                    </li>
                    <li>
                      <a className="">Music Audio</a>
                    </li>
                    <li>
                      <a className="">Programming Tech</a>
                    </li>
                    <li>
                      <a className="">Business</a>
                    </li>
                    <li>
                      <a className="">Lifestyle</a>
                    </li>
                    <li>
                      <a className="">Trending</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="breadcumb-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <div className="breadcumb-list">
                    <a>Home</a>
                    <a>Services</a>
                    <a>Design &amp; Creative</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="breadcumb-section pt-0">
          <div className="cta-banner mx-auto maxw1700 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center px30-lg cta-service-v1 mx20-lg">
            <img
              alt="vector-img"
              loading="lazy"
              width={198}
              height={226}
              decoding="async"
              data-nimg={1}
              className="left-top-img wow zoomIn"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75
        "
              src="_next/left-top9e21.png?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=640&q=75"
            />
            <img
              alt="vector-img"
              loading="lazy"
              width={255}
              height={181}
              decoding="async"
              data-nimg={1}
              className="right-bottom-img wow zoomIn"
              style={{ color: "transparent" }}
              srcSet="
          /_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75 1x,
          /_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=640&q=75 2x
        "
              src="_next/right-bottomebb3.png?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=640&q=75"
            />
            <img
              alt="vector-img"
              loading="lazy"
              width={532}
              height={300}
              decoding="async"
              data-nimg={1}
              className="service-v1-vector bounce-y d-none d-lg-block"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75"
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75"
            />
            <div className="container">
              <div className="row wow fadeInUp">
                <div className="col-xl-5">
                  <div className="position-relative">
                    <h2>Design &amp; Creative</h2>
                    <p className="text mb30">
                      Give your visitor a smooth online experience with a solid
                      UX design
                    </p>
                    <div className="d-flex align-items-center">
                      <a className="video-btn mr10 popup-iframe popup-youtube">
                        <i className="fal fa-play" />
                      </a>
                      <h6 className="mb-0">How Yasooh Works</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt30 pb90">
          <div className="container">
            <div className="row align-items-center mb20">
              <div className="col-6 col-sm-6 col-lg-9 pe-0">
                <div className="text-center text-sm-start">
                  <div className="dropdown-lists">
                    <ul className="p-0 mb-0 text-center text-sm-start">
                      <li className="list-inline-item">
                        <button
                          type="button"
                          className="open-btn filter-btn-left mb10"
                        >
                          <img
                            alt="icon"
                            loading="lazy"
                            width={18}
                            height={18}
                            decoding="async"
                            data-nimg={1}
                            className="me-2"
                            style={{ color: "transparent" }}
                            src="/images/icon/all-filter-icon.svg"
                          />
                          All Filter
                        </button>
                      </li>
                      <li className="list-inline-item position-relative d-none d-xl-inline-block">
                        <button
                          className="open-btn mb10 dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                        >
                          Delivery Time
                          <i className="fa fa-angle-down ms-2" />
                        </button>
                        <div className="dropdown-menu">
                          <div className="widget-wrapper pb25 mb0">
                            <div className="radio-element">
                              <div className="form-check d-flex align-items-center mb10">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault11"
                                  name="flexRadioDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault11"
                                >
                                  Express 24H
                                </label>
                              </div>
                              <div className="form-check d-flex align-items-center mb10">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault12"
                                  name="flexRadioDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault12"
                                >
                                  Up to 3 days
                                </label>
                              </div>
                              <div className="form-check d-flex align-items-center mb10">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault13"
                                  name="flexRadioDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault13"
                                >
                                  Up to 7 days
                                </label>
                              </div>
                              <div className="form-check d-flex align-items-center mb10">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault14"
                                  name="flexRadioDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault14"
                                >
                                  Anytime
                                </label>
                              </div>
                            </div>
                          </div>
                          <button className="done-btn ud-btn btn-thm drop_btn">
                            Apply
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                      </li>
                      <li className="list-inline-item position-relative d-none d-xl-inline-block">
                        <button
                          className="open-btn mb10 dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                        >
                          Budget
                          <i className="fa fa-angle-down ms-2" />
                        </button>
                        <div className="dropdown-menu dd3">
                          <div className="widget-wrapper pb25 mb0 pr20">
                            <div className="range-slider-style1">
                              <div className="range-wrapper">
                                <div className="price__range__box">
                                  <div
                                    style={{ position: "relative" }}
                                    className="horizontal-slider"
                                  >
                                    <div
                                      className="example-track example-track-0"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-track example-track-1"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-track example-track-2"
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                      }}
                                    />
                                    <div
                                      className="example-thumb example-thumb-0"
                                      style={{
                                        position: "absolute",
                                        touchAction: "none",
                                        zIndex: 1,
                                        left: 0,
                                      }}
                                      tabIndex={0}
                                      role="slider"
                                      aria-orientation="horizontal"
                                      aria-valuenow={0}
                                      aria-valuemin={0}
                                      aria-valuemax={100000}
                                      aria-disabled="false"
                                    />
                                    <div
                                      className="example-thumb example-thumb-1"
                                      style={{
                                        position: "absolute",
                                        touchAction: "none",
                                        zIndex: 2,
                                        left: 0,
                                      }}
                                      tabIndex={0}
                                      role="slider"
                                      aria-orientation="horizontal"
                                      aria-valuenow={100000}
                                      aria-valuemin={0}
                                      aria-valuemax={100000}
                                      aria-disabled="false"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex gap-1 align-items-center pt-4">
                                  <input
                                    type="number"
                                    className="amount w-100"
                                    placeholder="$20"
                                    min={0}
                                    defaultValue={0}
                                  />
                                  <span className="fa-sharp fa-solid fa-minus mx-1 dark-color" />
                                  <input
                                    type="number"
                                    className="amount2 w-100"
                                    placeholder="$100000"
                                    min={0}
                                    max={100000}
                                    defaultValue={100000}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <button className="done-btn ud-btn btn-thm drop_btn3">
                            Apply
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                      </li>
                      <li className="list-inline-item position-relative d-none d-xl-inline-block">
                        <button
                          className="open-btn mb10 dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                        >
                          Level
                          <i className="fa fa-angle-down ms-2" />
                        </button>
                        <div className="dropdown-menu">
                          <div className="widget-wrapper pb25 mb0">
                            <div className="checkbox-style1">
                              <label className="custom_checkbox">
                                Top Rated Seller
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                Level Two
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                Level One
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                New Seller
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                          <button className="done-btn ud-btn btn-thm dropdown-toggle">
                            Apply
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                      </li>
                      <li className="list-inline-item position-relative d-none d-xl-inline-block">
                        <button
                          className="open-btn mb10 dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                        >
                          Location
                          <i className="fa fa-angle-down ms-2" />
                        </button>
                        <div className="dropdown-menu dd4 pb20">
                          <div className="widget-wrapper pr20">
                            <div className="checkbox-style1">
                              <label className="custom_checkbox">
                                United States
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                United Kingdom
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                Canada
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                Germany
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <label className="custom_checkbox">
                                Turkey
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                          <button className="done-btn ud-btn btn-thm drop_btn4">
                            Apply
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                      </li>
                      <li className="list-inline-item position-relative d-none d-xl-inline-block" />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3 px-0">
                <div className="page_control_shorting mb10 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <div className="pcs_dropdown dark-color pr10 pr0-xs text-center">
                    <div className="dropdown bootstrap-select show-tick">
                      <div className="dropdown-menu">
                        <div className="inner show">
                          <ul className="dropdown-menu inner show">
                            <li>
                              <a className="dropdown-item active selected">
                                <span className="bs-ok-default check-mark" />
                                <span className="text">Best Seller</span>
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item">
                                <span className="bs-ok-default check-mark" />
                                <span className="text">Recommended</span>
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item">
                                <span className="bs-ok-default check-mark" />
                                <span className="text">New Arrivals</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <CardOneServiceAndSave />
          </div>
        </section>


        <div className="lefttside-hidden-bar">
          <div className="hsidebar-header bdrb1">
            <h4 className="list-title">All filters</h4>
            <div className="sidebar-close-icon">
              <span className="far fa-times" />
            </div>
          </div>
          <div className="hsidebar-content">
            <div className="widget-wrapper">
              <div className="sidebar-accordion">
                <div className="accordion" id="accordionExample2">
                  <div className="card mb20 pb10 rounded-0">
                    <div className="card-header" id="headingZero">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseZero"
                          aria-expanded="false"
                          aria-controls="collapseZero"
                        >
                          Delivery Time
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseZero"
                      className="collapse show"
                      aria-labelledby="headingZero"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="radio-element">
                          <div className="form-check d-flex align-items-center mb10">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="delivery-time1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="delivery-time1"
                            >
                              Express 24H
                            </label>
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </div>
                          <div className="form-check d-flex align-items-center mb10">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="delivery-time2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="delivery-time2"
                            >
                              Up to 3 days
                            </label>
                            <span className="right-tags">
                              ({/* */}8,136{/* */})
                            </span>
                          </div>
                          <div className="form-check d-flex align-items-center mb10">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="delivery-time3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="delivery-time3"
                            >
                              Up to 7 days
                            </label>
                            <span className="right-tags">
                              ({/* */}917{/* */})
                            </span>
                          </div>
                          <div className="form-check d-flex align-items-center mb10">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="delivery-time4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="delivery-time4"
                            >
                              Anytime
                            </label>
                            <span className="right-tags">
                              ({/* */}240{/* */})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb0 rounded-0">
                    <div className="card-header" id="headingOnes">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnes"
                          aria-expanded="true"
                          aria-controls="collapseOnes"
                        >
                          Budget
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseOnes"
                      className="collapse"
                      aria-labelledby="headingOnes"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="range-slider-style2">
                          <div className="range-wrapper">
                            <div className="price__range__box">
                              <div
                                style={{ position: "relative" }}
                                className="horizontal-slider"
                              >
                                <div
                                  className="example-track example-track-0"
                                  style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                  }}
                                />
                                <div
                                  className="example-track example-track-1"
                                  style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                  }}
                                />
                                <div
                                  className="example-track example-track-2"
                                  style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                  }}
                                />
                                <div
                                  className="example-thumb example-thumb-0"
                                  style={{
                                    position: "absolute",
                                    touchAction: "none",
                                    zIndex: 1,
                                    left: 0,
                                  }}
                                  tabIndex={0}
                                  role="slider"
                                  aria-orientation="horizontal"
                                  aria-valuenow={0}
                                  aria-valuemin={0}
                                  aria-valuemax={100000}
                                  aria-disabled="false"
                                />
                                <div
                                  className="example-thumb example-thumb-1"
                                  style={{
                                    position: "absolute",
                                    touchAction: "none",
                                    zIndex: 2,
                                    left: 0,
                                  }}
                                  tabIndex={0}
                                  role="slider"
                                  aria-orientation="horizontal"
                                  aria-valuenow={100000}
                                  aria-valuemin={0}
                                  aria-valuemax={100000}
                                  aria-disabled="false"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center pt-3">
                              <span id="slider-range-value1">${/* */}0</span>
                              <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon" />
                              <span id="slider-range-value2">
                                ${/* */}100000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb5 rounded-0">
                    <div className="card-header" id="headingTwos">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwos"
                          aria-expanded="true"
                          aria-controls="collapseTwos"
                        >
                          Design Tool
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseTwos"
                      className="collapse"
                      aria-labelledby="headingTwos"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="checkbox-style1 mb15">
                          <label className="custom_checkbox">
                            Adobe Photoshop
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Figma
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}8,15{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Sketch
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}654{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Adobe XD
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}323{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Balsamiq
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}2,455{/* */})
                            </span>
                          </label>
                        </div>
                        <a className="text-thm" href="#">
                          +20 more
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb5 rounded-0">
                    <div className="card-header" id="headingThrees">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThrees"
                          aria-expanded="true"
                          aria-controls="collapseThrees"
                        >
                          Location
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseThrees"
                      className="collapse"
                      aria-labelledby="headingThrees"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="default-box-shadow1 mb15">
                          <div className="search_area">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="What are you looking for?"
                              defaultValue=""
                            />
                            <label>
                              <span className="flaticon-loupe" />
                            </label>
                          </div>
                        </div>
                        <div className="checkbox-style1 mb15">
                          <label className="custom_checkbox">
                            United States
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            United Kingdom
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}8,136{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Canada
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}917{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Germany
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}240{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Turkey
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}2,460{/* */})
                            </span>
                          </label>
                        </div>
                        <a className="text-thm">+20 more</a>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb5 rounded-0">
                    <div className="card-header" id="headingFours">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFours"
                          aria-expanded="true"
                          aria-controls="collapseFours"
                        >
                          Speaks
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseFours"
                      className="collapse"
                      aria-labelledby="headingFours"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="checkbox-style1 mb15">
                          <label className="custom_checkbox">
                            Turkish
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            English
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}8,15{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Italian
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}654{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Spanish
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}323{/* */})
                            </span>
                          </label>
                        </div>
                        <a className="text-thm">+20 more</a>
                      </div>
                    </div>
                  </div>
                  <div className="card mb20 pb0 rounded-0">
                    <div className="card-header" id="headingFives">
                      <h4>
                        <button
                          className="btn btn-link ps-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFives"
                          aria-expanded="true"
                          aria-controls="collapseFives"
                        >
                          Level
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseFives"
                      className="collapse"
                      aria-labelledby="headingFives"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body card-body px-0 pt-0">
                        <div className="checkbox-style1">
                          <label className="custom_checkbox">
                            Top Rated Seller
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}1,945{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Level Two
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}8,136{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            Level One
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}917{/* */})
                            </span>
                          </label>
                          <label className="custom_checkbox">
                            New Seller
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <span className="right-tags">
                              ({/* */}240{/* */})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenbar-body-ovelay" />
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
};

export default ServiceSection1;
