/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { initialValuesBecomeSeller, becomeSellerSchema } from "./schema";
import { useFormik } from "formik";
import { GET_SERVICES } from "../../Graphql/Query/Service";
import { GET_USER } from "../../Graphql/Query/AuthQuery";
import { BECOME_SELLER_MUTATION } from "../../Graphql/Mutation/Auth/AuthMutation";

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. 'Swaziland')",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const FreelancerRegisterSection = () => {

  const navigate = useNavigate();
  const { data } = useQuery(GET_SERVICES);
  const { data:userData } = useQuery(GET_USER);

  const [becomeSeller, { loading: loginLoading }] =
  useMutation(BECOME_SELLER_MUTATION);

  const defaultValues = useMemo(() => initialValuesBecomeSeller(userData?.currentUser));
  const services = data?.services || [];

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues:defaultValues,
    validationSchema:becomeSellerSchema,
    onSubmit: async(values) => {
      console.log(values)
      const {
        firstName,
        lastName,
        // email,
        service_areas,
        services,
        role,
        parish,
      } = values;

      try {       
        const registerResponse = await becomeSeller({
          variables: {
            userId:userData?.currentUser?.id,
            firstName,
            lastName,
            service_areas,
            services: services.map((e) => e?.value),
            parish,
            role,
          },
        });

        console.log(registerResponse, "registerResponse");
        if (registerResponse && registerResponse.data) {
          navigate("/dashboard");
          toast.success("Seller Registered SuccessFully");
        } else {
          console.error("Registration failed");
        }
      } catch (error) {
        console.log(error,"---------error");
      }
    },
  }
);

  return (
    <>
      <div className="body_content mt40" style={{ background: "#FFEDE8" }}>
        <section className="our-register">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="main-title text-center">
                  <h2 className="title">Register</h2>
                  <p className="paragraph">
                    <strong>FREELANCER:</strong> Join other successful
                    Freelancers. Start getting new jobs in your preferred
                    service
                  </p>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div className="col-xl-6 mx-auto">
                  <div className="log-reg-form search-modal form-style1 bgc-white p40 p30-sm default-box-shadow1 bdrs12">
                    <div className="mb30">
                      <h4>Create your Free Account</h4>
                      <p className="text mt20">
                        Already have an account?{/* */}
                        <Link className="text-thm" to={"/login"}>
                          Log In!
                        </Link>
                      </p>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder="First name"
                        onChange={handleChange}
                        value={values.firstName}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.firstName && errors.firstName}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        placeholder="Last name"
                        onChange={handleChange}
                        value={values.lastName}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.lastName && errors.lastName}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Parish
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="parish"
                        placeholder="Parish"
                        onChange={handleChange}
                        value={values.parish}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.parish && errors.parish}
                      </span>
                    </div>
                  
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Select Role?{" "}
                      </label>
                      <select
                        class="form-select form-control"
                        name="role"
                        aria-label="Default select example"
                        onChange={handleChange}
                        value={values.role}
                        onBlur={handleBlur}
                      >
                        <option selected disabled value="">
                          Select Role?{" "}
                        </option>
                        <option value="SELLER">Seller</option>
                        <option value="BUYER">Buyer</option>
                      </select>

                      <span className="error">
                        {touched.role && errors.role}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Select any other services you do.
                      </label>

                      <Select
                        isMulti
                        name="services"
                        options={services?.map((e) => ({
                          value: e?.id,
                          label: e?.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "services",
                            selectedOptions?.map((option) => ({
                              value: option?.value,
                              label: option?.label,
                            }))
                          )
                        }
                        onBlur={handleBlur("services")}
                        value={values?.services?.map((service) => ({
                          value: service?.value,
                          label: service?.label,
                        }))}
                      />
                      <span className="error">
                        {touched.services && errors.services}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Select Service Areas from parishes
                      </label>

                      <Select
                        isMulti
                        name="service_areas"
                        options={countries?.map((e) => ({
                          value: e,
                          label: e,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "service_areas",
                            selectedOptions?.map((option) => option.value)
                          )
                        }
                        onBlur={handleBlur("service_areas")}
                        value={values?.service_areas?.map((service) => ({
                          value: service,
                          label: service,
                        }))}
                      />
                      <span className="error">
                        {touched.service_areas && errors.service_areas}
                      </span>
                    </div>

                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz14 ff-heading">
                        By signing up with email, Facebook, or Google, you agree
                        to our Terms of Use and Privacy Policy.
                        <input
                          type="checkbox"
                          name="checkbox1"
                          checked={values.checkbox1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="checkmark" /> <br />
                        <span className="error">
                          {touched.checkbox1 && errors.checkbox1}
                        </span>
                      </label>
                    </div>
                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz14 ff-heading">
                        I'd like to receive special email offers and discounts.
                        <input
                          type="checkbox"
                          name="checkbox2"
                          checked={values.checkbox2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="checkmark" /> <br />
                        <span className="error">
                          {touched.checkbox2 && errors.checkbox2}
                        </span>
                      </label>
                    </div>
                    <div className="d-grid mb20">
                      <button
                        className="ud-btn btn-thm default-box-shadow2"
                        type="submit"
                      >
                         {loginLoading ? (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        ) : (
                          "Creat Account"
                        )}
                        {/* Creat Account <i className="fal fa-arrow-right-long" /> */}
                      </button>
                    </div>
                    <div>
                      <label className="custom_checkbox fz14 ff-heading">
                        Problems Registering?
                      </label>
                    </div>
                    <div>
                      <label className="custom_checkbox fz14 ff-heading">
                        Contact us at 1-888-yassoh
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
};

export default FreelancerRegisterSection;
