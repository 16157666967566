import React from 'react'
import Layout from '../../../Layout/Layout'
import ProjectSection1 from '../../../Components/BrowserJobs/Projects/ProjectSection1'

const Project = () => {
  return (
    <>
      <Layout>
        <ProjectSection1 />
      </Layout>
    </>
  )
}

export default Project


