
import { toast } from "react-hot-toast";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from '@apollo/client/link/ws';
// import { createClient } from 'graphql-ws';
import { split } from "apollo-link";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "apollo-utilities";

const httpLink = new HttpLink({ uri: "https://yassohapi.devssh.xyz/graphql" });

const wsLink = new WebSocketLink({
  uri: 'wss://yassohapi.devssh.xyz/graphql',
  options: {
    reconnect: true
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message !== "Not Authorized!" && path !== "notifications") {
        toast.error(message);
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    toast.error(networkError);
    console.log(`[Network error]: ${networkError}`);
  }
});

const customMiddlewareLink = new ApolloLink((operation, forward) => {
  console.log("Request intercepted:", operation.operationName);
  return forward(operation).map((response) => {
    // console.log("Response intercepted:", response.data);
    return response;
  });
});

// const authLink = new ApolloLink((operation, forward) => {
//   const token = localStorage.getItem("authToken");
//   operation.setContext({
//     headers: {
//       Authorization: token ? `Bearer ${token}` : "",
//     },
//   });
//   return forward(operation);
// });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("authToken");
  // console.log("token", token);
  let authorization = "";
  if (token) authorization = `Bearer ${token}`;
  return {
    headers: {
      ...headers,
      authorization,
      Cookie: null,
    },
  };
});


// Create the Apollo Client instance
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, customMiddlewareLink, authLink.concat(splitLink)]), // Combine links
  // link: authLink.concat(splitLink).concat(errorLink),
  cache: new InMemoryCache(),
});

export default client;
