import * as yup from "yup";


export const initialValues = {
  title: "",
  price: "",
  description: "",
  requirement: "",
  tags:[],
  portfolio: [],
  deliveryTime: '',
  serviceCategoryId:"",
  service_id:""
};

export const serviceSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    price: yup.number().required("Price is required"),
    description: yup.string().required("Description is required"),
    requirement: yup.string().required("Requirement is required"),
    deliveryTime: yup.string().required("Delivery time is required"),
    serviceCategoryId: yup.string().required("Sub Category is required"),
    service_id: yup.string().required("Service is required"),
    tags: yup.array().required("Tag is required"),
    portfolio: yup.array().required("Portfolio is required"),
    // profilePic: yup.mixed().required("Image is required"),
  });