/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Dashboard = () => {
  return (
    <>    
    <div className="container">
      <div className="col-lg-12">
        <div className="dashboard_title_area">
          <h2>Dashboard</h2>
          <p className="text">Lorem ipsum dolor sit amet, consectetur.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-xxl-3">
          <div className="d-flex align-items-center justify-content-between statistics_funfact">
            <div className="details">
              <div className="fz15">Services Offered</div>
              <div className="title">25</div>
              <div className="text fz14">
                <span className="text-thm">10</span> New Offered
              </div>
            </div>
            <div className="icon text-center">
              <i className="flaticon-contract" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xxl-3">
          <div className="d-flex align-items-center justify-content-between statistics_funfact">
            <div className="details">
              <div className="fz15">Completed Services</div>
              <div className="title">1292</div>
              <div className="text fz14">
                <span className="text-thm">80+</span> New Completed
              </div>
            </div>
            <div className="icon text-center">
              <i className="flaticon-success" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xxl-3">
          <div className="d-flex align-items-center justify-content-between statistics_funfact">
            <div className="details">
              <div className="fz15">in Queue Services</div>
              <div className="title">182</div>
              <div className="text fz14">
                <span className="text-thm">35+</span> New Queue
              </div>
            </div>
            <div className="icon text-center">
              <i className="flaticon-review" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xxl-3">
          <div className="d-flex align-items-center justify-content-between statistics_funfact">
            <div className="details">
              <div className="fz15">Total Review</div>
              <div className="title">22,786</div>
              <div className="text fz14">
                <span className="text-thm">290+</span> New Review
              </div>
            </div>
            <div className="icon text-center">
              <i className="flaticon-review-1" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8">
          <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
              <div className="d-sm-flex align-items-center justify-content-between">
                <h4 className="title fz17 mb20">Profile Views</h4>
                <div className="page_control_shorting dark-color pr10 text-center text-md-end">
                  <div className="dropdown bootstrap-select show-tick">
                    <button
                      type="button"
                      className="btn dropdown-toggle btn-light"
                      data-bs-toggle="dropdown"
                    >
                      <div className="filter-option">
                        <div className="filter-option-inner">
                          <div className="filter-option-inner-inner">
                            This Week
                          </div>
                        </div>
                      </div>
                    </button>
                    <div className="dropdown-menu">
                      <div className="inner show">
                        <ul className="dropdown-menu inner show">
                          <li className="selected active">
                            <a className="dropdown-item selected active">
                              <span className="bs-ok-default check-mark" />
                              <span className="text">This Week</span>
                            </a>
                          </li>
                          <li className="">
                            <a className="dropdown-item">
                              <span className="bs-ok-default check-mark" />
                              <span className="text">This Month</span>
                            </a>
                          </li>
                          <li className="">
                            <a className="dropdown-item">
                              <span className="bs-ok-default check-mark" />
                              <span className="text">This Year</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="w-100" src="/images/download.png" alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
            <div className="bdrb1 pb15 mb30">
              <h5 className="title">Traffic</h5>
            </div>
            <img src="/images/traffic.png" alt="" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xxl-4">
          <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
            <div className="d-flex justify-content-between bdrb1 pb15 mb20">
              <h5 className="title">Most Viewed Services</h5>
              <a className="text-decoration-underline text-thm6">View All</a>
            </div>
            <div className="dashboard-img-service">
              <div>
                <div className="listing-style1 list-style d-block d-xl-flex align-items-center border-0 mb10">
                  <div className="list-thumb flex-shrink-0 bdrs4">
                    <img
                      alt="thumb"
                      loading="lazy"
                      width={122}
                      height={91}
                      decoding="async"
                      data-nimg={1}
                      className="w-100 h-auto"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75
                        "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75"
                    />
                  </div>
                  <div className="list-content flex-grow-1 pt10 pb10 pl15 pl0-lg">
                    <h6 className="list-title mb-2">
                      <a href="/">
                        I will design modern websites in figma or adobe xd
                      </a>
                    </h6>
                    <div className="list-meta d-flex justify-content-between align-items-center">
                      <div className="review-meta d-flex align-items-center">
                        <i className="fas fa-star fz10 review-color me-2" />
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">4.82</span>
                        </p>
                      </div>
                      <div className="budget">
                        <p className="mb-0 body-color">
                          Starting at
                          <span className="fz17 fw500 dark-color ms-1">
                            ${/* */}983
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="opacity-100 mt-0" />
              </div>
              <div>
                <div className="listing-style1 list-style d-block d-xl-flex align-items-center border-0 mb10">
                  <div className="list-thumb flex-shrink-0 bdrs4">
                    <img
                      alt="thumb"
                      loading="lazy"
                      width={122}
                      height={91}
                      decoding="async"
                      data-nimg={1}
                      className="w-100 h-auto"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-2.jpg&w=256&q=75
                        "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-2.jpg&w=256&q=75"
                    />
                  </div>
                  <div className="list-content flex-grow-1 pt10 pb10 pl15 pl0-lg">
                    <h6 className="list-title mb-2">
                      <a href="/">
                        I will create modern flat design illustration
                      </a>
                    </h6>
                    <div className="list-meta d-flex justify-content-between align-items-center">
                      <div className="review-meta d-flex align-items-center">
                        <i className="fas fa-star fz10 review-color me-2" />
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">4.82</span>
                        </p>
                      </div>
                      <div className="budget">
                        <p className="mb-0 body-color">
                          Starting at
                          <span className="fz17 fw500 dark-color ms-1">
                            ${/* */}983
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="opacity-100 mt-0" />
              </div>
              <div>
                <div className="listing-style1 list-style d-block d-xl-flex align-items-center border-0 mb10">
                  <div className="list-thumb flex-shrink-0 bdrs4">
                    <img
                      alt="thumb"
                      loading="lazy"
                      width={122}
                      height={91}
                      decoding="async"
                      data-nimg={1}
                      className="w-100 h-auto"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-3.jpg&w=256&q=75
                        "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-3.jpg&w=256&q=75"
                    />
                  </div>
                  <div className="list-content flex-grow-1 pt10 pb10 pl15 pl0-lg">
                    <h6 className="list-title mb-2">
                      <a href="/">
                        I will build a fully responsive design in HTML,CSS,
                        bootstrap, and javascript
                      </a>
                    </h6>
                    <div className="list-meta d-flex justify-content-between align-items-center">
                      <div className="review-meta d-flex align-items-center">
                        <i className="fas fa-star fz10 review-color me-2" />
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">4.82</span>
                        </p>
                      </div>
                      <div className="budget">
                        <p className="mb-0 body-color">
                          Starting at
                          <span className="fz17 fw500 dark-color ms-1">
                            ${/* */}983
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-4">
          <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
            <div className="d-flex justify-content-between bdrb1 pb15 mb30">
              <h5 className="title">Recent Purchased Services</h5>
              <a className="text-decoration-underline text-thm6">View All</a>
            </div>
            <div className="dashboard-img-service">
              <div>
                <div className="listing-style1 list-style d-block d-xl-flex align-items-start border-0 mb10">
                  <div className="list-thumb wa flex-shrink-0 bdrs4 mb15-lg">
                    <img
                      alt="thumb"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa img-2"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75
                        "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75"
                    />
                  </div>
                  <div className="list-content flex-grow-1 py-0 pb10 pl15 pl0-lg">
                    <h6 className="list-title mb-2">
                      Medium.
                      <span className="text-thm">has purchased</span> I will
                      deal with your item Description and assets
                    </h6>
                    <div className="list-meta d-flex justify-content-between align-items-center">
                      <div className="review-meta d-flex align-items-center">
                        <p className="mb-0 body-color fz14">
                          February 26, 2021
                        </p>
                      </div>
                      <div className="budget">
                        <p className="mb-0">
                          <span className="fz15 fw500 dark-color">$983</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="opacity-100 mt-0" />
              </div>
              <div>
                <div className="listing-style1 list-style d-block d-xl-flex align-items-start border-0 mb10">
                  <div className="list-thumb wa flex-shrink-0 bdrs4 mb15-lg">
                    <img
                      alt="thumb"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa img-2"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75
                        "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
                    />
                  </div>
                  <div className="list-content flex-grow-1 py-0 pb10 pl15 pl0-lg">
                    <h6 className="list-title mb-2">
                      Medium.
                      <span className="text-thm">has purchased</span> I will
                      deal with your item Description and assets
                    </h6>
                    <div className="list-meta d-flex justify-content-between align-items-center">
                      <div className="review-meta d-flex align-items-center">
                        <p className="mb-0 body-color fz14">
                          February 26, 2021
                        </p>
                      </div>
                      <div className="budget">
                        <p className="mb-0">
                          <span className="fz15 fw500 dark-color">$983</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="opacity-100 mt-0" />
              </div>
              <div>
                <div className="listing-style1 list-style d-block d-xl-flex align-items-start border-0 mb10">
                  <div className="list-thumb wa flex-shrink-0 bdrs4 mb15-lg">
                    <img
                      alt="thumb"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa img-2"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75
                        "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75"
                    />
                  </div>
                  <div className="list-content flex-grow-1 py-0 pb10 pl15 pl0-lg">
                    <h6 className="list-title mb-2">
                      Medium.
                      <span className="text-thm">has purchased</span> I will
                      deal with your item Description and assets
                    </h6>
                    <div className="list-meta d-flex justify-content-between align-items-center">
                      <div className="review-meta d-flex align-items-center">
                        <p className="mb-0 body-color fz14">
                          February 26, 2021
                        </p>
                      </div>
                      <div className="budget">
                        <p className="mb-0">
                          <span className="fz15 fw500 dark-color">$983</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-4">
          <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
            <div className="bdrb1 pb15 mb30">
              <h5 className="title">Recent Activity</h5>
            </div>
            <div className="dashboard-timeline-label">
              <div className="timeline-item pb15">
                <div className="child-timeline-label">08:42</div>
                <div className="timeline-badge d-flex align-items-center">
                  <i className="fas fa-genderless" />
                </div>
                <div className="ra_pcontent pl10">
                  <span className="title">Purchase by Ali Price</span>
                  <br />
                  <span className="subtitle">
                    Product noise evolve smartwatch
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-timeline-label">
              <div className="timeline-item pb15">
                <div className="child-timeline-label">14:37</div>
                <div className="timeline-badge d-flex align-items-center color3">
                  <i className="fas fa-genderless" />
                </div>
                <div className="ra_pcontent pl10">
                  <span className="title">
                    Make deposit{/* */}
                    <span className="text-thm6 fw500">USD 700</span> to TFN
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-timeline-label">
              <div className="timeline-item pb0">
                <div className="child-timeline-label">16:50</div>
                <div className="timeline-badge d-flex align-items-center color4">
                  <i className="fas fa-genderless" />
                </div>
                <div className="ra_pcontent pl10">
                  <span className="title">
                    Natasha Carey have liked the products
                  </span>
                  <br />
                  <span className="subtitle">
                    Allow users to like products in your WooCommerce store.
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-timeline-label">
              <div className="timeline-item pb10">
                <div className="child-timeline-label">21:03</div>
                <div className="timeline-badge d-flex align-items-center color5">
                  <i className="fas fa-genderless" />
                </div>
                <div className="ra_pcontent pl10">
                  <span className="title">Favoried Product</span> <br />
                  <span className="subtitle">
                    Esther James have favorited product.
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-timeline-label before-none mb30">
              <div className="timeline-item">
                <div className="child-timeline-label">23:07</div>
                <div className="timeline-badge d-flex align-items-center color6">
                  <i className="fas fa-genderless" />
                </div>
                <div className="ra_pcontent pl10">
                  <span className="title">Today offers by Digitech Galaxy</span>
                  <br />
                  <span className="subtitle">
                    Offer is valid on orders of Rs.500 Or above for selected
                    products only.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Dashboard;
