import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import InoviceSection1 from "../../Components/Dashboard/Inovice/InoviceSection1";

const Inovice = () => {
  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <InoviceSection1 />
        </div>
      </Layout>
    </>
  );
};

export default Inovice;
