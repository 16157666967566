/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { GET_USER } from "../../Graphql/Query/AuthQuery";
import { useQuery } from "@apollo/client";

const Header = () => {
  const navigate = useNavigate();

  const handlerSidebar = () => {
    document.getElementById("offcanvasExample").classList.toggle("show");
  };
  const token = localStorage.getItem("authToken");
  const { data } = useQuery(GET_USER);
  
  // const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      {/* // header-nav nav-homepage-style stricky main-menu animated   slideInDown stricky-fixed */}
      {/* className="header-nav nav-homepage-style stricky main-menu animated slideIn" */}
      <header
        className={
          "header-nav nav-homepage-style stricky main-menu animated slideInDown stricky-fixed"
        }
      >
        <nav className="posr">
          <div className="container-fluid posr menu_bdrt1 px30">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto px-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logos br-white-light pr30 pr5-xl">
                    <a className="header-logo logo1" href="/">
                      <img
                        alt="Header Logo"
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{
                          color: "transparent",
                          height: "70px",
                          marginBottom: "10px",
                          width: "150px",
                        }}
                        src="/images/header.png"
                      />
                    </a>
                    <Link className="header-logo logo2" to="/">
                      <img
                        alt="Header Logo"
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{
                          color: "transparent",
                          height: "70px",
                          marginBottom: "10px",
                          width: "150px",
                        }}
                        src="/images/header.png"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-auto px-0">
                <div className="d-flex align-items-center">
                  <ul className="ace-responsive-menu ui-navigation">
                    <li className="visible_list menu-active home-menu-parent mt-2">
                      <a className="list-item ui-active mx-2">
                        <Link to={"/"}>
                          <span className="ud-btn btn-thm add-joining">
                            Notification
                          </span>
                        </Link>
                      </a>
                    </li>
                    {token &&
                    <li className="visible_list menu-active mx-2">
                      <a className="list-item">
                        <Link to={"/dashboard/message"}>
                          <span className="ud-btn btn-thm add-joining">
                            Message{" "}
                          </span>
                        </Link>
                      </a>
                    </li>}
                    {/* <li className="visible_list menu-active mx-2">
                      <a className="list-item">
                        <Link className="ud-btn btn-thm add-joining" to={"#"}>
                          Orders
                        </Link>
                      </a>
                    </li>
                    <li className="visible_list menu-active mx-2">
                      <a className="list-item">
                        <Link
                          className="ud-btn btn-thm add-joining"
                          to={"/whoweare"}
                        >
                          Who we are
                        </Link>
                      </a>
                    </li> */}
                    {token ?
                     data?.currentUser?.role === 'BUYER' &&
                      <li className="visible_list menu-active mx-2">
                      <a className="list-item">
                        <Link
                          className="ud-btn btn-thm add-joining"
                          to={"/becomesellersignup"}
                        >
                          <span className="d-none d-xl-inline-block">
                            Become a Freelancer{" "}
                          </span>
                        </Link>
                      </a>
                      </li>:''}
                    {token &&
                       <li className="visible_list menu-active mx-2">
                       <a className="list-item">
                         <Link
                           className="ud-btn btn-thm add-joining"
                           to={"/dashboard"}
                         >
                           <span className="d-none d-xl-inline-block">
                             Dashboard{" "}
                           </span>
                         </Link>
                       </a>
                       </li>}
                    
                  
                    {!token ? (
                      <li className="visible_list menu-active mx-2">
                        <a className="list-item">
                          <Link
                            className="ud-btn btn-thm add-joining"
                            to={"/login"}
                          >
                            <span className="d-none d-xl-inline-block">
                              Login{" "}
                            </span>
                          </Link>
                        </a>
                      </li>
                    ) : (
                      <li
                        className="visible_list menu-active mx-2"
                        onClick={() => {
                          localStorage.clear();
                          navigate("/login");
                        }}
                      >
                        <a className="list-item">
                          <Link className="ud-btn btn-thm add-joining" to="">
                            <span className="d-none d-xl-inline-block">
                              logout{" "}
                            </span>
                          </Link>
                        </a>
                      </li>
                    )}
                    {/* <li className="visible_list menu-active mx-2">
                      <Link className="list-item" to={"/service"}>
                        <span className="title">Services</span>
                      </Link>
                    </li>
                    <li className="visible_list menu-active mx-2">
                      <Link className="list-item" to={"/about"}>
                        <span className="title">About</span>
                      </Link>
                    </li>
                    <li className="visible_list menu-active mx-2">
                      <Link className="list-item" to={"/contact"}>
                        <span className="title">Contact</span>
                      </Link>
                    </li> */}
                  </ul>
                
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="mobilie_header_nav stylehome1">
        <div className="mobile-menu">
          <div className="header bb-white-light">
            <div className="menu_and_widgets">
              <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                <Link className="mobile_logo" to={"/"}>
                  <img
                    alt="Header Logo"
                    loading="lazy"
                    width={133}
                    height={80}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent" }}
                    src="images/header.png"
                  />
                </Link>
                <div className="right-side text-end">
                  <Link className="text-white" to={"/register"}>
                    join
                  </Link>
                  <a
                    className="menubar ml30"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    {window.location.pathname === "/" ? (
                      <img
                        alt="icon"
                        loading="lazy"
                        width={20}
                        height={20}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent" }}
                        src="/images/mobile-dark-nav-icon.svg"
                        onClick={handlerSidebar}
                      />
                    ) : (
                      <img
                        alt="icon"
                        loading="lazy"
                        width={20}
                        height={20}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "black" }}
                        src="/images/mobile-dark-nav-icon.svg"
                        onClick={handlerSidebar}
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>
            <div className="posr">
              <div className="mobile_menu_close_btn">
                <span className="far fa-times" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="search-modal">
        <div
          className="modal fade"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalToggleLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-xmark" />
                </button>
              </div>
              <div className="modal-body">
                <div className="popup-search-field search_area">
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="What service are you looking for today?"
                  />
                  <label>
                    <span className="far fa-magnifying-glass" />
                  </label>
                  <button className="ud-btn btn-thm" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header border-bottom">
          <a href="/">
            <img
              alt="Header Logo"
              loading="lazy"
              decoding="async"
              data-nimg={1}
              style={{
                color: "transparent",
                height: "70px",
                width: "150px",
                marginBottom: "10px",
              }}
              src="/images/header.png"
            />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handlerSidebar}
          />
        </div>
        <div className="offcanvas-body">
          <div className="ui-navigation-sidebar">
            <aside
              data-testid="ps-sidebar-root-test-id"
              width="250px"
              className="ps-sidebar-root css-1wvake5"
            >
              <div
                data-testid="ps-sidebar-container-test-id"
                className="ps-sidebar-container css-dip3t8"
              >
                <nav className="ps-menu-root css-vj11vy">
                  <ul className="css-ewdv3l">
                    <style
                      data-emotion="css 16jesut"
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n                    .css-16jesut {\n                      position: relative;\n                      width: 100%;\n                    }\n                    .css-16jesut > .ps-menu-button {\n                      display: -webkit-box;\n                      display: -webkit-flex;\n                      display: -ms-flexbox;\n                      display: flex;\n                      -webkit-align-items: center;\n                      -webkit-box-align: center;\n                      -ms-flex-align: center;\n                      align-items: center;\n                      height: 50px;\n                      -webkit-text-decoration: none;\n                      text-decoration: none;\n                      color: inherit;\n                      box-sizing: border-box;\n                      cursor: pointer;\n                      padding-right: 20px;\n                      padding-left: 20px;\n                    }\n                    .css-16jesut > .ps-menu-button:hover {\n                      background-color: #f3f3f3;\n                    }\n                  ",
                      }}
                    />
                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                      <Link
                        className="ps-menu-button"
                        data-testid="ps-menu-button-test-id"
                        tabIndex={0}
                        to={"/"}
                      >
                        <span className="ps-menu-label css-12w9als">
                          Notification
                        </span>
                        <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                      </Link>
                    </li>
                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                      <a
                        className="ps-menu-button"
                        data-testid="ps-menu-button-test-id"
                        tabIndex={0}
                      >
                        <Link to={"/dashboard/message"}>
                          <span className="ps-menu-label css-12w9als">
                            Message
                          </span>
                        </Link>
                        <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                      </a>
                    </li>
                    {/*
                     <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                      <a
                        className="ps-menu-button"
                        data-testid="ps-menu-button-test-id"
                        tabIndex={0}
                      >
                        <Link to={"/jobview"}>
                          <span className="ps-menu-label css-12w9als">
                            Orders
                          </span>
                        </Link>
                        <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                      </a>
                    </li>
                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                      <a
                        className="ps-menu-button"
                        data-testid="ps-menu-button-test-id"
                        tabIndex={0}
                      >
                        <Link to={"/whoweare"}>
                          <span className="ps-menu-label css-12w9als">
                            Who we are
                          </span>
                        </Link>
                        <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                      </a>
                    </li> */}

                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                      <Link
                        className="ps-menu-button"
                        data-testid="ps-menu-button-test-id"
                        tabIndex={0}
                        to={"/login"}
                      >
                        <span className="ps-menu-label css-12w9als">Login</span>
                        <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                      </Link>
                    </li>
                    {/* <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                      <Link
                        className="ps-menu-button"
                        data-testid="ps-menu-button-test-id"
                        tabIndex={0}
                        to={"/becomeseller"}
                      >
                        <span className="ps-menu-label css-12w9als">
                          Become a freelance
                        </span>
                        <span className="ps-submenu-expand-icon css-1cuxlhl"></span>
                      </Link>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
