/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { useMutation, useQuery } from "@apollo/client";
import { LOGIN_MUTATION } from "../../Graphql/Mutation/Auth/AuthMutation";
import { initialValuesLogin, loginSchema } from "./schema";
import { useFormik } from "formik";
import { GET_USER } from "../../Graphql/Query/AuthQuery";

const LoginSection = () => {

  const navigate = useNavigate();
  const [userLogin, { loading: loginLoading }] =
    useMutation(LOGIN_MUTATION);
    const { refetch } = useQuery(GET_USER);


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValuesLogin,
      validationSchema: loginSchema,
      onSubmit: async(values) => {
        console.log(values);
        const {
          email,
          password,
        } = values;
  
        try {
          const loginResponse = await userLogin({
            variables: {
              email,
              password,
            },
          });
  
          console.log(loginResponse, "loginResponse");
          if (loginResponse && loginResponse.data) {
            localStorage.setItem('authToken',loginResponse.data?.userLogin?.token)
            if(loginResponse.data?.userLogin?.user?.role === 'SELLER'){
              refetch()
            navigate("/dashboard");
            }else{
              refetch()
              navigate("/");
            }
            toast.success("Login SuccessFully");
          } else {
            console.error("Login failed");
          }
        } catch (error) {
          console.log(error, "error");
        }
      },
    });

  return (
    <>
      <div className="body_content mt40" style={{ background: "#FFEDE8" }}>
        <section className="our-login">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="main-title text-center">
                  <h2 className="title">Login</h2>
                  <h3 className="paragraph">Welcome back</h3>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div className="col-xl-6 mx-auto">
                  <div className="log-reg-form search-modal form-style1 bgc-white p40 p30-sm default-box-shadow1 bdrs12">
                    <div className="mb30">
                      <h4>NEW CUSTOMERS ARE WAITING</h4>
                      <p className="text">
                        Don’t have an account? {/* */}
                        <Link className="text-thm" to={"/register"}>
                          Sign up.
                        </Link>
                      </p>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.email && errors.email}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="*******"
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.password && errors.password}
                      </span>
                    </div>
                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz14 ff-heading">
                        Remember me
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked={values.checkbox}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="checkmark" />
                        <br />
                        <span className="error">
                          {touched.checkbox && errors.checkbox}
                        </span> 
                      </label>
                      <Link className="fz14 ff-heading" to='/forgot'>Forgot password?</Link>
                    </div>
                    <div className="d-grid mb10">
                      <button className="ud-btn btn-thm" type="submit">
                      {loginLoading ? (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        ) : (
                          " Log In"
                        )}
                        {/* Log In <i className="fal fa-arrow-right-long" /> */}
                      </button>
                    </div>
                   
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
};

export default LoginSection;
