/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CardThreeEmployee from '../../AllCards/CardThreeEmployee'

const EmployeeSection1 = () => {
  return (
    <>
<div className="body_content">
  <section className="categories_list_section overflow-hidden" style={{marginTop:'8%'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="listings_category_nav_list_menu">
            <ul className="mb0 d-flex ps-0">
              <li>
                <a className="active">All Categories</a>
              </li>
              <li>
                <a className="">Graphics Design</a>
              </li>
              <li>
                <a className="">Digital Marketing</a>
              </li>
              <li>
                <a className="">Writing Translation</a>
              </li>
              <li>
                <a className="">Video Animation</a>
              </li>
              <li>
                <a className="">Music Audio</a>
              </li>
              <li>
                <a className="">Programming Tech</a>
              </li>
              <li>
                <a className="">Business</a>
              </li>
              <li>
                <a className="">Lifestyle</a>
              </li>
              <li>
                <a className="">Trending</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcumb-style1">
            <div className="breadcumb-list">
              <a>Home</a>
              <a>Services</a>
              <a>Design &amp; Creative</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section pt-0">
    <div className="cta-employee-single cta-banner mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative d-flex align-items-center">
      <img
        alt="service"
        loading="lazy"
        width={532}
        height={300}
        decoding="async"
        data-nimg={1}
        className="service-v1-vector at-job bounce-x d-none d-xl-block"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75"
      />
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-xl-7">
            <div className="position-relative">
              <h2>Employer List</h2>
              <p className="text">
                All the Lorem Ipsum generators on the Internet tend to repeat.
              </p>
            </div>
            <div className="advance-search-tab bgc-white p10 bdrs4 mt30">
              <div className="row">
                <div className="col-md-5 col-lg-6 col-xl-6">
                  <div className="advance-search-field bdrr1 bdrn-sm">
                    <form className="form-search position-relative">
                      <div className="box-search">
                        <span className="icon far fa-magnifying-glass" />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="What are you looking for?"
                          name="search"
                          defaultValue=""
                        />
                        <div
                          className="search-suggestions"
                          style={{ visibility: "hidden", opacity: 0, top: 100 }}
                        >
                          <h6 className="fz14 ml30 mt25 mb-3">
                            Popular Search
                          </h6>
                          <div className="box-suggestions">
                            <ul className="px-0 m-0 pb-4">
                              <li className="">
                                <div className="info-product">
                                  <div className="item_title">
                                    mobile app development
                                  </div>
                                </div>
                              </li>
                              <li className="">
                                <div className="info-product">
                                  <div className="item_title">
                                    mobile app builder
                                  </div>
                                </div>
                              </li>
                              <li className="">
                                <div className="info-product">
                                  <div className="item_title">
                                    mobile legends
                                  </div>
                                </div>
                              </li>
                              <li className="">
                                <div className="info-product">
                                  <div className="item_title">
                                    mobile app ui ux design
                                  </div>
                                </div>
                              </li>
                              <li className="">
                                <div className="info-product">
                                  <div className="item_title">
                                    mobile game app development
                                  </div>
                                </div>
                              </li>
                              <li className="">
                                <div className="info-product">
                                  <div className="item_title">
                                    mobile app design
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xl-3">
                  <div className="bselect-style1">
                    <div className="dropdown bootstrap-select">
                      <button
                        type="button"
                        className="btn dropdown-toggle btn-light"
                        data-bs-toggle="dropdown"
                      >
                        <div className="filter-option">
                          <div className="filter-option-inner">
                            <div className="filter-option-inner-inner">
                              City, state, or zip
                            </div>
                          </div>
                        </div>
                      </button>
                      <div className="dropdown-menu">
                        <div className="inner show">
                          <ul className="dropdown-menu inner show">
                            <li className="selected active">
                              <a className="dropdown-item selected">
                                <span className="text">
                                  City, state, or zip
                                </span>
                              </a>
                            </li>
                            <li className="selected active">
                              <a className="dropdown-item selected">
                                <span className="text">Miami</span>
                              </a>
                            </li>
                            <li className="selected active">
                              <a className="dropdown-item selected">
                                <span className="text">New York</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2 col-xl-3">
                  <div className="text-center text-xl-start">
                    <a className="ud-btn btn-thm2 w-100 vam">Search</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="pt30 pb90">
    <div className="container">
      <div className="row align-items-center mb20">
        <div className="col-sm-6 col-lg-9">
          <div className="text-center text-sm-start">
            <div className="dropdown-lists">
              <ul className="p-0 mb-0 text-center text-sm-start">
                <li className="d-block d-xl-none mb-2">
                  <button type="button" className="open-btn filter-btn-left">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={18}
                      height={18}
                      decoding="async"
                      data-nimg={1}
                      className="me-2"
                      style={{ color: "transparent" }}
                      src="images/icon/all-filter-icon.svg"
                    />
                    All Filter
                  </button>
                </li>
                <li className="list-inline-item position-relative d-none d-xl-inline-block">
                  <button
                    className="open-btn mb10 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    Category
                    <i className="fa fa-angle-down ms-2" />
                  </button>
                  <div className="dropdown-menu dd4 pb20">
                    <div className="widget-wrapper pr20">
                      <div className="checkbox-style1">
                        <label className="custom_checkbox">
                          UX Designer
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}1,945{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          Web Developers
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}8,136{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          Illustrators
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}917{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          Node.js
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}240{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          Project Managers
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}2,460{/* */})
                          </span>
                        </label>
                      </div>
                    </div>
                    <button className="done-btn ud-btn btn-thm drop_btn4">
                      Apply
                      <i className="fal fa-arrow-right-long" />
                    </button>
                  </div>
                </li>
                <li className="list-inline-item position-relative d-none d-xl-inline-block">
                  <button
                    className="open-btn mb10 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    No of Employees
                    <i className="fa fa-angle-down ms-2" />
                  </button>
                  <div className="dropdown-menu">
                    <div className="widget-wrapper pb25 mb0">
                      <div className="checkbox-style1">
                        <label className="custom_checkbox">
                          1-10
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}1,945{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          11-20
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}8,136{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          21-30
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}917{/* */})
                          </span>
                        </label>
                        <label className="custom_checkbox">
                          Less then 50
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span className="right-tags">
                            ({/* */}240{/* */})
                          </span>
                        </label>
                      </div>
                    </div>
                    <button className="done-btn ud-btn btn-thm dropdown-toggle">
                      Apply
                      <i className="fal fa-arrow-right-long" />
                    </button>
                  </div>
                </li>
                <li className="list-inline-item position-relative d-none d-xl-inline-block" />
              </ul>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="page_control_shorting mb10 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div className="pcs_dropdown dark-color pr10 pr0-xs text-center">
              <div className="dropdown bootstrap-select show-tick">
                <div className="dropdown-menu">
                  <div className="inner show">
                    <ul className="dropdown-menu inner show">
                      <li>
                        <a className="dropdown-item active selected">
                          <span className="bs-ok-default check-mark" />
                          <span className="text">Best Seller</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item">
                          <span className="bs-ok-default check-mark" />
                          <span className="text">Recommended</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item">
                          <span className="bs-ok-default check-mark" />
                          <span className="text">New Arrivals</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardThreeEmployee />
    </div>
  </section>
  <div className="lefttside-hidden-bar">
    <div className="hsidebar-header bdrb1">
      <h4 className="list-title">All filters</h4>
      <div className="sidebar-close-icon">
        <span className="far fa-times" />
      </div>
    </div>
    <div className="hsidebar-content">
      <div className="widget-wrapper">
        <div className="sidebar-accordion">
          <div className="accordion" id="accordionExample2">
            <div className="card mb20 pb10">
              <div className="card-header" id="headingZero">
                <h4>
                  <button
                    className="btn btn-link ps-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseZero"
                    aria-expanded="false"
                    aria-controls="collapseZero"
                  >
                    Category
                  </button>
                </h4>
              </div>
              <div
                id="collapseZero"
                className="collapse show"
                aria-labelledby="headingZero"
                data-parent="#accordionExample"
              >
                <div className="card-body card-body px-0 pt-0">
                  <div className="checkbox-style1 mb15">
                    <label className="custom_checkbox">
                      UX Designer
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}1,945{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      Web Developers
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}8,136{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      Illustrators
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}917{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      Node.js
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}240{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      Project Managers
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}2,460{/* */})
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb20 pb5">
              <div className="card-header" id="headingThrees">
                <h4>
                  <button
                    className="btn btn-link ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThrees"
                    aria-expanded="true"
                    aria-controls="collapseThrees"
                  >
                    No of Employees
                  </button>
                </h4>
              </div>
              <div
                id="collapseThrees"
                className="collapse show"
                aria-labelledby="headingThrees"
                data-parent="#accordionExample"
              >
                <div className="card-body card-body px-0 pt-0">
                  <div className="checkbox-style1">
                    <label className="custom_checkbox">
                      1-10
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}1,945{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      11-20
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}8,136{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      21-30
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}917{/* */})
                      </span>
                    </label>
                    <label className="custom_checkbox">
                      Less then 50
                      <input type="checkbox" />
                      <span className="checkmark" />
                      <span className="right-tags">
                        ({/* */}240{/* */})
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="hiddenbar-body-ovelay" />
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default EmployeeSection1


