import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import ManageProjectSection1 from "../../Components/Dashboard/ManageProject/ManageProjectSection1";

const ManageProject = () => {
  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <ManageProjectSection1 />
        </div>
      </Layout>
    </>
  );
};

export default ManageProject;
