import React from 'react'
import ProSignup from '../../Components/ProAuth/ProSignup'
import Layout from '../../Layout/Layout'

const ProSignupPage = () => {
  return (
    <>
      <Layout>
        <ProSignup />
      </Layout>
    </>
  )
}

export default ProSignupPage