import React from 'react'
import Skeleton from "react-loading-skeleton";

const CardSkeleton = () => {
  return (
    <div
    className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 grid-item cat4 mb-5"
  >
    <div className="course__item white-bg mb-30 fix">
      <div className="course__thumb w-img p-relative fix">
         <Skeleton width={350}  height={250}/> 

      
      </div>
      <div >
        <div className="d-flex align-items-center justify-content-between">
          <div className="course__lesson">
             <Skeleton  width={300}  height={30}/>

          </div>
        </div>
        <p
          className="py-2"
          style={{ textAlign: "left" }}
        >
         <Skeleton  width={330}  height={20}/> 
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center flex-1">
          <div><Skeleton width={40}  height={40} borderRadius={'50px'}/>
          </div>
          <div style={{marginLeft:'5px'}}>
          <Skeleton  width={70}  height={40}/>
          </div>
        </div>
        <div className="">
         <Skeleton  width={120}  height={40}/>
        </div>
      </div>
    </div>
  </div>  )
}

export default CardSkeleton