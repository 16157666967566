/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Layout from '../../../Layout/Layout'


const NotFound404 = () => {
  return (
    <>
    <Layout>
<div className="body_content">
  <section className="our-error">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 wow fadeInRight" data-wow-delay="300ms">
          <div className="animate_content text-center text-xl-start">
            <div className="animate_thumb">
              <img
                alt="error-page-img"
                loading="lazy"
                width={500}
                height={500}
                decoding="async"
                data-nimg={1}
                className="w-100"
                style={{ color: "transparent" }}
                src="images/icon/error-page-img.svg"
              />
            </div>
          </div>
        </div>
        <div
          className="col-xl-5 offset-xl-1 wow fadeInLeft"
          data-wow-delay="300ms"
        >
          <div className="error_page_content text-center text-xl-start">
            <div className="erro_code">
              40<span className="text-thm">4</span>
            </div>
            <div className="h2 error_title">
              Oops! It looks like you're lost.
            </div>
            <p className="text fz15 mb20">
              The page you're looking for isn't available. Try to search again
              or use the go
              <br className="d-none d-lg-block" />
              to.
            </p>
            <a className="ud-btn btn-thm" href="/">
              Go back to home
              <i className="fal fa-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</Layout>
    </>
  )
}

export default NotFound404

