import React from "react";
import Layout from "../../Layout/Layout";
import Banner from "./Banner";
// import Section1 from "./Section1";
import Section2 from "./Section2";
// import Section3 from "./Section3";

const Index = () => {
  return (
    <>
      <Layout>
        <div class="body_content">
          <Banner />
          {/* <Section1 /> */}
          <Section2 />
          {/* <Section3 /> */}
          {/* <Section4 /> */}
        </div>
      </Layout>
    </>
  );
};

export default Index;
