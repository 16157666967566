/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function ServiceSection2() {
  const handlerCollapseTwo = () => {
    document.getElementById("collapseTwo").classList.toggle("show");
  };
  const handlerCollapseThree = () => {
    document.getElementById("collapseThree").classList.toggle("show");
  };
  const handlerCollapseFour = () => {
    document.getElementById("collapseFour").classList.toggle("show");
  };
  const handlerCollapseFive = () => {
    document.getElementById("collapseFive").classList.toggle("show");
  };

  return (
    <>
      <div className="body_content">
        <section
          className="categories_list_section overflow-hidden"
          style={{ marginTop: "8%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="listings_category_nav_list_menu">
                  <ul className="mb0 d-flex ps-0">
                    <li>
                      <a className="active">All Categories</a>
                    </li>
                    <li>
                      <a className="">Graphics Design</a>
                    </li>
                    <li>
                      <a className="">Digital Marketing</a>
                    </li>
                    <li>
                      <a className="">Writing Translation</a>
                    </li>
                    <li>
                      <a className="">Video Animation</a>
                    </li>
                    <li>
                      <a className="">Music Audio</a>
                    </li>
                    <li>
                      <a className="">Programming Tech</a>
                    </li>
                    <li>
                      <a className="">Business</a>
                    </li>
                    <li>
                      <a className="">Lifestyle</a>
                    </li>
                    <li>
                      <a className="">Trending</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="breadcumb-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <div className="breadcumb-list">
                    <a>Home</a>
                    <a>Services</a>
                    <a>Design &amp; Creative</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="breadcumb-section pt-0">
          <div className="cta-service-single cta-banner mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
            <img
              alt="left-top"
              loading="lazy"
              width={198}
              height={226}
              decoding="async"
              data-nimg={1}
              className="left-top-img wow zoomIn"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75
        "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75"
            />
            <img
              alt="right-bottom"
              loading="lazy"
              width={255}
              height={181}
              decoding="async"
              data-nimg={1}
              className="right-bottom-img wow zoomIn"
              style={{ color: "transparent" }}
              srcSet="
          /_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75 1x,
          /_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=640&q=75 2x
        "
              src="_next/right-bottomebb3.png?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=640&q=75"
            />
            <img
              alt="vector-service"
              loading="lazy"
              width={532}
              height={300}
              decoding="async"
              data-nimg={1}
              className="service-v1-vector bounce-y d-none d-xl-block"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75
        "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75"
            />
            <div className="container">
              <div className="row wow fadeInUp">
                <div className="col-xl-7">
                  <div className="position-relative">
                    <h2>I will design website UI UX in adobe xd or figma</h2>
                    <div className="list-meta mt30">
                      <a className="list-inline-item mb5-sm">
                        <span className="position-relative mr10">
                          <img
                            alt="Freelancer Photo"
                            loading="lazy"
                            width={40}
                            height={40}
                            decoding="async"
                            data-nimg={1}
                            className="rounded-circle"
                            style={{ color: "transparent" }}
                            srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-d-1.png&w=48&q=75
                      "
                            src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-d-1.png&w=48&q=75"
                          />
                          <span className="online-badge" />
                        </span>
                        <span className="fz14">Eleanor Pena</span>
                      </a>
                      <p className="mb-0 dark-color fz14 list-inline-item ml25 ml15-sm mb5-sm ml0-xs">
                        <i className="fas fa-star vam fz10 review-color me-2" />
                        4.82 94 reviews
                      </p>
                      <p className="mb-0 dark-color fz14 list-inline-item ml25 ml15-sm mb5-sm ml0-xs">
                        <i className="flaticon-file-1 vam fz20 me-2" />2 Order
                        in Queue
                      </p>
                      <p className="mb-0 dark-color fz14 list-inline-item ml25 ml15-sm mb5-sm ml0-xs">
                        <i className="flaticon-website vam fz20 me-2" />
                        902 Views
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <section className="pt10 pb90 pb30-md">
            <div className="container">
              <div className="row wrap">
                <div className="col-lg-8">
                  <div className="column">
                    <div className="scrollbalance-inner">
                      <div className="row">
                        <div className="col-sm-6 col-md-4">
                          <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                            <div className="icon flex-shrink-0">
                              <span className="flaticon-calendar" />
                            </div>
                            <div className="details">
                              <h5 className="title">Delivery Time</h5>
                              <p className="mb-0 text">1-3 Days</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                            <div className="icon flex-shrink-0">
                              <span className="flaticon-goal" />
                            </div>
                            <div className="details">
                              <h5 className="title">English Level</h5>
                              <p className="mb-0 text">Professional</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                            <div className="icon flex-shrink-0">
                              <span className="flaticon-tracking" />
                            </div>
                            <div className="details">
                              <h5 className="title">Location</h5>
                              <p className="mb-0 text">New York</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="service-single-sldier vam_nav_style slider-1-grid owl-carousel owl-theme mb60 owl-loaded owl-drag">
                        <div className="thumb p50 p30-sm" />
                        <button type="button" className="prev-btn">
                          <i className="far fa-arrow-left-long" />
                        </button>
                        <button type="button" className="next-btn">
                          <i className="far fa-arrow-right-long" />
                        </button>
                      </div>

                    </div>
                    <div className="service-about">
                      <h4>About</h4>
                      <p className="text mb30">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English.
                      </p>
                      <p className="text mb-0">Services I provide:</p>
                      <p className="text mb-0">1) Website Design</p>
                      <p className="text mb-0">2) Mobile App Design</p>
                      <p className="text mb-0">3) Brochure Design</p>
                      <p className="text mb-0">4) Business Card Design</p>
                      <p className="text mb30">5) Flyer Design</p>
                      <p className="text mb30">
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum as their default model text, and a
                        search for 'lorem ipsum' will uncover many web sites
                        still in their infancy. Various versions have evolved
                        over the years, sometimes by accident, sometimes on
                        purpose (injected humour and the like).
                      </p>
                      <div className="d-flex align-items-start mb50">
                        <div className="list1">
                          <h6>App type</h6>
                          <p className="text mb-0">
                            Business, Food &amp; drink,
                          </p>
                          <p className="text">Graphics &amp; design</p>
                        </div>
                        <div className="list1 ml80">
                          <h6>Design tool</h6>
                          <p className="text mb-0">Adobe XD, Figma,</p>
                          <p className="text">Adobe Photoshop</p>
                        </div>
                        <div className="list1 ml80">
                          <h6>Device</h6>
                          <p className="text">Mobile, Desktop</p>
                        </div>
                      </div>
                      <hr className="opacity-100 mb60" />
                      <h4>Compare Packages</h4>
                      <div className="table-style2 table-responsive bdr1 mt30 mb60">
                        <table className="table table-borderless mb-0">
                          <thead className="t-head">
                            <tr>
                              <th className="col" scope="col" />
                              <th className="col" scope="col">
                                <span className="h2">
                                  $29 <small>/ monthly</small>
                                </span>
                                <br />
                                <span className="h4">Basic</span>
                                <br />
                                <span className="text">
                                  I will redesign your current{/* */}
                                  <br className="d-none d-lg-block" />
                                  landing page or create one for{/* */}
                                  <br className="d-none d-lg-block" />
                                  you (upto 4 sections)
                                </span>
                              </th>
                              <th className="col" scope="col">
                                <span className="h2">
                                  $49 <small>/ monthly</small>
                                </span>
                                <br />
                                <span className="h4">Standard</span>
                                <br />
                                <span className="text">
                                  4 High Quality Desktop{/* */}
                                  <br className="d-none d-lg-block" />
                                  Pages.
                                </span>
                              </th>
                              <th className="col" scope="col">
                                <span className="h2">
                                  $89 <small>/ monthly</small>
                                </span>
                                <br />
                                <span className="h4">Premium</span>
                                <br />
                                <span className="text">
                                  4 High Quality Desktop and{/* */}
                                  <br className="d-none d-lg-block" />
                                  Mobile Pages.
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="t-body">
                            <tr className="bgc-thm3">
                              <th scope="row">Source file</th>
                              <td>
                                <a className="check_circle bgc-thm">
                                  <span className="fas fa-check" />
                                </a>
                              </td>
                              <td>
                                <a className="check_circle bgc-thm">
                                  <span className="fas fa-check" />
                                </a>
                              </td>
                              <td>
                                <a className="check_circle bgc-thm">
                                  <span className="fas fa-check" />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Number of pages</th>
                              <td>2</td>
                              <td>4</td>
                              <td>6</td>
                            </tr>
                            <tr className="bgc-thm3">
                              <th scope="row">Revisions</th>
                              <td>1</td>
                              <td>3</td>
                              <td>5</td>
                            </tr>
                            <tr>
                              <th scope="row">Delivery Time</th>
                              <td>2 Days</td>
                              <td>3 Days</td>
                              <td>4 Days</td>
                            </tr>
                            <tr className="bgc-thm3">
                              <th scope="row">Total</th>
                              <td>$29</td>
                              <td>$49</td>
                              <td>$89</td>
                            </tr>
                            <tr>
                              <th scope="row" />
                              <td>
                                <a className="ud-btn btn-thm">
                                  Select
                                  <i className="fal fa-arrow-right-long" />
                                </a>
                              </td>
                              <td>
                                <a className="ud-btn btn-thm">
                                  Select
                                  <i className="fal fa-arrow-right-long" />
                                </a>
                              </td>
                              <td>
                                <a className="ud-btn btn-thm">
                                  Select
                                  <i className="fal fa-arrow-right-long" />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr className="opacity-100 mb60" />
                      <h4>Frequently Asked Questions</h4>
                      <div className="accordion-style1 faq-page mb-4 mb-lg-5 mt30">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                onClick={handlerCollapseTwo}
                              >
                                Can I cancel at anytime?
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                Cras vitae ac nunc orci. Purus amet tortor non
                                at phasellus ultricies hendrerit. Eget a, sit
                                morbi nunc sit id massa. Metus, scelerisque
                                volutpat nec sit vel donec. Sagittis, id
                                volutpat erat vel.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                                onClick={handlerCollapseThree}
                              >
                                How do I get a receipt for my purchase?
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                Cras vitae ac nunc orci. Purus amet tortor non
                                at phasellus ultricies hendrerit. Eget a, sit
                                morbi nunc sit id massa. Metus, scelerisque
                                volutpat nec sit vel donec. Sagittis, id
                                volutpat erat vel.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                                onClick={handlerCollapseFour}
                              >
                                Which license do I need?
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFour"
                              data-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                Cras vitae ac nunc orci. Purus amet tortor non
                                at phasellus ultricies hendrerit. Eget a, sit
                                morbi nunc sit id massa. Metus, scelerisque
                                volutpat nec sit vel donec. Sagittis, id
                                volutpat erat vel.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                                onClick={handlerCollapseFive}
                              >
                                How do I get access to a theme I purchased?
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFive"
                              data-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                Cras vitae ac nunc orci. Purus amet tortor non
                                at phasellus ultricies hendrerit. Eget a, sit
                                morbi nunc sit id massa. Metus, scelerisque
                                volutpat nec sit vel donec. Sagittis, id
                                volutpat erat vel.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="opacity-100 mb60" />
                      <h4>Add Extra Services</h4>
                      <div className="extra-service-tab mb40 mt30">
                        <nav>
                          <div className="nav flex-column nav-tabs">
                            <button className="nav-link">
                              <label className="custom_checkbox fw500 text-start">
                                100 Words (+2 days)
                                <span className="text text-bottom">
                                  I will professionally translate english to
                                  german
                                </span>
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <span className="price">${/* */}25</span>
                            </button>
                            <button className="nav-link">
                              <label className="custom_checkbox fw500 text-start">
                                100 Words (+2 days)
                                <span className="text text-bottom">
                                  I will professionally translate english to
                                  german
                                </span>
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <span className="price">${/* */}45</span>
                            </button>
                            <button className="nav-link">
                              <label className="custom_checkbox fw500 text-start">
                                100 Words (+2 days)
                                <span className="text text-bottom">
                                  I will professionally translate english to
                                  german
                                </span>
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                              <span className="price">${/* */}75</span>
                            </button>
                          </div>
                        </nav>
                      </div>
                      <hr className="opacity-100 mb15" />
                      <div className="product_single_content mb50">
                        <div className="mbp_pagination_comments">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="total_review mb30 mt45">
                                <h4>80 Reviews</h4>
                              </div>
                              <div className="d-md-flex align-items-center mb30">
                                <div className="total-review-box d-flex align-items-center text-center mb30-sm">
                                  <div className="wrapper mx-auto">
                                    <div className="t-review mb15">4.96</div>
                                    <h5>Exceptional</h5>
                                    <p className="text mb-0">3,014 reviews</p>
                                  </div>
                                </div>
                                <div className="wrapper ml60 ml0-sm">
                                  <div className="review-list d-flex align-items-center mb10">
                                    <div className="list-number">5 Star</div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{ width: "90%" }}
                                        aria-valuenow={90}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <div className="value text-end">58</div>
                                  </div>
                                  <div className="review-list d-flex align-items-center mb10">
                                    <div className="list-number">4 Star</div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar w-75"
                                        aria-valuenow={75}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <div className="value text-end">20</div>
                                  </div>
                                  <div className="review-list d-flex align-items-center mb10">
                                    <div className="list-number">3 Star</div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar w-50"
                                        aria-valuenow={50}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <div className="value text-end">15</div>
                                  </div>
                                  <div className="review-list d-flex align-items-center mb10">
                                    <div className="list-number">2 Star</div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{ width: "30%" }}
                                        aria-valuenow={30}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <div className="value text-end">2</div>
                                  </div>
                                  <div className="review-list d-flex align-items-center mb10">
                                    <div className="list-number">1 Star</div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{ width: "20%" }}
                                        aria-valuenow={10}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <div className="value text-end">1</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mbp_first position-relative d-flex align-items-center justify-content-start mb30-sm">
                                <img
                                  alt="comments-2.png"
                                  loading="lazy"
                                  width={60}
                                  height={60}
                                  decoding="async"
                                  data-nimg={1}
                                  className="mr-3"
                                  style={{ color: "transparent" }}
                                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                            "
                                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                                />
                                <div className="ml20">
                                  <h6 className="mt-0 mb-0">Bessie Cooper</h6>
                                  <div>
                                    <span className="fz14">12 March 2022</span>
                                  </div>
                                </div>
                              </div>
                              <p className="text mt20 mb20">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form, by injected humour, or
                                randomised words which don't look even slightly
                                believable. If you are going to use a passage of
                                Lorem Ipsum, you need to be sure there isn't
                                anything embarrassing hidden in the middle of
                                text.
                              </p>
                              <div className="review_cansel_btns d-flex">
                                <a>
                                  <i className="fas fa-thumbs-up" />
                                  Helpful
                                </a>
                                <a>
                                  <i className="fas fa-thumbs-down" />
                                  Not helpful
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mbp_first position-relative d-flex align-items-center justify-content-start mt30 mb30-sm">
                                <img
                                  alt="comments-2.png"
                                  loading="lazy"
                                  width={60}
                                  height={60}
                                  decoding="async"
                                  data-nimg={1}
                                  className="mr-3"
                                  style={{ color: "transparent" }}
                                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                            "
                                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                                />
                                <div className="ml20">
                                  <h6 className="mt-0 mb-0">Darrell Steward</h6>
                                  <div>
                                    <span className="fz14">12 March 2022</span>
                                  </div>
                                </div>
                              </div>
                              <p className="text mt20 mb20">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form, by injected humour, or
                                randomised words which don't look even slightly
                                believable. If you are going to use a passage of
                                Lorem Ipsum, you need to be sure there isn't
                                anything embarrassing hidden in the middle of
                                text.
                              </p>
                              <div className="review_cansel_btns d-flex pb30">
                                <a>
                                  <i className="fas fa-thumbs-up" />
                                  Helpful
                                </a>
                                <a>
                                  <i className="fas fa-thumbs-down" />
                                  Not helpful
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="position-relative bdrb1 pb50">
                                <a className="ud-btn btn-light-thm" href="/">
                                  See More
                                  <i className="fal fa-arrow-right-long" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bsp_reveiw_wrt mb20">
                        <h6 className="fz17">Add a Review</h6>
                        <p className="text">
                          Your email address will not be published. Required
                          fields are marked *
                        </p>
                        <h6>Your rating of this product</h6>
                        <div className="d-flex">
                          <i className="fas fa-star review-color" />
                          <i className="far fa-star review-color ms-2" />
                          <i className="far fa-star review-color ms-2" />
                          <i className="far fa-star review-color ms-2" />
                          <i className="far fa-star review-color ms-2" />
                        </div>
                        <form className="comments_form mt30 mb30-md">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label className="fw500 fz16 ff-heading dark-color mb-2">
                                  Comment
                                </label>
                                <textarea
                                  className="pt15"
                                  rows={6}
                                  placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb20">
                                <label className="fw500 ff-heading dark-color mb-2">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ali Tufan"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb20">
                                <label className="fw500 ff-heading dark-color mb-2">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="creativelayers088"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                                <label className="custom_checkbox fz15 ff-heading">
                                  Save my name, email, and website in this
                                  browser for the next time I comment.
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <a className="ud-btn btn-thm">
                                Send
                                <i className="fal fa-arrow-right-long" />
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="column">
                    <div className="scrollbalance-inner">
                      <div className="blog-sidebar ms-lg-auto">
                        <div className="price-widget">
                          <div className="navtab-style1">
                            <nav>
                              <div className="nav nav-tabs mb20">
                                <button className="nav-link fw500 active">
                                  Basic
                                </button>
                                <button className="nav-link fw500">
                                  Standart
                                </button>
                                <button className="nav-link fw500">
                                  Premium
                                </button>
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                              <div className="price-content">
                                <div className="price">$50</div>
                                <div className="h5 mb-2">
                                  High-converting Landing Pages
                                </div>
                                <p className="text fz14">
                                  I will redesign your current landing page or
                                  create one for you (upto 4 sections)
                                </p>
                                <hr className="opacity-100 mb20" />
                                <ul className="p-0 mb15 d-sm-flex align-items-center">
                                  <li className="fz14 fw500 dark-color">
                                    <i className="flaticon-sandclock fz20 text-thm2 me-2 vam" />
                                    3 Days Delivery
                                  </li>
                                  <li className="fz14 fw500 dark-color ml20 ml0-xs">
                                    <i className="flaticon-recycle fz20 text-thm2 me-2 vam" />
                                    2 Revisions
                                  </li>
                                </ul>
                                <div className="list-style1">
                                  <ul>
                                    <li className="mb15">
                                      <i className="far fa-check text-thm3 bgc-thm3-light" />
                                      2 Page / Screen
                                    </li>
                                    <li>
                                      <i className="far fa-check text-thm3 bgc-thm3-light" />
                                      Source file
                                    </li>
                                  </ul>
                                </div>
                                <div className="d-grid">
                                  <a className="ud-btn btn-thm">
                                    Continue $50
                                    <i className="fal fa-arrow-right-long" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="freelancer-style1 service-single mb-0">
                          <div className="wrapper d-flex align-items-center">
                            <div className="thumb position-relative mb25">
                              <img
                                alt="rounded-circle"
                                loading="lazy"
                                width={90}
                                height={90}
                                decoding="async"
                                data-nimg={1}
                                className="rounded-circle mx-auto"
                                style={{ color: "transparent" }}
                                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75
                          "
                                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75"
                              />
                              <span className="online" />
                            </div>
                            <div className="ml20">
                              <h5 className="title mb-1">Kristin Watson</h5>
                              <p className="mb-0">Dog Trainer</p>
                              <div className="review">
                                <p>
                                  <i className="fas fa-star fz10 review-color pr10" />
                                  <span className="dark-color">4.9</span> (595
                                  reviews)
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr className="opacity-100" />
                          <div className="details">
                            <div className="fl-meta d-flex align-items-center justify-content-between">
                              <a className="meta fw500 text-start">
                                Location
                                <br />
                                <span className="fz14 fw400">London</span>
                              </a>
                              <a className="meta fw500 text-start">
                                Rate
                                <br />
                                <span className="fz14 fw400">$90 / hr</span>
                              </a>
                              <a className="meta fw500 text-start">
                                Job Success
                                <br />
                                <span className="fz14 fw400">%98</span>
                              </a>
                            </div>
                          </div>
                          <div className="d-grid mt30">
                            <a className="ud-btn btn-thm-border" href="/">
                              Contact Me
                              <i className="fal fa-arrow-right-long" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
}

export default ServiceSection2;
