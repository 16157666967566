import React from 'react'
import Layout from '../../../Layout/Layout'
import PricingSection1 from '../../../Components/Pages/Pricing/PricingSection1'

const Pricing = () => {
  return (
    <>
       <Layout>
        <PricingSection1 />
       </Layout>
    </>
  )
}

export default Pricing