import React from 'react'
import EmployeeSectionSingle1 from '../../../Components/Dashboard/Employee/EmployeeSectionSingle1'
import Layout from '../../../Layout/Layout'

const EmployeeSingle = () => {
  return (
    <>
    <Layout>
       <EmployeeSectionSingle1 />
    </Layout>
    </>
  )
}

export default EmployeeSingle