import { gql } from "@apollo/client";

export const GET_SERVICES_CATEGORY = gql`
query servicesCategories($serviceId: Int!) {
    servicesCategories(serviceId: $serviceId) {
        service_categories {
            name
            id
          }
    }
  }
`;