/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import Services from "./Services";
import CardOneServiceAndSave from '../AllCards/CardOneServiceAndSave'

const Section2 = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="pb-0 bgc-thm3">
        <div className="container">
          <div className="row align-items-center wow fadeInUp">
            <div className="col-lg-12">
              <div class="section-header text-center">
                <h2 class="fw-bold fs-1">
                  START <span class="b-class-secondary"> SEARCH </span>
                </h2>
                <h4> Choose a Category</h4>
                <h5 className="mb-5">
                  Tell us about your project and we’ll show you a free lancer
                  for the job.
                </h5>
              </div>
              <div className="col-lg-12 mb-5">
                <div className="advance-search-tab bgc-white bgct-sm p10 p0-md bdrs4 banner-btn position-relative zi9 animate-up-4" style={{zIndex: 1}}>
                  <div className="row">
                    <div className="col-md-5 col-lg-6 col-xl-6">
                      <div className="advance-search-field mb10-sm bdrr1 bdrn-sm">
                        <form className="form-search position-relative">
                          <div className="box-search">
                            <span className="icon far fa-magnifying-glass" />
                            <input
                              className="form-control"
                              type="text"
                              placeholder="What are you looking for?"
                              name="search"
                              defaultValue=""
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-xl-4 d-md-block">
                      <div className="advance-search-field mb10-sm bdrr1 bdrn-sm">
                        <form className="form-search position-relative">
                          <select
                            class="form-select py-md-auto py-3"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Category
                            </option>
                            <option value="1">Category One</option>
                            <option value="2">Category Two</option>
                            <option value="3">Category Three</option>
                            <option value="3">Category Four</option>
                            <option value="3">Category Five</option>
                          </select>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 ps-md-0">
                      <div className="text-center text-xl-end">
                        <button
                          className="ud-btn btn-thm w-100 px-4"
                          type="button"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb40-md pb90"> 
        <div className="container">
          <div className="row align-items-center wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-9">
              <div className="main-title2">
                <h2 className="title">Trending Services</h2>
              </div>
            </div>
          </div>
          <div className="row d-none d-lg-flex wow fadeInUp gap-0 row-gap-3">
            <CardOneServiceAndSave/>
          </div>

          
        </div>
      </section > 

      {/* <section className='p-0'>
        <div className='container mt-4'>
          <div className="row">
            <h2 className='text-center'>Why Hire Professionals On YaSSoh?</h2>
            <div className="col-sm-6 col-xl-4 cursor-pointer">
              <div className="listing-style1 text-start border-0" style={{ height: 'auto', background: 'none' }}>
                <div className="list-thumb">
                </div>
                <div className="list-content mt-4">
                  <FaPlus size={40} color='#5bbb7b' />
                  <h2 className="list-text body-color fs-5 fz14">
                    Free to use
                  </h2>
                  <h4 className="list-title fw-normal">
                    You never pay to use yaSSoh: Get
                    cost estimates, contact pros, and
                    even book the job—all for no cost
                  </h4>
                </div>
              </div>

            </div>
            <div className="col-sm-6 col-xl-4 cursor-pointer">
              <div className="listing-style1 text-start border-0" style={{ height: 'auto', background: 'none' }}>
                <div className="list-thumb">
                </div>
                <div className="list-content mt-4">
                  <FaPlus size={40} color='#5bbb7b' />
                  <h2 className="list-text body-color fs-5 fz14">
                    Free to use
                  </h2>
                  <h4 className="list-title fw-normal">
                    You never pay to use yaSSoh: Get
                    cost estimates, contact pros, and
                    even book the job—all for no cost
                  </h4>
                </div>
              </div>

            </div>
            <div className="col-sm-6 col-xl-4 cursor-pointer">
              <div className="listing-style1 text-start border-0" style={{ height: 'auto', background: 'none' }}>
                <div className="list-thumb">
                </div>
                <div className="list-content mt-4">
                  <FaPlus size={40} color='#5bbb7b' />
                  <h2 className="list-text body-color fs-5 fz14">
                    Free to use
                  </h2>
                  <h4 className="list-title fw-normal">
                    You never pay to use yaSSoh: Get
                    cost estimates, contact pros, and
                    even book the job—all for no cost
                  </h4>
                </div>
              </div>

            </div>
            <h5 className='fs-5 text-center cursor-pointer'>
              <span onClick={() => navigate('/login')}>
                Don’t have an account? Sign up.
              </span>
              <span className='ms-4' onClick={() => navigate('/login')}>Join As A Pro</span></h5>
          </div>
        </div>
      </section> */}

      <section className="our-features pb90">
        <div className="container wow fadeInUp">
          <section id="advertisers" class="advertisers-service-sec pt-0 pb-5">
            <div class="container">
              <div class="row">
                <div class="section-header text-center">
                  <h2 class="fw-bold fs-1">
                    How <span class="b-class-secondary"> LukBout </span>Works
                  </h2>
                  <h4>SEARCH. CHAT. HIRE</h4>
                  <h5 className="mb-5">
                     Whatever work you do …… we find you the jobs you want.
                  </h5>
                </div>
              </div>
              <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
                <div class="col">
                  <div class="service-card">
                    <div class="icon-wrapper">
                      <span className="flaticon-cv" id="iconColor" />
                    </div>
                    <h3>SEARCH</h3>
                    <h6>Find trusted and reliable professional freelancers.</h6>
                    <p>
                      Get the job done right. Whether you need a social media
                      manager, content creator, resume writer, plumber,
                      electrician o handyman, or any other freelancer, we have
                      got you covered.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                    <div class="icon-wrapper">
                      <span className="flaticon-web-design" />
                    </div>
                    <h3>CHAT</h3>
                    <h6>
                      Connect with freelancer through our user-friendly chat
                      platform.
                    </h6>
                    <p>
                      With our easy-to-use chat platform, you can directly
                      connect with freelancer to discuss your requirements, ask
                      questions, and get instant updates on the progress of your
                      project. Our chat feature ensures efficient and
                      transparent communication between freelancer and
                      contractors.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                    <div class="icon-wrapper">
                      <span className="flaticon-secure" />
                    </div>
                    <h3>HIRE</h3>
                    <h6>Hundreds of customer reviews</h6>
                    <p>
                      We understand the importance of trust when it comes to
                      hiring a freelance professional. Every day, local and
                      overseas customers have submitted over 500 hundred reviews
                      for high-quality work the freelancers they hired on
                      LukBout. Whenever you’re ready, you can book them, you are
                      in good hands
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <div className="row mt-4 advertisers-service-sec" id="advertisers">
              <h2 className="text-center">Top Services in Jamaica</h2>
              <div className="col-lg-9 col-md-6 mt-4">
                <div className="row">
                  <Services />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-5 px-4">
                <h4 className="fw-bold fs-4">Get things done.</h4>
                <h5 className="mt-4">SEARCH. CHAT. HIRE</h5>
                <div className="app-widget mb-4 mb-sm-5">
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <h5>Get the yaSSoh Mobile App</h5>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <a className="app-list d-flex align-items-center mb10">
                        <i className="fab fa-apple fz17 mr15 text-black fs-1" />
                        <h6 className="app-title fz15 fw400 mb-0 text-black fs-6">
                          iOS App
                        </h6>
                      </a>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <a className="app-list d-flex align-items-center">
                        <i className="fab fa-google-play fz15 mr15 text-black fs-1" />
                        <h6 className="app-title fz15 fw400 mb-0 text-black fs-6">
                          Android App
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-features pb90 pt-0">
        <div className="container wow fadeInUp">
          <section id="advertisers" class="advertisers-service-sec pt-0 pb-0">
            <div class="container">
              <div class="row">
                <div class="section-header text-center">
                  <h2 class="fw-bold fs-1">
                    Become a<span class="b-class-secondary"> Freelancer </span>
                    on LukBout
                  </h2>
                  <h4> CONNECT. ENGGE. GROW</h4>
                  <h5 className="mb-5">
                     Join other successful frelancers. Start getting new jobs
                    worldwide
                  </h5>
                </div>
              </div>
              <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
                <div class="col">
                  <div class="service-card">
                    <div class="icon-wrapper">
                      <span className="flaticon-cv" id="iconColor" />
                    </div>
                    <h3>Get customers</h3>
                    <p>
                      Manage your jobs from one place Get more positive reviews
                      & showcase them Boost your sales Provide an easy and
                      professional customer experience. Communicate with
                      customers in one place.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                    <div class="icon-wrapper">
                      <span className="flaticon-web-design" />
                    </div>
                    <h3>Get Paid Easy</h3>
                    <p>
                      Your Customers pay invoices online using any major credit
                      card. Jamaica, US, Canada, and UK access and you determine
                      your payout.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                    <div class="icon-wrapper">
                      <span className="flaticon-secure" />
                    </div>
                    <h3>Manage Your Job</h3>
                    <p>
                      Keep things organized as you control your schedule which
                      means you get paid faster.
                    </p>
                  </div>
                </div>
              </div>
              <h4
                className="text-center cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Become a yaSSoh Freelancer. Sign Up
              </h4>
            </div>
          </section>
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-9 col-md-6 mt-4">
                <div className="row">
                  <div className="row">
                    <Services />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-5 px-4">
                <h4 className="fw-bold fs-4">Get Projects Started</h4>
                <h5 className="mt-4">CONNECT. ENGAGE. GROW</h5>
                <div className="app-widget mb-4 mb-sm-5">
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <h5>Get the yaSSoh Free Mobile App</h5>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <a className="app-list d-flex align-items-center mb10">
                        <i className="fab fa-apple fz17 mr15 text-black fs-1" />
                        <h6 className="app-title fz15 fw400 mb-0 text-black fs-6">
                          iOS App
                        </h6>
                      </a>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <a className="app-list d-flex align-items-center">
                        <i className="fab fa-google-play fz15 mr15 text-black fs-1" />
                        <h6 className="app-title fz15 fw400 mb-0 text-black fs-6">
                          Android App
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
