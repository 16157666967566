/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";

const CardSixInovice = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const [card, setCard] = useState([]);
  const allJobsCombined = [...card];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const AllCardData = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/products");
      setCard(response?.data?.products);
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllCardData();
  }, []);
  return (
    <>
      <table className="table-style3 table at-savesearch">
        <thead className="t-head">
          <tr>
            <th scope="col">Invoice ID</th>
            <th scope="col">Purchase Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody className="t-body">
          {currentJobs?.map((item) => (
            <tr>
              <th scope="row">
                <div>
                  #{/* */}99{/* */}
                  <span className="ms-3">App Services</span>
                </div>
              </th>
              <td className="vam">April 9, 2023</td>
              <td className="vam">${/* */}1.200</td>
              <td className="vam">
                <span className="pending-style style6">In Progress</span>
              </td>
              <td className="vam">
                <a className="table-action fz15 fw500 text-thm2" id="view">
                  <span className="flaticon-website me-2 vam" />
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CardSixInovice;
