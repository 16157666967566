import { gql } from "@apollo/client";

export const GET_Wallet = gql`
query SellerWallet {
    sellerWallet {
      availble_amount
      pending_amount
      total_credit
      total_debit
    }
  }
`