/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const ProjectSection2 = () => {
  return (
    <>
<div className="body_content">
  <section className="categories_list_section overflow-hidden" style={{marginTop:'8%'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="listings_category_nav_list_menu">
            <ul className="mb0 d-flex ps-0">
              <li>
                <a className="active">All Categories</a>
              </li>
              <li>
                <a className="">Graphics Design</a>
              </li>
              <li>
                <a className="">Digital Marketing</a>
              </li>
              <li>
                <a className="">Writing Translation</a>
              </li>
              <li>
                <a className="">Video Animation</a>
              </li>
              <li>
                <a className="">Music Audio</a>
              </li>
              <li>
                <a className="">Programming Tech</a>
              </li>
              <li>
                <a className="">Business</a>
              </li>
              <li>
                <a className="">Lifestyle</a>
              </li>
              <li>
                <a className="">Trending</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8 col-lg-10">
          <div className="breadcumb-style1 mb10-xs">
            <div className="breadcumb-list">
              <a>Home</a>
              <a>Services</a>
              <a>Design &amp; Creative</a>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-2">
          <div className="d-flex align-items-center justify-content-sm-end">
            <a className="position-relative ui-share-toggle">
              <div className="share-save-widget d-flex align-items-center">
                <span className="icon flaticon-share dark-color fz12 mr10" />
                <div className="h6 mb-0">Share</div>
              </div>
            </a>
            <a>
              <div className="share-save-widget d-flex align-items-center ml15">
                <span className="icon flaticon-like dark-color fz12 mr10" />
                <div className="h6 mb-0">Save</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section pt-0">
    <div className="cta-service-v1 freelancer-single-style mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
      <img
        alt="right-bottom"
        loading="lazy"
        width={255}
        height={181}
        decoding="async"
        data-nimg={1}
        className="right-bottom-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="
          /_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75 1x,
          /_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=640&q=75 2x
        "
        src="_next/right-bottomebb3.png?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=640&q=75"
      />
      <img
        alt="vector-service"
        loading="lazy"
        width={532}
        height={300}
        decoding="async"
        data-nimg={1}
        className="service-v1-vector bounce-y d-none d-xl-block"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fvector-service-v1.png&w=640&q=75"
      />
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-xl-7">
            <div className="position-relative">
              <h2>Website Designer Required For Directory Theme</h2>
              <div className="list-meta mt15">
                <p className="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                  <i className="flaticon-place vam fz20 text-thm2 me-2" />
                  {/* */}London, UK
                </p>
                <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                  <i className="flaticon-calendar text-thm2 vam fz20 me-2" />
                  {/* */}January 15, 2022
                </p>
                <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                  <i className="flaticon-website text-thm2 vam fz20 me-2" />
                  {/* */}902 Views
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div>
    <section className="pt30">
      <div className="container">
        <div className="row wrap">
          <div className="col-lg-8">
            <div className="column">
              <div className="scrollbalance-inner">
                <div className="row">
                  <div className="col-sm-6 col-xl-4">
                    <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div className="icon flex-shrink-0">
                        <span className="flaticon-notification-1" />
                      </div>
                      <div className="details">
                        <h5 className="title">Seller Type</h5>
                        <p className="mb-0 text">Company</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div className="icon flex-shrink-0">
                        <span className="flaticon-dollar" />
                      </div>
                      <div className="details">
                        <h5 className="title">Project type</h5>
                        <p className="mb-0 text">Hourly</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div className="icon flex-shrink-0">
                        <span className="flaticon-fifteen" />
                      </div>
                      <div className="details">
                        <h5 className="title">Project Duration</h5>
                        <p className="mb-0 text">10-15 Hours</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div className="icon flex-shrink-0">
                        <span className="flaticon-like-1" />
                      </div>
                      <div className="details">
                        <h5 className="title">Project Level</h5>
                        <p className="mb-0 text">Expensive</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div className="icon flex-shrink-0">
                        <span className="flaticon-translator" />
                      </div>
                      <div className="details">
                        <h5 className="title">Languages</h5>
                        <p className="mb-0 text">20</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div className="icon flex-shrink-0">
                        <span className="flaticon-goal" />
                      </div>
                      <div className="details">
                        <h5 className="title">English Level</h5>
                        <p className="mb-0 text">Professional</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-about">
                  <h4>Description</h4>
                  <p className="text mb30">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English.{/* */}
                  </p>
                  <p className="text mb30">
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text, and a search
                    for 'lorem ipsum' will uncover many web sites still in their
                    infancy. Various versions have evolved over the years,
                    sometimes by accident, sometimes on purpose (injected humour
                    and the like).
                  </p>
                  <hr className="opacity-100 mb60 mt60" />
                  <h4 className="mb30">Attachments</h4>
                  <div className="row">
                    <div className="col-6 col-lg-3">
                      <div className="project-attach">
                        <h6 className="title">Project Brief</h6>
                        <p>PDF</p>
                        <span className="icon flaticon-page" />
                      </div>
                    </div>
                    <div className="col-6 col-lg-3">
                      <div className="project-attach">
                        <h6 className="title">Project Brief</h6>
                        <p>PDF</p>
                        <span className="icon flaticon-page" />
                      </div>
                    </div>
                  </div>
                  <hr className="opacity-100 mb60 mt30" />
                  <h4 className="mb30">Skills Required</h4>
                  <div className="mb60">
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      SaaS
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      Figma
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      Software Design
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      Sketch
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      Prototyping
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      HTML5
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr10">
                      Design
                    </a>
                    <a className="tag list-inline-item mb-2 mb-xl-0 mr0">
                      Writing
                    </a>
                  </div>
                  <hr className="opacity-100 mb60" />
                  <h4 className="mb30">Project Proposals (3)</h4>
                  <div className="row">
                    <div className="col-md-6 col-lg-12">
                      <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-start">
                        <div className="col-xl-10 px-0">
                          <div className="d-lg-flex">
                            <div className="thumb w90 position-relative rounded-circle mb15-md">
                              <img
                                alt="rounded-circle"
                                loading="lazy"
                                width={90}
                                height={90}
                                decoding="async"
                                data-nimg={1}
                                className="rounded-circle mx-auto"
                                style={{ color: "transparent" }}
                                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75
                                "
                                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-1.png&w=96&q=75"
                              />
                              <span className="online" />
                            </div>
                            <div className="details ml20 ml0-md mb15-md">
                              <h5 className="title mb-1">Leslie Alexander</h5>
                              <div className="review mb20">
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="fas fa-star fz10 review-color pr10" />
                                  <span className="dark-color">4.9</span>(
                                  {/* */}595{/* */}
                                  reviews)
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs" />
                                  {/* */}2 hours ago
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm">
                                  <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs" />
                                  {/* */}1 Received
                                </p>
                              </div>
                              <p className="text mb-0">
                                Many desktop publishing packages and web page
                                editors now use Lorem Ipsum as their default
                                model text.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 px-0">
                          <div className="text-lg-center text-xl-end mt-0 mt-lg-2 mt-xl-0">
                            <h4>
                              ${/* */}100{/* */}- ${/* */}150
                            </h4>
                            <p className="text mb-0">
                              in
                              {/* */}100{/* */}
                              hours
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-start">
                        <div className="col-xl-10 px-0">
                          <div className="d-lg-flex">
                            <div className="thumb w90 position-relative rounded-circle mb15-md">
                              <img
                                alt="rounded-circle"
                                loading="lazy"
                                width={90}
                                height={90}
                                decoding="async"
                                data-nimg={1}
                                className="rounded-circle mx-auto"
                                style={{ color: "transparent" }}
                                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-5.png&w=96&q=75
                                "
                                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-5.png&w=96&q=75"
                              />
                              <span className="online" />
                            </div>
                            <div className="details ml20 ml0-md mb15-md">
                              <h5 className="title mb-1">Leslie Alexander</h5>
                              <div className="review mb20">
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="fas fa-star fz10 review-color pr10" />
                                  <span className="dark-color">4.9</span>(
                                  {/* */}595{/* */}
                                  reviews)
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs" />
                                  {/* */}2 hours ago
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm">
                                  <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs" />
                                  {/* */}1 Received
                                </p>
                              </div>
                              <p className="text mb-0">
                                Many desktop publishing packages and web page
                                editors now use Lorem Ipsum as their default
                                model text.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 px-0">
                          <div className="text-lg-center text-xl-end mt-0 mt-lg-2 mt-xl-0">
                            <h4>
                              ${/* */}100{/* */}- ${/* */}150
                            </h4>
                            <p className="text mb-0">
                              in
                              {/* */}100{/* */}
                              hours
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-start">
                        <div className="col-xl-10 px-0">
                          <div className="d-lg-flex">
                            <div className="thumb w90 position-relative rounded-circle mb15-md">
                              <img
                                alt="rounded-circle"
                                loading="lazy"
                                width={90}
                                height={90}
                                decoding="async"
                                data-nimg={1}
                                className="rounded-circle mx-auto"
                                style={{ color: "transparent" }}
                                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-3.png&w=96&q=75
                                "
                                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-3.png&w=96&q=75"
                              />
                              <span className="online" />
                            </div>
                            <div className="details ml20 ml0-md mb15-md">
                              <h5 className="title mb-1">Leslie Alexander</h5>
                              <div className="review mb20">
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="fas fa-star fz10 review-color pr10" />
                                  <span className="dark-color">4.9</span>(
                                  {/* */}595{/* */}
                                  reviews)
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs" />
                                  {/* */}2 hours ago
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm">
                                  <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs" />
                                  {/* */}1 Received
                                </p>
                              </div>
                              <p className="text mb-0">
                                Many desktop publishing packages and web page
                                editors now use Lorem Ipsum as their default
                                model text.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 px-0">
                          <div className="text-lg-center text-xl-end mt-0 mt-lg-2 mt-xl-0">
                            <h4>
                              ${/* */}100{/* */}- ${/* */}150
                            </h4>
                            <p className="text mb-0">
                              in
                              {/* */}100{/* */}
                              hours
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bsp_reveiw_wrt mt25">
                    <h4>Send Your Proposal</h4>
                    <form className="comments_form mt30 mb30-md">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb20">
                            <label className="fw500 ff-heading dark-color mb-2">
                              Your hourly price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$99"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb20">
                            <label className="fw500 ff-heading dark-color mb-2">
                              Estimated Hours
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={4}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-4">
                            <label className="fw500 fz16 ff-heading dark-color mb-2">
                              Cover Letter
                            </label>
                            <textarea
                              className="pt15"
                              rows={6}
                              placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="extra-service-tab mb40 mt30">
                            <nav>
                              <div className="nav flex-column nav-tabs">
                                <button className="nav-link">
                                  <label className="custom_checkbox fw500 text-start">
                                    100 Words (+2 days)
                                    <span className="text text-bottom">
                                      I will professionally translate english to
                                      german
                                    </span>
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="price">${/* */}25</span>
                                </button>
                                <button className="nav-link">
                                  <label className="custom_checkbox fw500 text-start">
                                    100 Words (+2 days)
                                    <span className="text text-bottom">
                                      I will professionally translate english to
                                      german
                                    </span>
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="price">${/* */}45</span>
                                </button>
                                <button className="nav-link">
                                  <label className="custom_checkbox fw500 text-start">
                                    100 Words (+2 days)
                                    <span className="text text-bottom">
                                      I will professionally translate english to
                                      german
                                    </span>
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="price">${/* */}75</span>
                                </button>
                              </div>
                            </nav>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="d-grid">
                            <a className="ud-btn btn-thm">
                              Submit a Proposal
                              <i className="fal fa-arrow-right-long" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="column">
              <div className="scrollbalance-inner">
                <div className="blog-sidebar ms-lg-auto">
                  <div className="price-widget pt25 bdrs8">
                    <h3 className="widget-title">$100 - $150</h3>
                    <p className="text fz14">Hourly Rate</p>
                    <div className="d-grid">
                      <a className="ud-btn btn-thm" href="/">
                        Submit a Proposal
                        <i className="fal fa-arrow-right-long" />
                      </a>
                    </div>
                  </div>
                  <div className="freelancer-style1 service-single mb-0 bdrs8">
                    <h4>About Buyer</h4>
                    <div className="wrapper d-flex align-items-center mt20">
                      <div className="thumb position-relative mb25">
                        <img
                          alt="client"
                          loading="lazy"
                          width={60}
                          height={60}
                          decoding="async"
                          data-nimg={1}
                          className="rounded-circle mx-auto"
                          style={{ color: "transparent" }}
                          srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75
                          "
                          src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
                        />
                      </div>
                      <div className="ml20">
                        <h5 className="title mb-1">Dropbox</h5>
                        <p className="mb-0">Digital Marketing</p>
                        <div className="review">
                          <p>
                            <i className="fas fa-star fz10 review-color pr10" />
                            <span className="dark-color">4.9</span> (595
                            reviews)
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="opacity-100" />
                    <div className="details">
                      <div className="fl-meta d-flex align-items-center justify-content-between">
                        <a className="meta fw500 text-start">
                          Location
                          <br />
                          <span className="fz14 fw400">London</span>
                        </a>
                        <a className="meta fw500 text-start">
                          Employees
                          <br />
                          <span className="fz14 fw400">11-20</span>
                        </a>
                        <a className="meta fw500 text-start">
                          Departments
                          <br />
                          <span className="fz14 fw400">Designer</span>
                        </a>
                      </div>
                    </div>
                    <div className="d-grid mt30">
                      <a className="ud-btn btn-thm-border" href="/">
                        Contact Buyer
                        <i className="fal fa-arrow-right-long" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default ProjectSection2

