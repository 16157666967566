import React from 'react'
import Skeleton from "react-loading-skeleton";

const ChatUserCard = () => {
  return (
    <div className="list-item pt5">
    <div className="d-flex align-items-center position-relative">
        <Skeleton  width={50}  height={50} borderRadius={'50px'}/>
      <div className="d-sm-flex ">
        <div className="d-inline-block mx-2">
          <div className="fz15 fw500 dark-color ff-heading mb-0 ">
          <Skeleton  width={100}  height={20} />
          </div>
            <p className="preview pt-1">
            <Skeleton  width={180}  height={10} />
              
            </p>
        </div>
      </div>
    </div>
</div>  )
}

export default ChatUserCard