import React from 'react'
import BecomeSellerSection1 from '../../../Components/Dashboard/BecomeSeller/BecomeSellerSection1'
import Layout from '../../../Layout/Layout'

const BecomeSeller = () => {
  return (
    <>
    <Layout>
     <BecomeSellerSection1 />
    </Layout>
    </>
  )
}

export default BecomeSeller



