import React from "react";
import ReviewsSection1 from "../../Components/Dashboard/Reviews/ReviewsSection1";
import Layout from "../../Components/Dashboard/Layout/Layout";

const Reviews = () => {
  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <ReviewsSection1 />
        </div>
      </Layout>
    </>
  );
};

export default Reviews;
