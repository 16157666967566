/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { initialValuesSignup, signUpSchema } from "./schema";
import { useFormik } from "formik";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { useMutation, useQuery } from "@apollo/client";
import { REGISTER_MUTATION } from "../../Graphql/Mutation/Auth/AuthMutation";
import { GET_SERVICES } from "../../Graphql/Query/Service";

const countries =[
  "Kingston",
  "Montego Bay",
  "Spanish Town",
  "Portmore",
  "May Pen",
  "Mandeville",
  "Old Harbour",
  "Linstead",
  "Half Way Tree",
  "Savanna-la-Mar",
  "Port Antonio",
  "Ocho Rios",
  "Morant Bay",
  "St. Ann's Bay",
  "Hayes"
];
const RegisterSection = () => {
  const navigate = useNavigate();
  const [registerUser, { loading: registerLoading }] =
    useMutation(REGISTER_MUTATION);

  const { data } = useQuery(GET_SERVICES);
  const services = data?.services || [];

  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValuesSignup,
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      const {
        username,
        firstName,
        lastName,
        email,
        profilePic,
        service_areas,
        services,
        role,
        parish,
        password,
      } = values;

      try {
        const formData = new FormData();
        formData.append("media", profilePic);

        const mediaResponse = await axios.post(
          `http://139.99.237.187:4444/upload-media`,
          formData
        );

        console.log("1nd");

        let profilePicUrl = "";
        console.log(mediaResponse, "mediaResponse");
        if (mediaResponse?.data?.status === true) {
          profilePicUrl = mediaResponse.data?.data[0].name;
        }

        const registerResponse = await registerUser({
          variables: {
            username,
            firstName,
            lastName,
            email,
            profilePic: profilePicUrl,
            service_areas,
            services: services.map((e) => e?.value),
            parish,
            role,
            password,
          },
        });
        console.log("2nd");

        console.log(registerResponse, "registerResponse");
        if (registerResponse && registerResponse.data) {
          navigate("/login");
          toast.success("User Registered SuccessFully");
        } else {
          console.error("Registration failed");
        }
      } catch (error) {
        console.log(error,"---------error");
      }
    },
  }
);

  return (
    <>
      <div className="body_content mt40" style={{ background: "#FFEDE8" }}>
        <section className="our-register">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="main-title text-center">
                  <h2 className="title">Register</h2>
                  <p className="paragraph">
                    <strong>CUSTOMER:</strong> Always open for business
                  </p>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div className="col-xl-6 mx-auto">
                  <div className="log-reg-form search-modal form-style1 bgc-white p40 p30-sm default-box-shadow1 bdrs12">
                    <div className="mb30">
                      <h4>Create your Free Account</h4>
                      <p className="text mt20">
                        Already have an account?{/* */}
                        <Link className="text-thm" to={"/login"}>
                          Log In!
                        </Link>
                      </p>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        User name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder="User name"
                        onChange={handleChange}
                        value={values.username}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.username && errors.username}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder="First name"
                        onChange={handleChange}
                        value={values.firstName}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.firstName && errors.firstName}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        placeholder="Last name"
                        onChange={handleChange}
                        value={values.lastName}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.lastName && errors.lastName}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.email && errors.email}
                      </span>
                    </div>

                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Parish
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="parish"
                        placeholder="Parish"
                        onChange={handleChange}
                        value={values.parish}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.parish && errors.parish}
                      </span>
                    </div>
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Select Role?{" "}
                      </label>
                      <select
                        class="form-select form-control"
                        name="role"
                        aria-label="Default select example"
                        onChange={handleChange}
                        value={values.role}
                        onBlur={handleBlur}
                      >
                        <option selected disabled value="">
                          Select Role?{" "}
                        </option>
                        <option value="SELLER">Seller</option>
                        <option value="BUYER">Buyer</option>
                      </select>

                      <span className="error">
                        {touched.role && errors.role}
                      </span>
                    </div>
                    {values?.role === "SELLER" && (
                      <>
                        <div className="mb10">
                          <label className="form-label fw500 dark-color">
                            Select any other services you do.
                          </label>
                          {/* <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            name="services"
                            onChange={handleChange}
                            value={values.services}
                            onBlur={handleBlur}
                          >
                            <option selected disabled value="">
                              Select any other services you do.
                            </option>
                            {services?.map(e=><option value={e?.id}>
                            {e?.name}
                            </option>)}
                            <option value="Design Services">
                              Design Services
                            </option>
                            <option value="Web Services">Web Services</option>
                            <option value="Social Media">Social Media</option>
                          </select> */}
                          <Select
                            isMulti
                            name="services"
                            options={services?.map((e) => ({
                              value: e?.id,
                              label: e?.name,
                            }))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) =>
                              setFieldValue(
                                "services",
                                selectedOptions.map((option) => ({
                                  value: option?.value,
                                  label: option?.label,
                                }))
                              )
                            }
                            onBlur={handleBlur("services")}
                            value={values?.services?.map((service) => ({
                              value: service?.value,
                              label: service?.label,
                            }))}
                          />
                          <span className="error">
                            {touched.services && errors.services}
                          </span>
                        </div>
                        <div className="mb10">
                          <label className="form-label fw500 dark-color">
                            Select Service Areas from parishes
                          </label>
                          {/* <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            name="service_areas"
                            onChange={handleChange}
                            value={values.service_areas}
                            onBlur={handleBlur}
                          >
                            <option selected disabled value="">
                              Select Service Areas from parishes
                            </option>
                            <option value="USA">USA</option>
                            <option value="Uk">Uk</option>
                            <option value="UAE">UAE</option>
                            <option value="Europe">Europe</option>
                          </select> */}
                          <Select
                            isMulti
                            name="service_areas"
                            options={countries?.map((e) => ({
                              value: e,
                              label: e,
                            }))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) =>
                              setFieldValue(
                                "service_areas",
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            onBlur={handleBlur("service_areas")}
                            value={values.service_areas.map((service) => ({
                              value: service,
                              label: service,
                            }))}
                          />
                          <span className="error">
                            {touched.service_areas && errors.service_areas}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="mb10">
                      <label className="form-label fw500 dark-color">
                        Profile Picture
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="profilePic"
                        onChange={(event) => {
                          // When the file input changes, set the value to the selected file
                          setFieldValue(
                            "profilePic",
                            event.currentTarget.files[0]
                          );
                        }}
                        // value={values.profilePic}
                        // onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.profilePic && errors.profilePic}
                      </span>
                    </div>
                    <div className="mb15">
                      <label className="form-label fw500 dark-color">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="*******"
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.password && errors.password}
                      </span>
                    </div>
                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz14 ff-heading">
                        By signing up with email, Facebook, or Google, you agree
                        to our Terms of Use and Privacy Policy.
                        <input
                          type="checkbox"
                          name="checkbox1"
                          checked={values.checkbox1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="checkmark" /> <br />
                        <span className="error">
                          {touched.checkbox1 && errors.checkbox1}
                        </span>
                      </label>
                    </div>
                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz14 ff-heading">
                        I'd like to receive special email offers and discounts.
                        <input
                          type="checkbox"
                          name="checkbox2"
                          checked={values.checkbox2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="checkmark" /> <br />
                        <span className="error">
                          {touched.checkbox2 && errors.checkbox2}
                        </span>
                      </label>
                    </div>
                    <div className="d-grid mb20">
                      <button
                        className="ud-btn btn-thm default-box-shadow2"
                        type="submit"
                      >
                        {registerLoading ? (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        ) : (
                          "Creat Account"
                        )}
                        {/* <i className="fal fa-arrow-right-long" /> */}
                      </button>
                    </div>
                    <div className="d-md-flex justify-content-between mb-4">
                      <button
                        className="ud-btn btn-google w-100 fz14 fw400 mb-2 mb-md-0"
                        type="button"
                      >
                        <i className="fab fa-google" /> Continue with Google
                      </button>
                    </div>
                    <div>
                      <label className="custom_checkbox fz14 ff-heading">
                        Problems Registering?
                      </label>
                    </div>
                    <div>
                      <label className="custom_checkbox fz14 ff-heading">
                        Contact us at 1-888-yassoh
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <a className="scrollToHome">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </>
  );
};

export default RegisterSection;
