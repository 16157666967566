import { gql } from "@apollo/client";

export const GET_GIG = gql`
query sellerGigs {
  sellerGigs {
    id
    description
    deliveryTime
    price
    requirement
    serviceCategory {
      name
      services {
        service_categories {
          name
        }
      }
    }
    tags {
      name
    }
    title
    status
  }
}`
export const GET_ONE_GIG = gql`
query sellerGigs {
  sellerGigs {
    id
    title
  }
}`
export const ALL_GET_GIG = gql`
query gigs {
  gigs {
    description
    id
    price
    title
    seller {
      username
      profilePic
      id
    }
  }
}`
export const GIG_BY_SELLER_ID = gql`
query GigsBySellerId($sellerId: Int!) {
  gigsBySellerId(sellerId: $sellerId) {
    id
    title
    price
  }
}`

