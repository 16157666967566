import { gql } from "@apollo/client";


export const GET_ALL_CHAT=gql`
query UserChats {
  userChats {
    id
    receiver {
      id
      username
      profilePic
      role
    }
    sender {
      profilePic
      username
      id
      role
    }
    messages {
      text
      createdAt
    }
  }
}
`

export const GET_CHAT_BY_USER_ID = gql`
query GetMessagesByChatID($chatId: Int!) {
  getMessagesByChatID(chatId: $chatId) {
    createdAt
    text
    sender {
      username
      profilePic
      id
    }
  }
}
`