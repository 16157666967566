import React from 'react'
import Layout from '../../../Layout/Layout'
import InvoicesSection from '../../../Components/Pages/Invoices/InvoicesSection'

const Invoices = () => {
  return (
    <>
      <Layout>
        <InvoicesSection />
      </Layout>
    </>
  )
}

export default Invoices