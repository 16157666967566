import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import SaveSection1 from "../../Components/Dashboard/Save/SaveSection1";

const Save = () => {
  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <SaveSection1 />
        </div>
      </Layout>
    </>
  );
};

export default Save;
