import React from 'react'
import JobViewSection1 from '../../../Components/BrowserJobs/JobView/JobViewSection1'
import Layout from '../../../Layout/Layout'

const JobView = () => {
  return (
    <>
    <Layout>
     <JobViewSection1 />
    </Layout>
    </>
  )
}

export default JobView