/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";

const CardFivePropsal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const [card, setCard] = useState([]);
  const allJobsCombined = [...card];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const AllCardData = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/products");
      setCard(response?.data?.products);
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllCardData();
  }, []);
  return (
    <>
      <table className="table-style3 table at-savesearch">
        <thead className="t-head">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Cost / Delivery</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody className="t-body">
          {currentJobs?.map((item) => (
            <tr>
              <th className="ps-0" scope="row">
                <div className="freelancer-style1 p-0 mb-0 box-shadow-none">
                  <div className="d-lg-flex align-items-lg-center">
                    <div className="thumb w60 position-relative rounded-circle mb15-md">
                      <img
                        alt="thumb"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="rounded-circle mx-auto"
                        style={{ color: "transparent" }}
                        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75
                                "
                        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
                      />
                      <span className="online-badge2" />
                    </div>
                    <div className="details ml15 ml0-md mb15-md">
                      <h5 className="title mb-2">Food Delviery Mobile App</h5>
                      <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                        <i className="flaticon-place fz16 vam text-thm2 me-1" />
                        {/* */}London, UK
                      </p>
                      <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                        <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs" />
                        {/* */}April 01, 2023
                      </p>
                      <p className="mb-0 fz14 list-inline-item mb5-sm">
                        <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs" />
                        {/* */}1 Received
                      </p>
                    </div>
                  </div>
                </div>
              </th>
              <td className="vam">
                <h5 className="mb-0">
                  ${/* */}100{/* */}- ${/* */}150{/* */}
                  <span className="fz14 fw400">Hourly Rate</span>
                </h5>
              </td>
              <td>
                <div className="d-flex">
                  <a
                    className="icon me-2"
                    id="edit"
                    data-bs-toggle="modal"
                    data-bs-target="#proposalModal"
                  >
                    <span className="flaticon-pencil" />
                  </a>
                  <a
                    className="icon"
                    id="delete"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                  >
                    <span className="flaticon-delete" />
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="row mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CardFivePropsal;
