/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ALL_GET_GIG } from "../../Graphql/Query/Gig";
import { CREATE_CHAT_MUTATION } from "../../Graphql/Mutation/Auth/Chat/Chat";
import CardSkeleton from "../Skeleton/Card";

const CardOneServiceAndSave = () => {

  const navigate=useNavigate()

  const [createChat] = useMutation(CREATE_CHAT_MUTATION);
  const { data,loading } = useQuery(ALL_GET_GIG);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 9;
  const allJobsCombined = data ? data?.gigs : []
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const CreateChat = async (id) => {
    try {
      const createChatResponse = await createChat({
        variables: {
          receiverId: +id,
          text: "hi",
        },
      });
      console.log('createChatResponse',createChatResponse)
      if (createChatResponse?.data?.createChat) {
        navigate(`/dashboard/message/${id}`)
      }
      else{
        console.log('chat not created')
      }
    } catch (error) {
      console.log(error, "error");
    }
  };


  return (
    <>
      <div className="row">
        {loading ? [1,2,3,4,5,6].map(e=><CardSkeleton/>)  : currentJobs?.map((job) => (
          <div className="col-sm-6 col-xl-4">
            <div className="listing-style1" style={{height:'auto'}}>
              <div className="list-thumb">
                <img
                  alt="thumbnail"
                  loading="lazy"
                  width={255}
                  height={190}
                  decoding="async"
                  data-nimg={1}
                  className="w-100 h-100 object-fit-cover"
                  style={{ color: "transparent" }}
                  // srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75"
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75"
                />
                <a className="listing-fav fz12">
                  <span className="far fa-heart" />
                </a>
              </div>
              <div className="list-content">
                <p className="list-text body-color fz14 mb-1">
                  {job?.title}
                </p>
                <h5 className="list-title" onClick={()=>CreateChat(job?.seller?.id)}>
                  <Link >{job?.description.slice(0,35)}</Link>
                </h5>
                <div className="review-meta d-flex align-items-center">
                  <i className="fas fa-star fz10 review-color me-2" />
                  <p className="mb-0 body-color fz14">
                    <span className="dark-color me-2">{job?.rating}</span>
                    94{/* */}
                    reviews
                  </p>
                </div>
                <hr className="my-2" />
                <div className="list-meta d-flex justify-content-between align-items-center mt15">
                      <Link className="d-flex" onClick={()=>CreateChat(job?.seller?.id)}>
                    <span className="position-relative mr10">
                      <img
                        alt="Freelancer Photo"
                        loading="lazy"
                        width={24}
                        height={24}
                        decoding="async"
                        data-nimg={1}
                        className="rounded-circle wa"
                        style={{ color: "transparent" }}
                        // srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Flistings%2Fg-1.jpg&w=256&q=75"
                        src={`${'https://yassohapi.devssh.xyz'}${job?.seller?.profilePic}`}
                      />
                      <span className="online-badges" />
                    </span>
                    <span className="fz14">{job?.seller?.username}</span>
                  </Link>
                  <div className="budget">
                    <p className="mb-0 body-color">
                      Starting at
                      <span className="fz17 fw500 dark-color ms-1">
                        ${/* */}
                        {job?.price}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mbp_pagination text-center">
        <ul className="page_navigation">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a className="page-link" onClick={() => paginate(currentPage - 1)}>
              <span className="fas fa-angle-left" />
            </a>
          </li>
          {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
            .slice(currentPage - 1, currentPage + 2)
            .map((number) => (
              <li
                key={number}
                className={`page-item ${
                  currentPage === number + 1 ? "active" : ""
                }`}
              >
                <a className="page-link" onClick={() => paginate(number + 1)}>
                  {number + 1}
                </a>
              </li>
            ))}
          <li
            className={`page-item ${
              currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <a className="page-link" onClick={() => paginate(currentPage + 1)}>
              <span className="fas fa-angle-right" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CardOneServiceAndSave;
