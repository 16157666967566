import React from 'react'
import EmployeeSection1 from '../../../Components/Dashboard/Employee/EmployeeSection1'
import Layout from '../../../Layout/Layout'

const Employee = () => {
  return (
    <>
    <Layout>
      <EmployeeSection1 />
    </Layout>
    </>
  )
}

export default Employee