/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const AboutSection1 = () => {
  return (
    <>
<div className="body_content">
  <section className="our-about pb90">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-6 col-md-3">
          <div className="position-relative mb30">
            <img
              alt="about img"
              loading="lazy"
              width={334}
              height={284}
              decoding="async"
              data-nimg={1}
              className="w100 w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-1.jpg&w=384&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-1.jpg&w=384&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="position-relative mb30">
            <img
              alt="about img"
              loading="lazy"
              width={334}
              height={284}
              decoding="async"
              data-nimg={1}
              className="w100 w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-2.jpg&w=384&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-2.jpg&w=384&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="position-relative mb30">
            <img
              alt="about img"
              loading="lazy"
              width={334}
              height={284}
              decoding="async"
              data-nimg={1}
              className="w100 w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-3.jpg&w=384&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-3.jpg&w=384&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="position-relative mb30">
            <img
              alt="about img"
              loading="lazy"
              width={334}
              height={284}
              decoding="async"
              data-nimg={1}
              className="w100 w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-4.jpg&w=384&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-4.jpg&w=384&q=75"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-about pb90 pt0">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="main-title">
            <h2>
              Join World's Best Marketplace{/* */}
              <br className="d-none d-lg-block" />
              for Workers
            </h2>
          </div>
        </div>
        <div className="col-lg-6">
          <p className="text mb20">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </p>
          <div className="list-style2">
            <ul className="mb25">
              <li>
                <i className="far fa-check" />
                Connect to freelancers with proven business experience
              </li>
              <li>
                <i className="far fa-check" />
                Get matched with the perfect talent by a customer success
                manager
              </li>
              <li>
                <i className="far fa-check" />
                Unmatched quality of remote, hybrid, and flexible jobs
              </li>
            </ul>
          </div>
          <a className="ud-btn btn-thm-border mb25 me-4">
            Get Started
            <i className="fal fa-arrow-right-long" />
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="p-0">
    <div className="cta-banner3 mx-auto maxw1600 pt120 pt60-lg pb90 pb60-lg position-relative overflow-hidden bgc-light-yellow">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 wow fadeInRight" data-wow-delay="300ms">
            <div className="mb30">
              <div className="main-title">
                <h2 className="title">
                  A whole world of freelance{/* */}
                  <br className="d-none d-xl-block" />
                  talent at your fingertips
                </h2>
              </div>
            </div>
            <div className="why-chose-list">
              <div className="list-one d-flex align-items-start mb30">
                <span className="list-icon flex-shrink-0 flaticon-badge" />
                <div className="list-content flex-grow-1 ml20">
                  <h4 className="mb-1">Proof of quality</h4>
                  <p className="text mb-0 fz15">
                    Check any pro’s work samples, client reviews, and identity{" "}
                    <br className="d-none d-lg-block" />
                    {/* */}verification.
                  </p>
                </div>
              </div>
              <div className="list-one d-flex align-items-start mb30">
                <span className="list-icon flex-shrink-0 flaticon-money" />
                <div className="list-content flex-grow-1 ml20">
                  <h4 className="mb-1">No cost until you hire</h4>
                  <p className="text mb-0 fz15">
                    Interview potential fits for your job, negotiate rates, and
                    only pay <br className="d-none d-lg-block" />
                    for work you approve.
                  </p>
                </div>
              </div>
              <div className="list-one d-flex align-items-start mb30">
                <span className="list-icon flex-shrink-0 flaticon-security" />
                <div className="list-content flex-grow-1 ml20">
                  <h4 className="mb-1">Safe and secure</h4>
                  <p className="text mb-0 fz15">
                    Focus on your work knowing we help protect your data and
                    privacy. We’re here with 24/7 support if you need it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        alt="cta banner 3"
        data-wow-delay="300ms"
        loading="lazy"
        width={500}
        height={500}
        decoding="async"
        data-nimg={1}
        className="cta-banner3-img wow fadeInLeft h-100 object-fit-cover"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-5.jpg&w=640&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-5.jpg&w=640&q=75"
      />
    </div>
  </section>
  <section className="pb0 pt60">
    <div className="container maxw1600 pb60">
      <div
        className="row justify-content-center wow fadeInUp"
        data-wow-delay="300ms"
      >
        <div className="col-6 col-md-3">
          <div className="funfact_one mb20-sm text-center">
            <div className="details">
              <ul className="ps-0 mb-0 d-flex justify-content-center">
                <li>
                  <div className="timer">
                    <span />
                  </div>
                </li>
                <li>
                  <span>M</span>
                </li>
              </ul>
              <p className="text mb-0">Total Freelancer</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="funfact_one mb20-sm text-center">
            <div className="details">
              <ul className="ps-0 mb-0 d-flex justify-content-center">
                <li>
                  <div className="timer">
                    <span />
                  </div>
                </li>
                <li>
                  <span>M</span>
                </li>
              </ul>
              <p className="text mb-0">Positive Review</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="funfact_one mb20-sm text-center">
            <div className="details">
              <ul className="ps-0 mb-0 d-flex justify-content-center">
                <li>
                  <div className="timer">
                    <span />
                  </div>
                </li>
                <li>
                  <span>M</span>
                </li>
              </ul>
              <p className="text mb-0">Order recieved</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="funfact_one mb20-sm text-center">
            <div className="details">
              <ul className="ps-0 mb-0 d-flex justify-content-center">
                <li>
                  <div className="timer">
                    <span />
                  </div>
                </li>
                <li>
                  <span>M</span>
                </li>
              </ul>
              <p className="text mb-0">Projects Completed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-testimonial">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mx-auto wow fadeInUp" data-wow-delay="300ms">
          <div className="main-title text-center">
            <h2>Testimonials</h2>
            <p className="paragraph">
              Interdum et malesuada fames ac ante ipsum
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 m-auto wow fadeInUp" data-wow-delay="500ms">
          <div className="testimonial-style2">
            <div className="testi-content text-md-center">
              <span className="icon fas fa-quote-left" />
              <h4 className="testi-text">
                Unforgettable Japan Journey: Our family traveled by bullet
                train, and the hotel's strategic location enhanced our
                experience. Agoda's pricing was a pleasant surprise.
              </h4>
              <h6 className="name">Sophia Ramirez</h6>
              <p className="design">Software Engineer</p>
            </div>
            <div className="tab-list position-relative">
              <ul className="nav nav-pills justify-content-md-center">
                <li className="nav-item">
                  <button className="nav-link">
                    <img
                      alt="user"
                      loading="lazy"
                      width={70}
                      height={70}
                      decoding="async"
                      data-nimg={1}
                      className="h-auto w-100 rounded-circle"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=96&q=75"
                    />
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link">
                    <img
                      alt="user"
                      loading="lazy"
                      width={70}
                      height={70}
                      decoding="async"
                      data-nimg={1}
                      className="h-auto w-100 rounded-circle"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-2.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-2.png&w=96&q=75"
                    />
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link active">
                    <img
                      alt="user"
                      loading="lazy"
                      width={70}
                      height={70}
                      decoding="async"
                      data-nimg={1}
                      className="h-auto w-100 rounded-circle"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-3.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-3.png&w=96&q=75"
                    />
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link">
                    <img
                      alt="user"
                      loading="lazy"
                      width={70}
                      height={70}
                      decoding="async"
                      data-nimg={1}
                      className="h-auto w-100 rounded-circle"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-4.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-4.png&w=96&q=75"
                    />
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link">
                    <img
                      alt="user"
                      loading="lazy"
                      width={70}
                      height={70}
                      decoding="async"
                      data-nimg={1}
                      className="h-auto w-100 rounded-circle"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-5.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-5.png&w=96&q=75"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="pt0 pb40-md">
    <div className="cta-banner mx-auto maxw1700 pt110 pb80 pb30-md bdrs12 position-relative bgc-thm3">
      <div className="container">
        <div className="row align-items-start align-items-xl-center">
          <div className="col-md-6 col-lg-7 col-xl-6">
            <div
              className="position-relative mb35 mb0-sm wow fadeInRight"
              data-wow-delay="300ms"
            >
              <div className="freelancer-widget d-none d-lg-block">
                <h5 className="title mb20">
                  <span className="text-thm">200+</span>Verified Freelancer
                </h5>
                <div className="thumb d-flex align-items-center mb20">
                  <div className="flex-shrink-0">
                    <img
                      alt="object"
                      loading="lazy"
                      width={55}
                      height={55}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-1.png&w=96&q=75"
                    />
                  </div>
                  <div className="flex-grow-1 ml20">
                    <h6 className="title mb-0">Marvin McKinney</h6>
                    <p className="fz14 mb-0">Designer</p>
                  </div>
                </div>
                <div className="thumb d-flex align-items-center mb20">
                  <div className="flex-shrink-0">
                    <img
                      alt="object"
                      loading="lazy"
                      width={55}
                      height={55}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-3.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-3.png&w=96&q=75"
                    />
                  </div>
                  <div className="flex-grow-1 ml20">
                    <h6 className="title mb-0">Ralph Edwards</h6>
                    <p className="fz14 mb-0">Designer</p>
                  </div>
                </div>
                <div className="thumb d-flex align-items-center mb20">
                  <div className="flex-shrink-0">
                    <img
                      alt="object"
                      loading="lazy"
                      width={55}
                      height={55}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fea-3.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fea-3.png&w=64&q=75"
                    />
                  </div>
                  <div className="flex-grow-1 ml20">
                    <h6 className="title mb-0">Annette Black</h6>
                    <p className="fz14 mb-0">Designer</p>
                  </div>
                </div>
                <div className="thumb d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img
                      alt="object"
                      loading="lazy"
                      width={55}
                      height={55}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-4.png&w=96&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Ftestimonials%2Ftesti-4.png&w=96&q=75"
                    />
                  </div>
                  <div className="flex-grow-1 ml20">
                    <h6 className="title mb-0">Jane Cooper</h6>
                    <p className="fz14 mb-0">Designer</p>
                  </div>
                </div>
              </div>
              <div className="freelancer-style1 about-page-style text-center d-none d-lg-block">
                <div className="thumb w90 mb25 mx-auto position-relative rounded-circle">
                  <img
                    alt="object"
                    loading="lazy"
                    width={90}
                    height={90}
                    decoding="async"
                    data-nimg={1}
                    className="rounded-circle mx-auto"
                    style={{ color: "transparent" }}
                    srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-2.png&w=96&q=75
                    "
                    src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Ffl-2.png&w=96&q=75"
                  />
                  <span className="online" />
                </div>
                <div className="details">
                  <h5 className="title mb-1">Kristin Watson</h5>
                  <p className="mb-0">Dog Trainer</p>
                  <div className="review">
                    <p>
                      <i className="fas fa-star fz10 review-color pr10" />
                      <span className="dark-color">4.9</span>(595 reviews)
                    </p>
                  </div>
                  <div className="skill-tags d-flex align-items-center justify-content-center mb20">
                    <span className="tag">Figma</span>
                    <span className="tag mx10">Sketch</span>
                    <span className="tag">HTML5</span>
                  </div>
                  <hr className="opacity-100" />
                  <div className="fl-meta d-flex align-items-center justify-content-between">
                    <a className="meta fw500 text-start">
                      Location
                      <br />
                      <span className="fz14 fw400">London</span>
                    </a>
                    <a className="meta fw500 text-start">
                      Rate
                      <br />
                      <span className="fz14 fw400">$90 / hr</span>
                    </a>
                    <a className="meta fw500 text-start">
                      Job Success
                      <br />
                      <span className="fz14 fw400">%98</span>
                    </a>
                  </div>
                </div>
              </div>
              <img
                alt="object"
                loading="lazy"
                width={633}
                height={561}
                decoding="async"
                data-nimg={1}
                className="d-block d-lg-none w-100 h-100 object-fit-contain"
                style={{ color: "transparent" }}
                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-4.png&w=64&q=75
                "
                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-4.png&w=64&q=75"
              />
              <div className="imgbox-about-page position-relative d-none d-xl-block">
                <img
                  alt="object"
                  loading="lazy"
                  width={129}
                  height={129}
                  decoding="async"
                  data-nimg={1}
                  className="img-1 spin-right"
                  style={{ color: "transparent" }}
                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-1.png&w=256&q=75
                  "
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-1.png&w=256&q=75"
                />
                <img
                  alt="object"
                  loading="lazy"
                  width={41}
                  height={11}
                  decoding="async"
                  data-nimg={1}
                  className="img-2 bounce-x"
                  style={{ color: "transparent" }}
                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-2.png&w=48&q=75
                  "
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-2.png&w=48&q=75"
                />
                <img
                  alt="object"
                  loading="lazy"
                  width={8}
                  height={34}
                  decoding="async"
                  data-nimg={1}
                  className="img-3 bounce-y"
                  style={{ color: "transparent" }}
                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-3.png&w=16&q=75
                  "
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-3.png&w=16&q=75"
                />
                <img
                  alt="object"
                  loading="lazy"
                  width={57}
                  height={49}
                  decoding="async"
                  data-nimg={1}
                  className="img-4 bounce-y"
                  style={{ color: "transparent" }}
                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-4.png&w=64&q=75
                  "
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-4.png&w=64&q=75"
                />
                <img
                  alt="object"
                  loading="lazy"
                  width={71}
                  height={71}
                  decoding="async"
                  data-nimg={1}
                  className="img-5 spin-right"
                  style={{ color: "transparent" }}
                  srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-5.png&w=96&q=75
                  "
                  src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Felement-5.png&w=96&q=75"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-5 offset-xl-1">
            <div
              className="about-box-1 pe-4 mt100 mt0-lg mb30-lg wow fadeInLeft"
              data-wow-delay="300ms"
            >
              <h2 className="title mb10">
                Truested By Best <br />
                Freelancer
              </h2>
              <p className="text mb25 mb30-md">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod <br />
                tempor incididunt.
              </p>
              <div className="list-style3 mb40 mb30-md">
                <ul>
                  <li>
                    <i className="far fa-check text-white bgc-review-color2" />
                    Last Education of Bachelor Degree
                  </li>
                  <li>
                    <i className="far fa-check text-white bgc-review-color2" />
                    More Than 15 Years Experience
                  </li>
                  <li>
                    <i className="far fa-check text-white bgc-review-color2" />
                    12 Education Award Winning
                  </li>
                </ul>
              </div>
              <a href="/" className="ud-btn btn-thm2">
                See More
                <i className="fal fa-arrow-right-long" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-pricing pt0 pb0">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 m-auto wow fadeInUp">
          <div className="main-title text-center mb30">
            <h2 className="title">Membership Plans</h2>
            <p className="paragraph mt10">
              Give your visitor a smooth online experience with a solid UX
              design
            </p>
          </div>
        </div>
      </div>
      <div className="row wow fadeInUp" data-wow-delay="200ms">
        <div className="col-lg-12">
          <div className="pricing_packages_top d-flex align-items-center justify-content-center mb60">
            <div className="toggle-btn">
              <span className="pricing_save1 dark-color ff-heading">
                Billed Monthly
              </span>
              <label className="switch">
                <input type="checkbox" id="checbox" />
                <span className="pricing_table_switch_slide round" />
              </label>
              <span className="pricing_save2 dark-color ff-heading">
                Billed Yearly
              </span>
              <span className="pricing_save3">Save 20%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row wow fadeInUp" data-wow-delay="300ms">
        <div className="col-sm-6 col-xl-3">
          <div className="pricing_packages text-center bdrs16 active at-home2">
            <div className="heading mb10">
              <h1 className="text2">
                ${/* */}49{/* */}
                <small>/ monthly</small>
              </h1>
              <h4 className="package_title mt-2">Standard Plan</h4>
            </div>
            <div className="details">
              <p className="text mb30">
                One time fee for one listing or task highlighted in search
                results.
              </p>
              <div className="pricing-list mb40">
                <ul className="px-0">
                  <li>1 Listing</li>
                  <li>30 Days Visibility</li>
                  <li>Highlighted in Search Results</li>
                  <li>4 Revisions</li>
                  <li>9 days Delivery Time</li>
                  <li>Products Support</li>
                </ul>
              </div>
              <div className="d-grid">
                <a className="ud-btn btn-light-thm">
                  Buy Now
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="pricing_packages text-center bdrs16 active at-home2">
            <div className="heading mb10">
              <h1 className="text2">
                ${/* */}49{/* */}
                <small>/ monthly</small>
              </h1>
              <h4 className="package_title mt-2">Standard Plan</h4>
            </div>
            <div className="details">
              <p className="text mb30">
                One time fee for one listing or task highlighted in search
                results.
              </p>
              <div className="pricing-list mb40">
                <ul className="px-0">
                  <li>1 Listing</li>
                  <li>30 Days Visibility</li>
                  <li>Highlighted in Search Results</li>
                  <li>4 Revisions</li>
                  <li>9 days Delivery Time</li>
                  <li>Products Support</li>
                </ul>
              </div>
              <div className="d-grid">
                <a className="ud-btn btn-light-thm">
                  Buy Now
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="pricing_packages text-center bdrs16 active at-home2">
            <div className="heading mb10">
              <h1 className="text2">
                ${/* */}49{/* */}
                <small>/ monthly</small>
              </h1>
              <h4 className="package_title mt-2">Standard Plan</h4>
            </div>
            <div className="details">
              <p className="text mb30">
                One time fee for one listing or task highlighted in search
                results.
              </p>
              <div className="pricing-list mb40">
                <ul className="px-0">
                  <li>1 Listing</li>
                  <li>30 Days Visibility</li>
                  <li>Highlighted in Search Results</li>
                  <li>4 Revisions</li>
                  <li>9 days Delivery Time</li>
                  <li>Products Support</li>
                </ul>
              </div>
              <div className="d-grid">
                <a className="ud-btn btn-light-thm">
                  Buy Now
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="pricing_packages text-center bdrs16 active at-home2">
            <div className="heading mb10">
              <h1 className="text2">
                ${/* */}49{/* */}
                <small>/ monthly</small>
              </h1>
              <h4 className="package_title mt-2">Standard Plan</h4>
            </div>
            <div className="details">
              <p className="text mb30">
                One time fee for one listing or task highlighted in search
                results.
              </p>
              <div className="pricing-list mb40">
                <ul className="px-0">
                  <li>1 Listing</li>
                  <li>30 Days Visibility</li>
                  <li>Highlighted in Search Results</li>
                  <li>4 Revisions</li>
                  <li>9 days Delivery Time</li>
                  <li>Products Support</li>
                </ul>
              </div>
              <div className="d-grid">
                <a className="ud-btn btn-light-thm">
                  Buy Now
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-partners">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 wow fadeInUp">
          <div className="main-title text-center">
            <h6>Trusted by the world’s best</h6>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-4 col-xl-2">
          <div className="partner_item text-center mb30-lg">
            <img
              alt="Partner 0"
              loading="lazy"
              width={84}
              height={26}
              decoding="async"
              data-nimg={1}
              className="wa m-auto w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F1.png&w=96&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F1.png&w=96&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <div className="partner_item text-center mb30-lg">
            <img
              alt="Partner 1"
              loading="lazy"
              width={84}
              height={26}
              decoding="async"
              data-nimg={1}
              className="wa m-auto w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F2.png&w=96&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F2.png&w=96&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <div className="partner_item text-center mb30-lg">
            <img
              alt="Partner 2"
              loading="lazy"
              width={84}
              height={26}
              decoding="async"
              data-nimg={1}
              className="wa m-auto w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F3.png&w=96&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F3.png&w=96&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <div className="partner_item text-center mb30-lg">
            <img
              alt="Partner 3"
              loading="lazy"
              width={84}
              height={26}
              decoding="async"
              data-nimg={1}
              className="wa m-auto w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F4.png&w=96&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F4.png&w=96&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <div className="partner_item text-center mb30-lg">
            <img
              alt="Partner 4"
              loading="lazy"
              width={84}
              height={26}
              decoding="async"
              data-nimg={1}
              className="wa m-auto w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F5.png&w=96&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F5.png&w=96&q=75"
            />
          </div>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <div className="partner_item text-center mb30-lg">
            <img
              alt="Partner 5"
              loading="lazy"
              width={84}
              height={26}
              decoding="async"
              data-nimg={1}
              className="wa m-auto w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F6.png&w=96&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fpartners%2F6.png&w=96&q=75"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-cta pt90 pb90 pt60-md pb60-md mt100 mt0-lg bgc-thm4">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-md-6 col-lg-7 col-xl-5 wow fadeInLeft">
          <div className="cta-style3">
            <h2 className="cta-title">
              Find the talent needed to get your business growing.
            </h2>
            <p className="cta-text">
              Advertise your jobs to millions of monthly users and search 15.8
              million CVs
            </p>
            <a className="ud-btn btn-thm2" href="/">
              Get Started <i className="fal fa-arrow-right-long" />
            </a>
          </div>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-5 position-relative wow zoomIn">
          <div className="cta-img">
            <img
              alt="woman"
              loading="lazy"
              width={596}
              height={489}
              decoding="async"
              data-nimg={1}
              className="w-100 h-100 object-fit-contain"
              style={{ color: "transparent" }}
              srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-3.png&w=640&q=75
              "
              src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fabout%2Fabout-3.png&w=640&q=75"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default AboutSection1