import React from 'react'
import ProLogin from '../../Components/ProAuth/ProLogin'
import Layout from '../../Layout/Layout'

const ProLoginPage = () => {
  return (
    <>
    <Layout>
      <ProLogin />
    </Layout>
    </>
  )
}

export default ProLoginPage