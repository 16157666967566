import React from 'react'
import FreelancerSection1 from '../../../Components/Dashboard/Freelancer/FreelancerSection1'
import Layout from '../../../Layout/Layout'

const Freelancer = () => {
  return (
    <>
    <Layout>
    <FreelancerSection1 />
    </Layout>
    </>
  )
}

export default Freelancer