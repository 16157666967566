/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CardOneServiceAndSave from "../../AllCards/CardOneServiceAndSave";

const SaveSection1 = () => {
  return (
    <>
      <>
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Saved</h2>
            <p className="text">Lorem ipsum dolor sit amet, consectetur.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="ps-widget bgc-white bdrs4 p30 mb30 position-relative">
              <div className="navtab-style1">
                <nav>
                  <div className="nav nav-tabs mb30">
                    <button className="nav-link fw500 ps-0 active">
                      Services
                    </button>
                  </div>
                </nav>
                <div className="row">
                  <CardOneServiceAndSave />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
// https://freeio-app-nextjs.vercel.app/proposal
export default SaveSection1;
