/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";

const CardTwoProject = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const [card, setCard] = useState([]);
  const allJobsCombined = [...card];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const AllCardData = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/products");
      setCard(response?.data?.products);
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllCardData();
  }, []);

  return (
    <>
      <div className="row">
        {currentJobs.map((job) => {
          return (
            <div className="col-md-6 col-lg-12">
              <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-lg-center">
                <div className="col-lg-8 ps-0">
                  <div className="d-lg-flex bdrr1 bdrn-xl pr15 pr0-lg">
                    <div className="thumb w60 position-relative rounded-circle mb15-md">
                      <img
                        alt="rounded-circle"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="rounded-circle mx-auto"
                        style={{ color: "transparent" }}
                        srcSet={job?.thumbnail}
                        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
                      />
                      <span className="online-badge2" />
                    </div>
                    <div className="details ml15 ml0-md mb15-md">
                      <h5 className="title mb-3">{job?.brand}</h5>
                      <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                        <i className="flaticon-place fz16 vam text-thm2 me-1" />
                        {/* */}United States
                      </p>
                      <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                        <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs" />
                        {/* */}2 hours ago
                      </p>
                      <p className="mb-0 fz14 list-inline-item mb5-sm">
                        <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs" />
                        {/* */}1 Received
                      </p>
                      <p className="text mt10">
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum as their default model text.
                      </p>
                      <div className="skill-tags d-flex align-items-center justify-content-start">
                        <span className="tag">Figma</span>
                        <span className="tag mx10">Sketch</span>
                        <span className="tag">HTML5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 ps-0 ps-xl-3 pe-0">
                  <div className="details">
                    <div className="text-lg-end">
                      <h4>
                        ${/* */}100{/* */}- ${/* */}150
                      </h4>
                      <p className="text">Hourly Rate</p>
                    </div>
                    <div className="d-grid mt15">
                      <a
                        className="ud-btn btn-light-thm"
                        href="/"
                      >
                        Send Proposal
                        <i className="fal fa-arrow-right-long" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CardTwoProject;
