/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CardSixInovice from "../../AllCards/CardSixInovice";

const InoviceSection1 = () => {
  return (
    <>
      <>
        <div className="row align-items-center justify-content-between pb40">
          <div className="col-xl-4">
            <div className="dashboard_title_area">
              <h2>Invoice</h2>
              <p className="text">Lorem ipsum dolor sit amet, consectetur.</p>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="dashboard_search_meta">
              <div className="search_area">
                <input
                  type="text"
                  className="form-control bdrs4"
                  placeholder="Search Invoice"
                />
                <label>
                  <span className="far fa-magnifying-glass" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
              <div className="packages_table table-responsive">
                <CardSixInovice />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default InoviceSection1;
