import React from 'react'
import Layout from '../../Layout/Layout'
import FreelancerRegisterSection from '../../Components/Auth/FreelancerRegisterSection'

const FreelancerRegister = () => {
  return (
    <>
    <Layout>
       <FreelancerRegisterSection />
    </Layout>
    </>
  )
}

export default FreelancerRegister
