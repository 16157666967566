import React from "react";
import Layout from "../../Components/Dashboard/Layout/Layout";
import MyOrderSection from "../../Components/Dashboard/MyOrder/MyOrderSection";

const MyOrder = () => {

  return (
    <>
      <Layout>
        <div
          className="dashboard__content hover-bgc-color"
          style={{ marginTop: "6%" }}
        >
          <MyOrderSection />
        </div>
      </Layout>
    </>
  );
};

export default MyOrder;
