/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../Layout/Layout";

const WhoweAre = () => {
  return (
    <>
      <Layout>
        <>
          <div className="wrapper ovh mm-page mm-slideout pt60">
            <div className="body_content">
              <section className="breadcumb-section mt40">
                <div className="cta-about-v1 mx-auto maxw1700 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-5">
                        <div className="position-relative">
                          <h1 className="text-white">Who We Are</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="body_content">
            <section className="our-about pb0">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-xl-6">
                    <div
                      className="about-img mb30-sm wow fadeInRight fadeInRight-one"
                      data-wow-delay="300ms"
                    >
                      <img
                        alt="about"
                        loading="lazy"
                        width={691}
                        height={574}
                        decoding="async"
                        data-nimg={1}
                        className="w100 h-100"
                        style={{ color: "transparent" , objectFit: "cover"}}
                        srcSet="/images/whowearetwo.jpeg"
                        src="/images/whowearetwo.jpeg"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-5 offset-xl-1">
                    <div
                      className="position-relative wow fadeInLeft"
                      data-wow-delay="300ms"
                    >
                      <h2 className="mb25">Who We Are</h2>
                      <p className="text mb25">
                        Jamaica is in Our DNA. We are not just Jamaicans we
                        strive to transcend boundaries and make impact that’s
                        truly borderless.
                      </p>
                      <p>
                        At Lukbout, we’ve always believed in the power of our
                        Jamaican people talent and skills. Having the right
                        talent and tools, we are capable of crafting creative
                        solutions and expertise from start to finish to bring
                        clients vision to life. When you work with us, you’ll
                        create a positive ripple effect that could touch
                        lives—and build for boundless impact.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pb0 pt40">
              <div className="container maxw1600   pb60">
                <div
                  className="row justify-content-center wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div className="col-lg-8">
                    <p>
                      <strong>Lukbout’s</strong> mission is empower Jamaican’s
                      to showcase their talents and skills to the world, amplify
                      their earning potential and provide a flexible work
                      schedule, while having the freedom to work from home or
                      other unconventional workspaces,{" "}
                      <strong>Lukbout’s</strong> connects individuals and
                      businesses with Jamaican freelancers offering digital
                      services and trade services in 100+ categories.
                    </p>
                    <p>
                      Lukbout Freelancers work from a variety of workplaces. Our
                      listings on Lukbout can be described as diverse, ranging
                      from "hire a tradesman", "build a website”, hire a Social
                      Media Manager to “register your business". Our
                      highest-paying jobs on Lukbout include website design,
                      social media manager, proofreading and copywriting, and
                      resume writing.{" "}
                    </p>
                    <p>
                      Lukbout, conceptualized and founded in 2023, by Gaile
                      Sweeney, MBA, MPH, PMP and Kaji Sweeney-Sterling, B.Sc.,
                      MBA - takes its name from the Jamaican dialect “Lukbout”
                      meaning “what do you want to look about? Building for
                      boundless and bordless impact and powered by purpose,
                      LukBout is focused on making a difference across 3 key
                      areas: Career Development, education and health, and
                      diversity and inclusion.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="p-0">
              <div className="cta-banner mx-auto maxw1600 pb20 pb60-lg position-relative overflow-hidden mx20-lg">
                <div className="container">
                  <div className="row align-items-center">
                    <div
                      className="col-md-6 col-xl-5 pl30-md pl15-xs wow fadeInRight"
                      data-wow-delay="500ms"
                    >
                    
                        <div
                          className="position-relative wow fadeInLeft"
                          data-wow-delay="300ms"
                        >
                          <h2 className="title">Our Approach</h2>
                          <p className="text mb25">
                          Making your journey, our journey.
                          </p>
                          <p><strong>A Platform Built To Amplify Your Impact</strong></p>
                          <p>
                          Everything we do is made possible through a deep commitment to collaboration and a powerful network of inspiring experts.  Lukbout is an on-demand platforms based on digital technology have created jobs and employment forms that are differentiated from existing offline transactions by the level of accessibility, convenience and price competitiveness.  The platform is local but we act global, with freelancers in every parish across Jamaica and the diaspora doing businesses spanning an estimated 50 countries.
                          </p>
                        </div>
                    </div>
                    <div
                      className="col-md-6 col-xl-6 offset-xl-1 wow fadeInLeft fadeInRight-two"
                      data-wow-delay="500ms"
                    >
                      <div className="about-img">
                        <img
                          alt="about 6"
                          loading="lazy"
                          width={691}
                          height={705}
                          decoding="async"
                          data-nimg={1}
                          className="w100 h-100"
                          style={{ color: "transparent" , objectFit: "cover" }}
                          srcSet="/images/whoweareone.jpeg"
                          src="/images/whoweareone.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
        <section className="pt30">
          <div className="container maxw1600 ">
            <div
              className="row justify-content-center wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="col-lg-8">
                <p><strong>The Ultimate Collaboration</strong></p>
                <p>
                We approach every potential client opportunity with an open mind.  Then, we explore how to realize your vision for the future, and achieve results that matter for your life. Right from the start, we help Jamaican Freelancer’s to Connect, collaborate and grow their side hustle or gig successfully. For the Customers we co-create a reliable and trusted space for them to search, chat and hire high quality professional Jamaican freelancers.
                </p>
                <p><strong>A Recipe For Success</strong></p>
                <p>While every user on our platform journey is unique, we consider certain aspects of our approach to be universal. We not only provide multinational online marketplace for Jamaican freelance services, but focus on engaging our users on LukBout in ways that are authentic and meaningful.  By fostering meaningful connections, we gain the knowledge and trust we need to help you build your gig or side hustle.   We make sure all users on LukBout are heading in right direction and make smart decision to uncover big opportunities to bring visions to life.  </p>
                <p className="text-center">
                  <strong>Conveniently Connect and Collaborate</strong>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default WhoweAre;
