import React from 'react'
import AboutSection1 from '../../../Components/Pages/About/AboutSection1'
import Layout from '../../../Layout/Layout'

const About = () => {
  return (
    <>
    <Layout>
     <AboutSection1 />
    </Layout>
    </>
  )
}

export default About