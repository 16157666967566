/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const HelpSection1 = () => {
  const handlerCollapseTwo = () => {
    document.getElementById('collapseTwo').classList.toggle('show');
  }
  const handlerCollapseThree = () => {
    document.getElementById('collapseThree').classList.toggle('show');
  }
  const handlerCollapseFour = () => {
    document.getElementById('collapseFour').classList.toggle('show');
  }
  const handlerCollapseFive = () => {
    document.getElementById('collapseFive').classList.toggle('show');
  }
  return (
    <>
<div className="body_content">
  <section className="our-faq pb50" style={{marginTop:'5%'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
          <div className="main-title text-center">
            <h2 className="title">How can we help you?</h2>
            <p className="paragraph mt10">
              Give your visitor a smooth online experience with a solid UX
              design
            </p>
            <div className="search_widgets mt30">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
                <div className="help_search_btn">
                  <button type="submit" className="btn search-btn">
                    <span className="far fa-magnifying-glass" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-developer" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Development &amp; IT</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-web-design-1" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Design &amp; Creative</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-digital-marketing" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Digital Marketing</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-translator" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Writing &amp; Translation</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-microphone" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Musics &amp; Audio</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-video-file" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Video &amp; Animation</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-ruler" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Engineering &amp; Architecture</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="iconbox-style1 bdr1">
            <div className="icon">
              <span className="flaticon-goal" />
            </div>
            <div className="details mt20">
              <p className="text mb5">
                1.853{/* */}
                skills
              </p>
              <h4 className="title">
                <a href="/">Finance &amp; Accounting</a>
              </h4>
              <p className="mb-0">
                Software Engineer, Web / Mobile Developer &amp; More{/* */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="our-faqs pb50">
        <div className="container">
          <div className="row wow fadeInUp">
            <div className="col-lg-6 m-auto">
              <div className="main-title text-center">
                <h2 className="title">Frequently Asked Questions</h2>
                <p className="paragraph mt10">
                  Lorem ipsum dolor sit amet, consectetur.
                </p>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-8 mx-auto">
              <div className="ui-content">
                <div className="accordion-style1 faq-page mb-4 mb-lg-5">
                <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            onClick={handlerCollapseTwo}
                          >
                            Can I cancel at anytime?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Cras vitae ac nunc orci. Purus amet tortor non at
                            phasellus ultricies hendrerit. Eget a, sit morbi
                            nunc sit id massa. Metus, scelerisque volutpat nec
                            sit vel donec. Sagittis, id volutpat erat vel.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={handlerCollapseThree}
                          >
                            How do I get a receipt for my purchase?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Cras vitae ac nunc orci. Purus amet tortor non at
                            phasellus ultricies hendrerit. Eget a, sit morbi
                            nunc sit id massa. Metus, scelerisque volutpat nec
                            sit vel donec. Sagittis, id volutpat erat vel.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            onClick={handlerCollapseFour}
                          >
                            Which license do I need?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Cras vitae ac nunc orci. Purus amet tortor non at
                            phasellus ultricies hendrerit. Eget a, sit morbi
                            nunc sit id massa. Metus, scelerisque volutpat nec
                            sit vel donec. Sagittis, id volutpat erat vel.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            onClick={handlerCollapseFive}
                          >
                            How do I get access to a theme I purchased?
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Cras vitae ac nunc orci. Purus amet tortor non at
                            phasellus ultricies hendrerit. Eget a, sit morbi
                            nunc sit id massa. Metus, scelerisque volutpat nec
                            sit vel donec. Sagittis, id volutpat erat vel.
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default HelpSection1

