/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { initialValues, serviceSchema } from "../../../Schema/Service";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { GET_GIG } from "../../../Graphql/Query/Gig";
import { GET_USER } from "../../../Graphql/Query/AuthQuery";
import { GET_SERVICES_CATEGORY } from "../../../Graphql/Query/ServiceCategory";
import { SERVICES_MUTATION } from "../../../Graphql/Mutation/Auth/Service";
import {
  USER_ACCOUNT,
  STRIPE_ACCOUNT_STATUS,
} from "../../../Graphql/Mutation/Auth/order/order";

const AddServicesSection1 = () => {
  const { refetch: refetchQueries } = useQuery(GET_GIG);
  const { data } = useQuery(GET_USER);
  const [CreateGig, { loading: gigLoading }] = useMutation(SERVICES_MUTATION);
  const [StripeAccountStatusCheck] = useMutation(STRIPE_ACCOUNT_STATUS);
  const [UserConnectedAccount, { loading: accountLoading }] = useMutation(USER_ACCOUNT);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleAccount = async () => {
    try {
      const accountResponse = await UserConnectedAccount();
      if (accountResponse && accountResponse.data) {
        window.location.href = accountResponse?.data?.userConnectedAccount?.url;
        console.log(accountResponse, "accountResponse-------->>>");
      } else {
        console.error("Account Creation failed");
      }
    } catch (error) {
      console.log(error, "---------error");
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: serviceSchema,
    onSubmit: async (values) => {
      console.log(values);
      const {
        title,
        price,
        description,
        deliveryTime,
        requirement,
        serviceCategoryId,
        tags,
      } = values;

      try {
       
  
        const gigResponse = await CreateGig({
          variables: {
            title,
            price,
            description,
            deliveryTime,
            requirement,
            serviceCategoryId: +serviceCategoryId,
            tags,
          },
        });
  
        if (gigResponse && gigResponse.data) {
          refetchQueries();
          navigate("/dashboard/manageservice");
          toast.success("Gig Created SuccessFully");
        } else {
          console.error("Gig failed");
        }
      } catch (error) {
        // if (error.message.includes("Account not found") || error.message.includes("Account detailed not submitted")) {
        //   handleShow();
        // } else {
          console.log(error, "---------error");
        // } 
      }
    },
  });



  const { data: serviceCategory, refetch } = useQuery(GET_SERVICES_CATEGORY, {
    variables: { serviceId: +values?.service_id },
  });

  const handleStripe=async()=>{
    try{
      const accountStatus = await StripeAccountStatusCheck()
    }
    catch(e){
      handleShow()
      console.log(e, "---------error");
    }
  }

  useEffect(() => {
    handleStripe();
  }, []);

  useEffect(() => {
    refetch({ serviceId: +values?.service_id });
  }, [values.service_id, refetch]);


  return (
    <>
      <div className="row">
        <div className="col-lg-9">
          <div className="dashboard_title_area">
            <h2>Add Services</h2>
            <p className="text">Lorem ipsum dolor sit amet, consectetur.</p>
          </div>
        </div>
        {/* <div className="col-lg-3">
    <div className="text-lg-end">
      <a className="ud-btn btn-dark">
        Save &amp; Publish
        <i className="fal fa-arrow-right-long" />
      </a>
    </div>
  </div> */}
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
            <div className="bdrb1 pb15 mb25">
              <h5 className="list-title">Basic Information</h5>
            </div>
            <div className="col-xl-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb10">
                        Service Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        placeholder="service title"
                        onChange={handleChange}
                        value={values.title}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.title && errors.title}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb10">
                        Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="$10"
                        name="price"
                        onChange={handleChange}
                        value={values.price}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.price && errors.price}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw500 mb10">
                          Category
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          name="service_id"
                          onChange={handleChange}
                        >
                          <option selected disabled value="">
                            Select any one Service.
                          </option>
                          {data?.currentUser?.services?.map((e) => (
                            <option value={e?.id}>{e?.name}</option>
                          ))}
                        </select>
                        <span className="error">
                          {touched.service_id && errors.service_id}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw500 mb10">
                          Sub Category
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          name="serviceCategoryId"
                          onChange={handleChange}
                          value={values.serviceCategoryId}
                          onBlur={handleBlur}
                        >
                          <option selected disabled value="">
                            Select any Sub Category.
                          </option>
                          {serviceCategory?.servicesCategories[0]?.service_categories?.map(
                            (e) => (
                              <option value={e?.id}>{e?.name}</option>
                            )
                          )}
                        </select>
                        <span className="error">
                          {touched.serviceCategoryId &&
                            errors.serviceCategoryId}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw500 mb10">
                          Tag
                        </label>
                        <TagsInput
                          value={values.tags}
                          onChange={(tag) => setFieldValue("tags", tag)}
                          name="tags"
                          placeHolder="enter tags"
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.tags && errors.tags}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <div className="form-style1">
                        <label className="heading-color ff-heading fw500 mb10">
                          Delivery Time
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="2 days"
                          name="deliveryTime"
                          onChange={handleChange}
                          value={values.deliveryTime}
                          onBlur={handleBlur}
                        />
                        <span className="error">
                          {touched.deliveryTime && errors.deliveryTime}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb10">
                      <label className="heading-color ff-heading fw500 mb10">
                        Requirement Detail
                      </label>
                      <textarea
                        cols={10}
                        rows={3}
                        placeholder="Requirement"
                        name="requirement"
                        onChange={handleChange}
                        value={values.requirement}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.requirement && errors.requirement}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb10">
                      <label className="heading-color ff-heading fw500 mb10">
                        Services Detail
                      </label>
                      <textarea
                        cols={10}
                        rows={3}
                        placeholder="Description"
                        name="description"
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                      />
                      <span className="error">
                        {touched.description && errors.description}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="text-start">
                      <button
                        className="ud-btn btn-thm default-box-shadow2"
                        type="submit"
                      >
                        {gigLoading ? (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        ) : (
                          <>
                            Save <i className="fal fa-arrow-right-long" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content position-relative">
       
          <div className="modal-body p-4">
            <div className="col-sm-12 py-3 text-center">
              <i className="fa-regular fa-circle-exclamation fs-1 text-danger"></i>
              <div className="mb20">
                <h6 className="ff-heading fw500 fs-3 text-danger">
                  Please Complete Your Profile
                </h6>
              </div>
              <div className="mb10 mx-1">
                <p className="fw400 fs-5 ">Create Your Wallet</p>
              </div>
            </div>

            <button
              type="submit"
              className="ud-btn btn-thm mt-2 w-100"
              onClick={() => {
                handleAccount();
              }}
            >
              {accountLoading ? (
                <ColorRing
                  visible={true}
                  height="30"
                  width="30"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                <>
                  Create Wallet
                  <i className="fal fa-arrow-right-long" />
                </>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddServicesSection1;
