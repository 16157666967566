import React from 'react'
import Layout from '../../Components/Dashboard/Layout/Layout'
import Dashboards from '../../Components/Dashboard/Dashboard'

const Dashboard = () => {
  return (
    <>
       <Layout>
       <div className="dashboard__content hover-bgc-color" style={{marginTop:'5%'}}>
        <Dashboards />
       </div>
       </Layout>
    </>
  )
}

export default Dashboard


