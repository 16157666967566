/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const EmployeeSectionSingle1 = () => {
  return (
    <>
<div className="body_content">
  <section className="categories_list_section overflow-hidden" style={{marginTop:'8%'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="listings_category_nav_list_menu">
            <ul className="mb0 d-flex ps-0">
              <li>
                <a className="active">All Categories</a>
              </li>
              <li>
                <a className="">Graphics Design</a>
              </li>
              <li>
                <a className="">Digital Marketing</a>
              </li>
              <li>
                <a className="">Writing Translation</a>
              </li>
              <li>
                <a className="">Video Animation</a>
              </li>
              <li>
                <a className="">Music Audio</a>
              </li>
              <li>
                <a className="">Programming Tech</a>
              </li>
              <li>
                <a className="">Business</a>
              </li>
              <li>
                <a className="">Lifestyle</a>
              </li>
              <li>
                <a className="">Trending</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8 col-lg-10">
          <div className="breadcumb-style1 mb10-xs">
            <div className="breadcumb-list">
              <a>Home</a>
              <a>Services</a>
              <a>Design &amp; Creative</a>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-2">
          <div className="d-flex align-items-center justify-content-sm-end">
            <a className="position-relative ui-share-toggle">
              <div className="share-save-widget d-flex align-items-center">
                <span className="icon flaticon-share dark-color fz12 mr10" />
                <div className="h6 mb-0">Share</div>
              </div>
            </a>
            <a>
              <div className="share-save-widget d-flex align-items-center ml15">
                <span className="icon flaticon-like dark-color fz12 mr10" />
                <div className="h6 mb-0">Save</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="breadcumb-section pt-0">
    <div className="cta-employee-single freelancer-single-style mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
      <img
        alt="vector-img"
        loading="lazy"
        width={198}
        height={226}
        decoding="async"
        data-nimg={1}
        className="left-top-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="
          /_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=256&q=75 1x,
          /_next/image?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=640&q=75 2x
        "
        src="_next/left-top9e21.png?url=%2Fimages%2Fvector-img%2Fleft-top.png&w=640&q=75"
      />
      <img
        alt="vector-img"
        loading="lazy"
        width={255}
        height={181}
        decoding="async"
        data-nimg={1}
        className="right-bottom-img wow zoomIn"
        style={{ color: "transparent" }}
        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75
        "
        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fvector-img%2Fright-bottom.png&w=256&q=75"
      />
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-xl-7">
            <div className="position-relative">
              <div className="list-meta d-sm-flex align-items-center">
                <a className="position-relative freelancer-single-style">
                  <img
                    alt="Freelancer Photo"
                    loading="lazy"
                    width={120}
                    height={120}
                    decoding="async"
                    data-nimg={1}
                    className="rounded-circle w-100 wa-sm mb15-sm"
                    style={{ color: "transparent" }}
                    srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Femployee-single.png&w=128&q=75
                    "
                    src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Femployee-single.png&w=128&q=75"
                  />
                  <span className="online2" />
                </a>
                <div className="ml20 ml0-xs">
                  <h5 className="title mb-1">Invision</h5>
                  <p className="text fz14 mb-2">Lorem Ipsum Dolar Sit Amet</p>
                  <p className="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                    <i className="fas fa-star vam fz10 review-color me-2" />
                    {/* */}4.82 94 reviews
                  </p>
                  <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                    <i className="flaticon-place vam fz20 me-2" /> London, UK
                  </p>
                  <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                    <i className="flaticon-30-days vam fz20 me-2" /> Since April
                    1, 2022
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="pt10 pb90 pb30-md">
    <div className="container">
      <div className="row wow fadeInUp">
        <div className="col-lg-8">
          <div className="service-about">
            <h4 className="mb20">About Company</h4>
            <p className="text mb30">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <p className="text mb30">
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy. Various
              versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <h5 className="mb20 mt60">Who are we?</h5>
            <p className="text mb30">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <h5 className="mb20 mt60">What do we do?</h5>
            <p className="text mb30">
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy. Various
              versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <div className="employee-single-slider vam_nav_style slider-1-grid owl-carousel owl-theme mt55 mb60 owl-loaded owl-drag">
              <div className="thumb" />
              <button type="button" className="prev-btn">
                <i className="far fa-arrow-left-long" />
              </button>
              <button type="button" className="next-btn">
                <i className="far fa-arrow-right-long" />
              </button>
            </div>
            <div className="row">
              <h4 className="mb25">Projects</h4>
              <div className="col-sm-6 col-xl-12">
                <div className="job-list-style1 bdr1 d-xl-flex align-items-start">
                  <div className="icon d-flex align-items-center mb20">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75"
                    />
                    <span className="fav-icon flaticon-star" />
                  </div>
                  <div className="details ml20 ml0-xl">
                    <h5>Website Designer Required For Directory Theme</h5>
                    <h6 className="mb-3 text-thm">Mailchimp</h6>
                    <p className="list-inline-item mb-0">$125k-$135k Hourly</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">1-5 Days</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">
                      Expensive
                    </p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">Remote</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-12">
                <div className="job-list-style1 bdr1 d-xl-flex align-items-start">
                  <div className="icon d-flex align-items-center mb20">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
                    />
                    <span className="fav-icon flaticon-star" />
                  </div>
                  <div className="details ml20 ml0-xl">
                    <h5>Website Designer Required For Directory Theme</h5>
                    <h6 className="mb-3 text-thm">Mailchimp</h6>
                    <p className="list-inline-item mb-0">$125k-$135k Hourly</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">1-5 Days</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">
                      Expensive
                    </p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">Remote</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-12">
                <div className="job-list-style1 bdr1 d-xl-flex align-items-start">
                  <div className="icon d-flex align-items-center mb20">
                    <img
                      alt="icon"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="wa"
                      style={{ color: "transparent" }}
                      srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75
                      "
                      src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75"
                    />
                    <span className="fav-icon flaticon-star" />
                  </div>
                  <div className="details ml20 ml0-xl">
                    <h5>Website Designer Required For Directory Theme</h5>
                    <h6 className="mb-3 text-thm">Mailchimp</h6>
                    <p className="list-inline-item mb-0">$125k-$135k Hourly</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">1-5 Days</p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">
                      Expensive
                    </p>
                    <p className="list-inline-item mb-0 bdrl1 pl15">Remote</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product_single_content mb50">
              <div className="mbp_pagination_comments">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="total_review mb30 mt45">
                      <h4>80 Reviews</h4>
                    </div>
                    <div className="d-md-flex align-items-center mb30">
                      <div className="total-review-box d-flex align-items-center text-center mb30-sm">
                        <div className="wrapper mx-auto">
                          <div className="t-review mb15">4.96</div>
                          <h5>Exceptional</h5>
                          <p className="text mb-0">3,014 reviews</p>
                        </div>
                      </div>
                      <div className="wrapper ml60 ml0-sm">
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">5 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "90%" }}
                              aria-valuenow={90}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">58</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">4 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar w-75"
                              aria-valuenow={75}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">20</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">3 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar w-50"
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">15</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">2 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "30%" }}
                              aria-valuenow={30}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">2</div>
                        </div>
                        <div className="review-list d-flex align-items-center mb10">
                          <div className="list-number">1 Star</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "20%" }}
                              aria-valuenow={10}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <div className="value text-end">1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mbp_first position-relative d-flex align-items-center justify-content-start mb30-sm">
                      <img
                        alt="comments-2.png"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="mr-3"
                        style={{ color: "transparent" }}
                        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                        "
                        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                      />
                      <div className="ml20">
                        <h6 className="mt-0 mb-0">Bessie Cooper</h6>
                        <div>
                          <span className="fz14">12 March 2022</span>
                        </div>
                      </div>
                    </div>
                    <p className="text mt20 mb20">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                    </p>
                    <div className="review_cansel_btns d-flex">
                      <a>
                        <i className="fas fa-thumbs-up" />
                        Helpful
                      </a>
                      <a>
                        <i className="fas fa-thumbs-down" />
                        Not helpful
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mbp_first position-relative d-flex align-items-center justify-content-start mt30 mb30-sm">
                      <img
                        alt="comments-2.png"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="mr-3"
                        style={{ color: "transparent" }}
                        srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75
                        "
                        src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fcomments-2.png&w=64&q=75"
                      />
                      <div className="ml20">
                        <h6 className="mt-0 mb-0">Darrell Steward</h6>
                        <div>
                          <span className="fz14">12 March 2022</span>
                        </div>
                      </div>
                    </div>
                    <p className="text mt20 mb20">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                    </p>
                    <div className="review_cansel_btns d-flex pb30">
                      <a>
                        <i className="fas fa-thumbs-up" />
                        Helpful
                      </a>
                      <a>
                        <i className="fas fa-thumbs-down" />
                        Not helpful
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="position-relative bdrb1 pb50">
                      <a
                        className="ud-btn btn-light-thm"
                        href="/"
                      >
                        See More
                        <i className="fal fa-arrow-right-long" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bsp_reveiw_wrt mb20">
              <h6 className="fz17">Add a Review</h6>
              <p className="text">
                Your email address will not be published. Required fields are
                marked *
              </p>
              <h6>Your rating of this product</h6>
              <div className="d-flex">
                <i className="fas fa-star review-color" />
                <i className="far fa-star review-color ms-2" />
                <i className="far fa-star review-color ms-2" />
                <i className="far fa-star review-color ms-2" />
                <i className="far fa-star review-color ms-2" />
              </div>
              <form className="comments_form mt30 mb30-md">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-4">
                      <label className="fw500 fz16 ff-heading dark-color mb-2">
                        Comment
                      </label>
                      <textarea
                        className="pt15"
                        rows={6}
                        placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb20">
                      <label className="fw500 ff-heading dark-color mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ali Tufan"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb20">
                      <label className="fw500 ff-heading dark-color mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="creativelayers088"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <label className="custom_checkbox fz15 ff-heading">
                        Save my name, email, and website in this browser for the
                        next time I comment.
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <a className="ud-btn btn-thm">
                      Send
                      <i className="fal fa-arrow-right-long" />
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="blog-sidebar ms-lg-auto">
            <div className="price-widget pt25 widget-mt-minus bdrs8">
              <h4 className="widget-title">About Me</h4>
              <div className="category-list mt20">
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-menu text-thm2 pe-2 vam" />
                    Primary industry
                  </span>
                  <span>Software</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-factory text-thm2 pe-2 vam" />
                    Company size
                  </span>
                  <span>501-1.000</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-calendar text-thm2 pe-2 vam" />
                    Founded in
                  </span>
                  <span>April 2022</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-call text-thm2 pe-2 vam" />
                    Phone
                  </span>
                  <span>382 4632 01 03</span>
                </a>
                <a className="d-flex align-items-center justify-content-between bdrb1 pb-2">
                  <span className="text">
                    <i className="flaticon-mail text-thm2 pe-2 vam" />
                    Email
                  </span>
                  <span>info@freeio.com</span>
                </a>
                <a className="d-flex align-items-center justify-content-between mb-3">
                  <span className="text">
                    <i className="flaticon-place text-thm2 pe-2 vam" />
                    Location
                  </span>
                  <span>London, IK</span>
                </a>
              </div>
              <div className="d-grid">
                <a className="ud-btn btn-thm" href="/">
                  Contact Me
                  <i className="fal fa-arrow-right-long" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="pt-0 pb90 pb30-md">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mb30">
            <h2>3 jobs at Invision</h2>
            <p className="text">2022 jobs live - 293 added today</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="job-list-style1 bdr1">
            <div className="icon d-flex align-items-center mb20">
              <img
                alt="img"
                loading="lazy"
                width={60}
                height={60}
                decoding="async"
                data-nimg={1}
                className="wa"
                style={{ color: "transparent" }}
                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75
                "
                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-2.png&w=64&q=75"
              />
              <h6 className="mb-0 text-thm ml20">Mailchimp</h6>
              <span className="fav-icon flaticon-star" />
            </div>
            <div className="details">
              <h5 className="mb20">
                <a href="/">
                  Website Designer Required For Directory Theme
                </a>
              </h5>
              <p className="list-inline-item c">$125k-$135k Hourly</p>
              <p className="list-inline-item c bdrl1 pl10">1-5 Days</p>
              <p className="list-inline-item c bdrl1 pl10">Expensive</p>
              <p className="list-inline-item c bdrl1 pl10">Remote</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="job-list-style1 bdr1">
            <div className="icon d-flex align-items-center mb20">
              <img
                alt="img"
                loading="lazy"
                width={60}
                height={60}
                decoding="async"
                data-nimg={1}
                className="wa"
                style={{ color: "transparent" }}
                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75
                "
                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-1.png&w=64&q=75"
              />
              <h6 className="mb-0 text-thm ml20">Mailchimp</h6>
              <span className="fav-icon flaticon-star" />
            </div>
            <div className="details">
              <h5 className="mb20">
                <a href="/">
                  Website Designer Required For Directory Theme
                </a>
              </h5>
              <p className="list-inline-item c">$125k-$135k Hourly</p>
              <p className="list-inline-item c bdrl1 pl10">1-5 Days</p>
              <p className="list-inline-item c bdrl1 pl10">Expensive</p>
              <p className="list-inline-item c bdrl1 pl10">Remote</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="job-list-style1 bdr1">
            <div className="icon d-flex align-items-center mb20">
              <img
                alt="img"
                loading="lazy"
                width={60}
                height={60}
                decoding="async"
                data-nimg={1}
                className="wa"
                style={{ color: "transparent" }}
                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75
                "
                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-3.png&w=64&q=75"
              />
              <h6 className="mb-0 text-thm ml20">Mailchimp</h6>
              <span className="fav-icon flaticon-star" />
            </div>
            <div className="details">
              <h5 className="mb20">
                <a href="/">
                  Website Designer Required For Directory Theme
                </a>
              </h5>
              <p className="list-inline-item c">$125k-$135k Hourly</p>
              <p className="list-inline-item c bdrl1 pl10">1-5 Days</p>
              <p className="list-inline-item c bdrl1 pl10">Expensive</p>
              <p className="list-inline-item c bdrl1 pl10">Remote</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          <div className="job-list-style1 bdr1">
            <div className="icon d-flex align-items-center mb20">
              <img
                alt="img"
                loading="lazy"
                width={60}
                height={60}
                decoding="async"
                data-nimg={1}
                className="wa"
                style={{ color: "transparent" }}
                srcSet="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-4.png&w=64&q=75
                "
                src="https://freeio-app-nextjs.vercel.app/_next/image?url=%2Fimages%2Fteam%2Fclient-4.png&w=64&q=75"
              />
              <h6 className="mb-0 text-thm ml20">Mailchimp</h6>
              <span className="fav-icon flaticon-star" />
            </div>
            <div className="details">
              <h5 className="mb20">
                <a href="/">
                  Website Designer Required For Directory Theme
                </a>
              </h5>
              <p className="list-inline-item c">$125k-$135k Hourly</p>
              <p className="list-inline-item c bdrl1 pl10">1-5 Days</p>
              <p className="list-inline-item c bdrl1 pl10">Expensive</p>
              <p className="list-inline-item c bdrl1 pl10">Remote</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <a className="scrollToHome">
    <i className="fas fa-angle-up" />
  </a>
</div>

    </>
  )
}

export default EmployeeSectionSingle1


