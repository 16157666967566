/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CardSevenPayouts from '../../AllCards/CardSevenPayouts'

const PayoutsSection1 = () => {
  return (
    <>
<>
  <div className="row align-items-center justify-content-between pb40">
    <div className="col-lg-6">
      <div className="dashboard_title_area">
        <h2>Payouts</h2>
      </div>
    </div>
    {/* <div className="col-lg-6">
      <div className="text-lg-end">
        <a
          className="ud-btn btn-dark default-box-shadow2"
          href="#"
        >
          Create Payout
          <i className="fal fa-arrow-right-long" />
        </a>
      </div>
    </div> */}
  </div>
  <div className="row">
    <div className="col-xl-12">
      <div className="ps-widget bgc-white bdrs4 p30 mb60 overflow-hidden position-relative">
        <div className="packages_table table-responsive">
          <CardSevenPayouts />
        </div>
      </div>
      {/* <div className="ps-widget bgc-white bdrs4 p30 mb30 position-relative">
        <div className="row">
          <div className="col-lg-9">
            <div className="bdrb1 pb15">
              <h5 className="list-title">Payout Methods</h5>
            </div>
            <div className="widget-wrapper mt35">
              <h6 className="list-title mb10">Select default payout method</h6>
              <div className="bootselect-multiselect">
                <div className="dropdown bootstrap-select">
                  <button
                    type="button"
                    className="btn dropdown-toggle btn-light"
                    data-bs-toggle="dropdown"
                  >
                    <div className="filter-option">
                      <div className="filter-option-inner">
                        <div className="filter-option-inner-inner">Paypal</div>
                      </div>
                    </div>
                  </button>
                  <div className="dropdown-menu">
                    <div className="inner show">
                      <ul className="dropdown-menu inner show">
                        <li className="selected active">
                          <a className="dropdown-item selected active">
                            <span className="text">Paypal</span>
                          </a>
                        </li>
                        <li className="selected active">
                          <a className="dropdown-item">
                            <span className="text">Bank Transfer</span>
                          </a>
                        </li>
                        <li className="selected active">
                          <a className="dropdown-item">
                            <span className="text">Payoneer</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="mb15">Payout Details</h5>
            <div className="navpill-style1 payout-style">
              <ul className="nav nav-pills align-items-center justify-content-center mb30">
                <li className="nav-item">
                  <button className="nav-link fw500 dark-color active">
                    Paypal
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link fw500 dark-color">
                    Bank Transfer
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link fw500 dark-color">
                    Payoneer
                  </button>
                </li>
              </ul>
              <form className="form-style1">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb-1">
                        Bank Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="creativelayers088@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb-1">
                        Bank Account Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="creativelayers088@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb-1">
                        Bank Account Holder Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="creativelayers088@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb-1">
                        Bank Routing Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="creativelayers088@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb-1">
                        Bank IBAN
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="creativelayers088@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb-1">
                        Swift Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="creativelayers088@gmail.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-start">
                      <a className="ud-btn btn-thm" href="/">
                        Save Detail
                        <i className="fal fa-arrow-right-long" />
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </div>
</>

    </>
  )
}

export default PayoutsSection1


