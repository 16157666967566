/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CardTenManageJobs1 from '../../AllCards/CardTenManageJobs1'

const MyOrderSection = () => {


  return (
    <>
<>
  <div className="col-lg-12">
    <div className="dashboard_title_area">
      <h2>My Orders</h2>
      {/* <p className="text">Lorem ipsum dolor sit amet, consectetur.</p> */}
    </div>
  </div>
  <div className="row">
    <div className="col-xl-12">
      <div className="ps-widget bgc-white bdrs4 p20 mb30 overflow-hidden position-relative">
        <div className="packages_table table-responsive">
           <CardTenManageJobs1 />
        </div>
      </div>
    </div>
  </div>
  <div
    className="modal fade"
    id="proposalModal"
    tabIndex={-1}
    aria-labelledby="proposalModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content position-relative">
        <button
          type="button"
          className="btn-close position-absolute"
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ top: 10, right: 10, zIndex: 9 }}
        />
        <div className="modal-body p-4">
          <form>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label className="form-label">Your hourly price</label>
                  <input type="number" className="form-control" />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label className="form-label">Your hourly price</label>
                  <input type="number" className="form-control" />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Cover letter</label>
              <textarea
                name="text"
                cols={30}
                rows={2}
                defaultValue={"This is what i want"}
              />
            </div>
            <button
              type="button"
              className="ud-btn btn-thm"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Update
              <i className="fal fa-arrow-right-long" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div
    className="modal fade"
    id="deleteModal"
    tabIndex={-1}
    aria-labelledby="deleteModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content position-relative">
        <button
          type="button"
          className="btn-close position-absolute"
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ top: 10, right: 10, zIndex: 9 }}
        />
        <div className="modal-body px-4 pt-5">
          <div className="pb20">
            <h4 className="pb10 text-center text-black">
              Are you sure you want to delete?
            </h4>
            <p className="text-center">
              Do you really want to delete your these record ? This process
              can't be undo.
            </p>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <a
              className="ud-btn bg-danger text-white mb25"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Delete
              <i className="fal fa-arrow-right-long" />
            </a>
            <a
              className="ud-btn btn-dark mb25"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Cancel
              <i className="fal fa-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </>
  )
}

export default MyOrderSection