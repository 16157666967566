/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { GET_ORDERS } from "../../Graphql/Query/order";
import { GET_USER } from "../../Graphql/Query/AuthQuery";
import { useMutation, useQuery } from "@apollo/client";
import {
  ORDER_STATUS,
} from "../../Graphql/Mutation/Auth/order/order";
import { ColorRing } from "react-loader-spinner";

const CardTenManageJobs1 = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderId, setOrderId] = useState(null);

  const { data ,refetch } = useQuery(GET_ORDERS);
  const { data: currentUser } = useQuery(GET_USER);
  const [orderStatusChange,{loading:statusloading}] = useMutation(ORDER_STATUS);

  console.log(data,'orders------------>>>')
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const allJobsCombined  = data ? data?.userOrders : []
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
  const formatDate = (isoTime) => {
    const date = new Date(isoTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    refetch();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
   }
  
   const formik = useFormik({
    initialValues: {
      status: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { status } = values;

      try {
        const orderStatus = await orderStatusChange({
          variables: {
            status: status,
            orderId: +orderId,
          },
        });

        console.log(orderStatus, "orderStatus");
        if (orderStatus && orderStatus.data) {
          toast.success("Order Status Changes SuccessFully");
          setOrderId("");
          resetForm();
          refetch();
          handleClose();
        } else {
          console.error("Order failed");
        }
      } catch (error) {
        console.log(error, "error");
      }
    },
  });

  return (
    <>
      <table className="table-style3 table at-savesearch">
        <thead className="t-head">
          <tr>
            <th scope="col">Seller</th>
            <th scope="col">Title</th>
            <th scope="col">Date</th>
            <th scope="col">Gig Price</th>
            <th scope="col">Platform Price</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody className="t-body">
          {currentJobs?.filter(e=>e?.buyerId === currentUser?.currentUser?.id)?.map((item) => (
            <tr>
              <th scope="row">
                <div className="freelancer-style1 p-0 mb-0 box-shadow-none">
                  <div className="d-lg-flex align-items-lg-center">
                    <div className="thumb w60 position-relative rounded-circle mb15-md">
                      <img
                        alt="rounded"
                        loading="lazy"
                        width={60}
                        height={60}
                        decoding="async"
                        data-nimg={1}
                        className="rounded-circle mx-auto"
                        style={{ color: "transparent" }}
                        src={`${"https://yassohapi.devssh.xyz"}${
                          item?.buyerId === currentUser?.currentUser?.id
                            ? item?.seller?.profilePic
                            : item?.buyer?.profilePic
                        }`}
                      />
                      <span className="online-badge2" />
                    </div>
                    <div className="details ml15 ml0-md mb15-md">
                      <h5 className="title mb-2">
                        {currentUser?.currentUser?.id === item?.buyerId ? item?.seller?.username : item?.buyer?.username}
                      </h5>
                      {/* <h6 className="mb-0 text-thm">{item?.buyer?.email}</h6> */}
                    </div>
                  </div>
                </div>
              </th>
              <td className="vam">
                <span >{item?.gig?.title}</span>
              </td>
              <td className="vam">
              {formatDate(item?.orderDate)}
              </td>
              <td className="vam">
              <span>{item?.gig?.price}</span>
              </td>
              <td className="vam">
              <span>{item?.appFee}</span>
              </td>
              <td className="vam">
                  <span className={`pending-style ${item?.status === 'in_progress' ? 'style2': item?.status === 'completed' ? 'style7' : item?.status === 'cancelled' ? 'style3' : 'style1' }`}>{item?.status === 'in_progress' ? "Progress":capitalizeFirstLetter(item?.status)}</span>
                </td>
                <td>
                  <div className="d-flex">
                    <button
                      onClick={() => {
                        handleShow();
                        setOrderId(item?.id);
                      }}
                      className={`btn btn-outline-none pending-style style7 me-2 ${item?.status === ('pending' || 'completed') && 'disabled'} `}
                      id="edit"
                      data-bs-toggle="modal"
                      data-bs-target="#proposalModal"
                    >
                      <span className="flaticon-pencil" />
                    </button>
                  
                  </div>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt30">
        <div className="mbp_pagination text-center">
          <ul className="page_navigation">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                <span className="fas fa-angle-left" />
              </a>
            </li>
            {[...Array(Math.ceil(allJobsCombined?.length / jobsPerPage)).keys()]
              .slice(currentPage - 1, currentPage + 2)
              .map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(allJobsCombined?.length / jobsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content position-relative">
          <button
            type="button"
            onClick={() => handleClose()}
            className="btn-close position-absolute"
            style={{ top: 10, right: 10, zIndex: 9 }}
          />
          <div className="modal-body p-4">
            <form>
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb20">
                    <div className="form-style1">
                      <label className="heading-color ff-heading fw500 mb10">
                        Select Order Status
                      </label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        name="status"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.status}
                      >
                        <option selected disabled value="">
                          Select Any One Status
                        </option>
                        <option value="pending">Pending</option>
                        <option value="in_progress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                    {formik.touched.status && formik.errors.status && (
                      <div className="text-danger">{formik.errors.status}</div>
                    )}
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="ud-btn btn-thm w-100"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                {statusloading ? (
                        <ColorRing
                          visible={true}
                          height="25"
                          width="25"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      ) : (
                        <>
                          {" "} 
                Submit
                <i className="fal fa-arrow-right-long" />
                 </>
                      )} 
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardTenManageJobs1;
